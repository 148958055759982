import React, { useState } from "react";
import { Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import CustomMadePage from "./pages/CustomMade/CustomMade";
import CustomMadeDetailPage from "./pages/CustomMadeDetail/CustomMadeDetail";
import DecorativePage from "./pages/Decorative/Decorative";
import ReadyToWearPage from "./pages/ReadyToWear/ReadyToWear";
import ReadyToWearDetailPage from "./pages/ReadyToWearDetail/ReadyToWearDetail";
import AccountPage from "./pages/Account/Account";
import CartPage from "./pages/Cart/Cart";
import PaymentPage from "./pages/Payment/Payment";
import LoginPage from "./pages/Login/Login";
import RegisterPage from "./pages/Register/Register";
import OrderHistoryPage from "./pages/Account/OrderHistory";
import BalancePage from "./pages/Account/Balance";
import AddressPage from "./pages/Account/Address";
import OrderHistoryDetailPage from "./pages/Account/OrderHistoryDetail";
import ShopPage from "./pages/Shop/Shop";
import ShopDetailPage from "./pages/Shop/ShopDetail";
import PaymentSuccessfulPage from "./pages/PaymentSuccessful/PaymentSuccessful";
import StatementAccountPage from "./pages/Account/StatementAccount";
import PaymentUOB from "./pages/PaymentUOB/PaymentUOB";
import ShowRoom from "./pages/ShowRoom/ShowRoom";
import Catalogue from "./pages/Catalogue/Catalogue";
import BestBuy from "./pages/BestBuy/BestBuy";

export default function Routes() {
  return (
    <Router>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/readytowear" element={<ReadyToWearPage />} />
      <Route
        path="/readytoweardetail/:item_id"
        element={<ReadyToWearDetailPage />}
      />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/custommade" element={<CustomMadePage />} />
      <Route
        path="/custommadedetail/:item_id"
        element={<CustomMadeDetailPage />}
      />
      <Route path="/decorative" element={<DecorativePage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/cart" element={<CartPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/orderhistorydetail/:sales_order_id"
        element={<OrderHistoryDetailPage />}
      />
      <Route path="/shop" element={<ShopPage />} />
      <Route path="/shopdetail/:item_id" element={<ShopDetailPage />} />
      <Route path="/paymentsuccessful" element={<PaymentSuccessfulPage />} />
      <Route path="/orderhistory" element={<OrderHistoryPage />} />
      <Route path="/balance" element={<BalancePage />} />
      <Route path="/address" element={<AddressPage />} />
      <Route path="/statementaccount" element={<StatementAccountPage />} />
      <Route path="/paynow/:jespa" element={<PaymentUOB />} />
      <Route path="/showroom" element={<ShowRoom />} />
      <Route path="/catalogue" element={<Catalogue />} />
      <Route path="/bestbuy" element={<BestBuy />} />
    </Router>
  );
}
