import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField, Input, FormControl } from "@mui/material";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //   border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {

  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "24px",
    ...md("md", {
      fontSize: "34px",
    }),
    ...md("xmd", {
      fontSize: "44px",
    }),
    ...md("lg", {
      fontSize: "54px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("xmd", {
      fontSize: "23px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theBox: {
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",

    ...md("md", {
      top: "50%",
      left: "50%",
      //   width: "100%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theFlex: {
    display: "block",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    ...md("md", {
      display: "flex",
    }),
  },

  theTabList: {
    display: "block",
    width: "100%",
    ...md("md", {
      display: "flex",
      justifyContent: "center",
    }),
  },

  theMB: {
    marginTop: "2rem",
    display: "block",
    fontStyle: "italic",
    ...md("md", {
      marginTop: "0rem",
      fontStyle: "unset",
    }),
  },

  theTabActive: {
    borderBottom: "2px solid #800000",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theTab: {
    borderBottom: "2px solid #FFF",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theGallery: {
    position: "relative",
    width: "100%",
    // marginBottom: "1rem",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theWidth: {
    display: "flex",
    width: "100%",
    alignItems: "center",

    ...md("sm", {
      width: "45%",
    }),

    ...md("md", {
      width: "40%",
    }),

    ...md("xmd", {
      width: "45%",
    }),
  },

  theWidth01: {
    width: "25%",
    ...md("sm", {
      width: "30%",
    }),
    ...md("md", {
      width: "35%",
    }),
    ...md("xmd", {
      width: "25%",
    }),
  },

  theWidth02: {
    marginLeft: "1rem",
    width: "100%",

    ...md("md", {
      width: "35%",
    }),
  },

  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },
  theIcon: {
    position: "relative",
    width: "12%",
    marginRight: ".4rem",
    "& img": {
      width: "100%",
    },
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: ".8rem 0",
    justifyContent: "center",
    // border: "1px solid #BA2025",
    // width: '30%'
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "52%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theButtonStyle2: {
    color: "#353535",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  //

  theMaterial: {
    borderRadius: "50%",
    width: "70px",
    height: "70px",
    // background: "#878787",
    margin: "0 auto",

    "& img": {
      width: "100%",
      height: "70px",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },

  thePatternIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      borderRadius: "10px"
    },
  },

  theBoxx: {
    width: "100%",
    ...md("md", {
      width: "50%",
      margin: "0 auto",
    }),
  },

  _theBanner: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "600px",
      objectFit: "cover",
    },
  },
  theOverlay: {
    position: "absolute",
    width: "80%",
    // height: "100%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    padding: "2rem 1rem",

    ...md("md", {
      width: "68%",
    }),
  },

  backgroundBlur: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    height: "150px",
    backgroundColor: "rgba(255, 255, 255, .3)", // Adjusted the alpha value
    borderRadius: "50%",
    boxShadow: "0 0 15px 100px rgba(255, 255, 255, .3)", // Adjusted box shadow
    opacity: 0.8, // Reduced opacity
    "&::before": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      background:
        "radial-gradient(ellipse at center, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, .5) 140%)", // Adjusted radial gradient
      zIndex: -1,
    },
  },

  //   ovalBlur: {
  //     position: "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     width: "400px",
  //     height: "150px",
  //     backgroundColor: "rgba(255, 255, 255, .35)",
  //     borderRadius: "50%",
  //     boxShadow: "0 0 10px 90px rgba(255, 255, 255, .35)",
  //     overflow: "hidden",
  //     // opacity: 0.4,
  //     "&::before": {
  //       // content: '""',
  //       position: "absolute",
  //       top: 0,
  //       left: 0,
  //       width: "100%",
  //       height: "100%",
  //       // background: "radial-gradient(ellipse at center, rgba(255, 255, 255, .3) 0%, rgba(255, 255, 255, .2) 140%)",
  //       zIndex: -1,
  //     },
  //   },
  theGlow: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
    "& img": {
      width: "100%",
      height: "auto"
    }
  }
};

export default makeStyles(config);
