import React from "react";
// import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";

import { Box, Container } from "@mui/material";
import Section from "../../components/Section/Section";

export default function PaymentUOB() {
  const classes = useStyles();

  const [paynowCode, setPaynowCode] = React.useState("");

  React.useEffect(() => {
    // Get the current URL
    const currentURL = window.location.href;

    // Find the index of "paynow" in the URL
    const paynowIndex = currentURL.indexOf("paynow");

    // Extract the value after "paynow"
    const paynowValue = currentURL.substring(paynowIndex + "paynow".length + 1);

    // Log the extracted value
    console.log(paynowValue);
    setPaynowCode(paynowValue);
  }, []);

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="4rem 0">
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", sm: "50%", md: "40%" },
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  border: "1px solid ",
                  background: "#2a2866",
                  padding: "2rem 2rem",
                }}
              >
                <Paragraph
                  bold="700"
                  size="22px"
                  color="#fff"
                  center
                  margin="0 0 1rem 0"
                >
                  Pay via PayNow
                </Paragraph>
                <div style={{ textAlign: "center" }}>
                  {/* <img src={paynowCode}/> */}
                  {/* <img
                  style={{ width: "100%" }}
                  src={
                    "https://chart.apis.google.com/chart?cht=qr&chs=450x450&chl=" +
                    paynowCode
                  }
                ></img> */}
                  <img
                    style={{ width: "100%" }}
                    src={
                      "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" +
                      paynowCode
                    }
                  />
                </div>
              </Box>
            </Box>
          </Box>
        </Section>
      </Container>

      {/* <Footer /> */}
    </React.Fragment>
  );
}
