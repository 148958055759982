import React from "react";
import { BrowserRouter } from "react-router-dom";
import CartContextProvider from "./context/CartContext";
import Routes from "./Routes";
import "./index.css";
function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      // alert("Please switch to Desktop Version to continue.");
    };
  }, []);

  const isMobile = width <= 768;

  React.useEffect(() => {
    if (window.localStorage.getItem("cart") === null) {
      window.localStorage.setItem("cart", JSON.stringify([]));
    }
  }, []);
  

  return (
    <>
      {isMobile ? (
        // <>
        //   <div className="box">
        //     <div className="head">
        //       <div className="eyes">
        //         <div className="eye left"></div>
        //         <div className="eye right"></div>
        //       </div>
        //       <div className="blushes">
        //         <div className="blush left"></div>
        //         <div className="blush right"></div>
        //       </div>
        //       <div className="mouth">
        //         <div className="lip left"></div>
        //         <div className="lip right"></div>
        //       </div>
        //     </div>
        //     <div className="shadow"></div>
        //   </div>
        // </>
        <CartContextProvider>
          <BrowserRouter>
            <Routes></Routes>
          </BrowserRouter>
        </CartContextProvider>
      ) : (
        <CartContextProvider>
          <BrowserRouter>
            <Routes></Routes>
          </BrowserRouter>
        </CartContextProvider>
      )}
    </>
  );
}

export default App;
