import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField, Input, FormControl } from "@mui/material";

export const NavLinking = styled.a`
  position: relative;
  width: 100%;
  color: #1e1e1e;
  text-align: center;
  display: block;
  // padding: 0.4rem 0;

  .MuiMenuItem-root {
    display: block;
  }
  .MuiListItem-button:hover {
    background-color: #7f222c !important;
  }
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //   border: 1px solid #c5c5c5;
  // border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: ${(props) => props.border};
    border-radius: ${(props) => props.br};
  }
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 15,

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: -35,

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      bottom: "-25px !important",
      ...md("sm", {
        bottom: "0px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          //   backgroundColor: "#eee",
          border: "1px solid #979797",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#979797",
          },
        },
      },
    },
  },

  theL: {
    display: "block",
    alignItems: "center",
    // padding: ".4rem 2rem",
    color: "#000",
    background: "transparent",

    "& :hover": {
      // padding: ".4rem 2rem",
      background: "#6290BC",
      color: "#fff",
    },
  },

  theLActive: {
    display: "block",
    alignItems: "center",
    // padding: ".4rem 2rem",
    color: "#fff",
    background: "#125396",

    "& :hover": {
      // padding: ".4rem 2rem",
      background: "#6290BC",
      color: "#fff",
    },
  },

  theBox: {
    background: "#fff",
    // padding: "1rem",
    // height: "500px",
  },

  theList: {
    border: "1px solid #707070",
    padding: "1rem",
    borderRadius: "4px",
  },

  //

  theCard: {
    position: "relative",
    background: "#fff",
    padding: "1rem",
    boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.2)",
    borderRadius: "6px",
  },

  thePos: {
    float: "right",
  },

  theFlex: {
    display: "block",
    alignItems: "center",

    ...md("sm", {
      display: "flex",
      alignItems: "center",
    }),
  },

  thePIC: {
    position: "relative",
    width: "50%",
    margin: "0 auto",

    ...md("sm", {
      width: "20%",
      margin: "unset",
      marginRight: "2rem",
    }),

    "& img": {
      width: "100%",
      borderRadius: "50%",
      objectFit: "cover",
    },
  },

  theBelowIcon: {
    position: "absolute",
    bottom: "0",
    right: "0",
    width: "24%",

    ...md("sm", {
      width: "20%",
    }),

    ...md("xmd", {
      width: "auto",
    }),

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theFlex_: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "1rem",
  },

  theDefaultCard: {
    position: "relative",
    padding: "1rem",
    background: "#353535",
    borderRadius: "6px",
    marginBottom: "1.5rem",
    boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.2)",
  },

  theOtherCard: {
    position: "relative",
    padding: "1rem",
    // background: "#353535",
    borderRadius: "6px",
    marginBottom: "1.5rem",
    boxShadow: "0px 3px 10px 0px rgba(0,0,0,0.2)",
  },

  theFTable: {
    padding: "1rem",
  },

  theSizeBtn: {
    width: "100%",
    ...md("md", {
      width: "20%",
      float: "right",
    }),
  },

  theSizeSearch: {
    // width: "100%",
    // ...md("md", {
    //   width: "30%",
    //   float: "right",
    // }),
    width: "100%",
  },

  theRed: {
    color: "red !important",
  },

  theDefault: {
    color: "#000 !important",
  },

  theFlexBulk: {
    display: "block",
    ...md("md", {
      display: "flex",
      width: "100%",
      alignItems: "center",
    }),
  },

  theBulkBtn: {
    width: "100%",
    marginTop: "1rem",
    ...md("md", {
      width: "30%",
      marginTop: 0,
    }),
  },

  theOverScroll: {
    display: "flex",
    overflow: "scroll",
    ...md("md", {
      overflow: "unset",
      flexWrap: "wrap",
    }),
  },
};

export default makeStyles(config);
