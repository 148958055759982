import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
// import "./styles.css";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Remove,
} from "@mui/icons-material";
import { customer_login } from "../../API/API";
import { useNavigate } from "react-router-dom";

export default function Register() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    // setLoginData({
    //   ...loginData,
    //   [e.target.name]: e.target.value,
    // });
  };

  const handleSignUp = () => {
    // if (loginData.email.length == "" || loginData.password.length == "") {
    //   alert("All Field Required");
    //   return;
    // }
    // customer_login(loginData).then((json) => {
    //   if (json.status) {
    //     alert(json.message);
    //     window.location.reload();
    //     navigate("/")
    //   } else {
    //     alert(json.message);
    //   }
    // });
  };

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section
          pd="2rem 0"
          style={{
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            position: "absolute",
            width: "80%",
          }}
        >
          <Grid container spacing={0}>
            <Grid
              xs={12}
              sm={12}
              md={6}
              sx={{
                background: "#878787",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ padding: "2rem" }}>
                <Paragraph size="22px" color="#FFF" margin="0 0 .4rem 0" bold="600" center>
                  Welcome Back
                </Paragraph>
                <Paragraph size="14px" color="#FFF" center>
                  Keep connected with us, please login with your email
                </Paragraph>
                <br />
                <Button
                  href="/login"
                  label="LOGIN"
                  box="1px solid #FFF"
                  color="#FFF"
                  bg="#878787"
                  style={{ width: "30%", margin: "0 auto" }}
                />
              </div>
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <div
                style={{ padding: "5rem 2rem", border: "1px solid #d7d7d7" }}
              >
                <Paragraph
                  color="#878787"
                  size="22px"
                  bold="600"
                  margin="0 0 1rem 0"
                >
                  Create An Account
                </Paragraph>
                <FormInputNoLabel
                  placeholder="Email"
                  name="email"
                  bg="#eaeaea"
                  br="0px"
                  onChange={handleChange}
                />
                 <FormInputNoLabel
                  placeholder="Name"
                  name="name"
                  bg="#eaeaea"
                  br="0px"
                  onChange={handleChange}
                />
                <FormInputNoLabel
                  placeholder="Password"
                  type="password"
                  name="password"
                  bg="#eaeaea"
                  br="0px"
                  onChange={handleChange}
                />
                 <FormInputNoLabel
                  placeholder="Confirm Password"
                  type="password"
                  name="password2"
                  bg="#eaeaea"
                  br="0px"
                  onChange={handleChange}
                />
                <br />
                <Button
                  label="SIGN UP"
                  box="1px solid #878787"
                  color="#878787"
                  style={{ width: "30%", margin: "0 auto" }}
                  onClick={() => handleSignUp()}
                />
              </div>
            </Grid>
          </Grid>
        </Section>
      </Container>
    </React.Fragment>
  );
}
