import React from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  Button as Btn,
  Dialog,
  DialogContent,
  Grid,
  Select,
  Divider,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SidebarMenu from "../SidebarMenu/SidebarMenu";

import Button from "../Button/Button";
import FormInput from "../FormInput/FormInput";
import logo from "../../assets/images/logo.svg";
import malaysia from "../../assets/images/malaysia.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarLogo_,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavLinking,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  NavbarUpper,
} from "./styles";

import {
  AccountCircleOutlined,
  FavoriteBorderOutlined,
  Close,
  LockOutlined,
  Phone,
  CorporateFareOutlined,
  EmailOutlined,
  PersonOutlined,
  FeedOutlined,
  BuildOutlined,
  Public,
  InsertPhoto,
  AutoFixNormal,
  AccountCircleRounded,
  ShoppingBagOutlined,
  LoginOutlined,
  GroupAdd,
  ShoppingCartOutlined,
  Warning,
  ErrorRounded,
} from "@mui/icons-material";
import Paragraph from "../Typography/Paragraph";
import "./styles.css";
import useCart from "../../hooks/useCart";
import { get_customer_cart, get_customer_profile } from "../../API/API";

const Navbar = () => {
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [newCartist, setNewCartList] = React.useState([]);
  React.useEffect(() => {
    let cart = window.localStorage.getItem("cart");
    setNewCartList(JSON.parse(cart));
  }, [window.localStorage.getItem("cart")]);

  const [openSideMenu, setOpenSideMenu] = React.useState({ left: false });

  const [openSideCart, setOpenSideCart] = React.useState({ right: false });

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [profileData, setProfileData] = React.useState({});

  const [checkUserType, setCheckUserType] = React.useState("");

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
        setCheckUserType(json.data.paymenttype);

        // get_customer_cart({
        //   customer_contact_id: json.data.customer_contact_id,
        //   token: token,
        // }).then((json) => {
        //   window.localStorage.setItem("cart", JSON.stringify(json.data));
        // });
      } else {
        // alert(json.message);
        window.localStorage.removeItem("token");
        // history("/login");
      }
    });
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  // HANDLE LOGOUT

  const handleLogout = () => {
    // if (checkUserType == "CASH") {
    //   window.localStorage.removeItem("token");
    //   window.localStorage.removeItem("cart");
    //   history("/");
    //   window.location.reload();
    // } else {
    //   window.localStorage.removeItem("token");
    //   history("/");
    //   window.location.reload();
    // }
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("cart");
    // window.location.reload();
    history("/login");
  };

  // API AUTH

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
  }, []);

  const handleNext = () => {
    if (token) {
      history("/shop");
    } else {
      history("/login");
    }
  };

  return (
    <>
      <NavbarContainer>
        {/* <div className={classes.theHead}>
        <Container>
            <Paragraph color="#FFF" size="14px">
              The latest 2022 catalog is here.{" "}
              <a style={{ color: "#FFF", textDecoration: "underline" }}>
                {" "}
                Download now
              </a>
            </Paragraph>
          </Container>
        </div> */}
        <Container maxWidth="xxl" style={{ background: "rgb(42,40,102)" }}>
          <AppBar position="static">
            <Toolbar>
              {isMobile ? (
                <>
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleDrawerToggle("left", true)}
                  >
                    <MenuIcon color="#FFF" />
                  </IconButton>
                  <NavbarMobileLogo>
                    <Link to="/">
                      <img src={logo} />
                    </Link>
                  </NavbarMobileLogo>
                  <NavbarIconMobileList>
                    {token ? (
                      <>
                        <NavbarIconLink
                          href="/cart"
                          style={{ marginRight: "1rem" }}
                        >
                          <ShoppingCartOutlined style={{ color: "#FFF" }} />
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#2a2866",
                              background: "#FFF",
                              fontSize: "12px",
                            }}
                          >
                            {newCartist.length}
                          </div>
                        </NavbarIconLink>

                        <NavbarIconLink onClick={handleMenu}>
                          <AccountCircleRounded style={{ color: "#FFF" }} />
                        </NavbarIconLink>

                        {profileData.daysoverdue > 0 && (
                          <Tooltip
                            title={
                              <p
                                style={{
                                  fontSize: "14px",
                                  margin: 0,
                                  padding: "0 .4rem",
                                }}
                              >
                                You have {profileData.daysoverdue} days past due
                                invoice.
                              </p>
                            }
                            placement="bottom"
                            sx={{ backgroundColor: "#F92000" }}
                          >
                            <ErrorRounded
                              sx={{
                                color: "#F92000",
                                marginLeft: "1rem",
                              }}
                            />
                          </Tooltip>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <NavbarIconLink onClick={handleMenu}>
                          <AccountCircleRounded style={{ color: "#FFF" }} />
                        </NavbarIconLink> */}
                        <a
                          href="/login"
                          style={{
                            padding: "0 1rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Paragraph
                            margin="0 0 0 1rem"
                            color="#FFF"
                            bold="500"
                            size="14px"
                          >
                            LOGIN
                          </Paragraph>
                        </a>
                      </>
                    )}
                  </NavbarIconMobileList>

                  <SidebarMenu
                    anchor="left"
                    open={openSideMenu.left}
                    onOpen={handleDrawerToggle("left", true)}
                    onClose={handleDrawerToggle("left", false)}
                    handleDrawerToggle={handleDrawerToggle("left", false)}
                  />
                </>
              ) : (
                <>
                  <NavbarList>
                    <Container>
                      <NavbarUpper>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "70%",
                              // justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            {token ? (
                              <>
                                <Paragraph color="#FFF" bold="600">
                                  Welcome, {profileData.name}
                                </Paragraph>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              marginLeft: "auto",
                              marginRight: "auto",
                              padding: "1rem 0",
                            }}
                          >
                            <NavbarLogo>
                              <a href="/">
                                <img src={logo} />
                              </a>
                            </NavbarLogo>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              width: "80%",
                              justifyContent: "flex-end",
                              alignItems: "center",
                            }}
                          >
                            {token ? (
                              <>
                                <div style={{ display: "flex" }}>
                                  <NavbarIconLink
                                    href="/cart"
                                    style={{ marginRight: "1.5rem" }}
                                  >
                                    <ShoppingCartOutlined
                                      style={{ color: "#FFF" }}
                                    />
                                    {newCartist && newCartist.length == 0 ? (
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "#2a2866",
                                          background: "#FFF",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {cart.length}
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          width: "20px",
                                          height: "20px",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "#2a2866",
                                          background: "#FFF",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {/* {newCartist.length} */}
                                        {newCartist && newCartist.length > 0
                                          ? newCartist.length
                                          : 0}
                                      </div>
                                    )}
                                  </NavbarIconLink>
                                </div>

                                <div
                                  style={{
                                    display: "flex",
                                    // marginLeft: "2rem",
                                  }}
                                >
                                  {profileData.paymenttype == "Cash" && (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          flexDirection: "column",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          style={{ fontStyle: "italic" }}
                                        >
                                          Balance:
                                        </Paragraph>
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          bold="700"
                                        >
                                          {profileData.current_balance}
                                        </Paragraph>
                                      </div>
                                    </>
                                  )}
                                  {profileData.paymenttype == "Credit" && (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          flexDirection: "column",
                                          marginRight: "1rem",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <Paragraph
                                          // margin="0 1rem 0 0"
                                          color="#FFF"
                                          size="14px"
                                          style={{ fontStyle: "italic" }}
                                        >
                                          My Credit Limit:
                                        </Paragraph>
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          bold="700"
                                        >
                                          {profileData.creditlimit}
                                        </Paragraph>
                                      </div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          flexDirection: "column",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          style={{ fontStyle: "italic" }}
                                        >
                                          Balance:
                                        </Paragraph>
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          bold="700"
                                        >
                                          {profileData.current_balance}
                                        </Paragraph>
                                      </div>
                                    </>
                                  )}
                                  {profileData.paymenttype == "Prepaid" && (
                                    <>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          flexDirection: "column",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          style={{ fontStyle: "italic" }}
                                        >
                                          Debit Balance:
                                        </Paragraph>
                                        <Paragraph
                                          color="#FFF"
                                          size="14px"
                                          bold="700"
                                        >
                                          {profileData.current_depositbalance}
                                        </Paragraph>
                                      </div>
                                    </>
                                  )}
                                </div>
                                {/* <NavbarIconLink
                                  style={{ marginLeft: "2rem" }}
                                  onClick={handleMenu}
                                >
                                  <AccountCircleRounded
                                    style={{ color: "#FFF" }}
                                  />
                                </NavbarIconLink> */}
                                <a
                                  onClick={() => handleLogout()}
                                  style={{
                                    padding: "0 1rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Paragraph
                                    margin="0 0 0 1rem"
                                    color="#FFF"
                                    bold="500"
                                    size="14px"
                                  >
                                    LOGOUT
                                  </Paragraph>
                                </a>
                                {profileData.daysoverdue > 0 && (
                                  <Tooltip
                                    title={
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          margin: 0,
                                          padding: "0 .4rem",
                                        }}
                                      >
                                        You have {profileData.daysoverdue} days
                                        past due invoice.
                                      </p>
                                    }
                                    placement="bottom"
                                    sx={{ backgroundColor: "#F92000" }}
                                  >
                                    <ErrorRounded
                                      sx={{
                                        color: "#F92000",
                                        marginLeft: "1rem",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </>
                            ) : (
                              <>
                                {/* <a
                                  href="/register"
                                  style={{
                                    borderRight: "1px solid #FFF",
                                    padding: "0 1rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Paragraph
                                    margin="0 0 0 1rem"
                                    bold="500"
                                    size="14px"
                                    color="#FFF"
                                  >
                                    REGISTER
                                  </Paragraph>
                                </a> */}
                                <a
                                  href="/login"
                                  style={{
                                    // padding: "0 1rem",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Paragraph
                                    margin="0 0 0 1rem"
                                    color="#FFF"
                                    bold="500"
                                    size="14px"
                                  >
                                    LOGIN
                                  </Paragraph>
                                </a>
                              </>
                            )}
                          </div>
                        </div>
                      </NavbarUpper>
                      <NavbarLower>
                        <NavbarLowerList>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              // width: "80%",
                              margin: "0 auto",
                            }}
                          >
                            <NavbarLink color="#D5AE35" href="/">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === ""
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === ""
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Home
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink color="#D5AE35" href="/about">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "about"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "about"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  About Us
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink color="#D5AE35" href="/catalogue">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "catelogue"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "catelogue"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Catalogue
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink
                              color="#D5AE35"
                              onClick={() => handleNext()}
                            >
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "shop"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "shop"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Shop
                                </Paragraph>
                              </div>
                            </NavbarLink>

                            <NavbarLink color="#D5AE35" href="/bestbuy">
                              <div>
                                <Paragraph
                                  style={{
                                    animation: "blinkingText 0.5s infinite",
                                    textShadow:
                                      "0 0 2px red, 0 0 4px red, 0 0 6px red, 0 0 8px red, 0 0 12px red, 0 0 16px red, 0 0 22px red, 0 0 30px red",
                                  }}
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "bestbuy"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "bestbuy"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  BEST BUY
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink color="#000" href="/readytowear">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "readytowear"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "readytowear"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Ready to Wear
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink color="#000" href="/custommade">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "custommade"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "custommade"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Custom Made
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink color="#000" href="/decorative">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "decorative"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "decorative"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Decorative
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            <NavbarLink color="#000" href="/contact">
                              <div>
                                <Paragraph
                                  center
                                  color={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "contact"
                                      ? "#FFF"
                                      : "#FFF"
                                  }
                                  bold={
                                    location.pathname.replace(
                                      /[^A-Za-z]+/g,
                                      ""
                                    ) === "contact"
                                      ? "600"
                                      : "normal"
                                  }
                                  className={classes.theFont}
                                >
                                  Contact Us
                                </Paragraph>
                              </div>
                            </NavbarLink>
                            {token && (
                              <NavbarLink color="#000" href="/account">
                                <div>
                                  <Paragraph
                                    center
                                    color={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "account"
                                        ? "#FFF"
                                        : "#FFF"
                                    }
                                    bold={
                                      location.pathname.replace(
                                        /[^A-Za-z]+/g,
                                        ""
                                      ) === "account"
                                        ? "600"
                                        : "normal"
                                    }
                                    className={classes.theFont}
                                  >
                                    My Account
                                  </Paragraph>
                                </div>
                              </NavbarLink>
                            )}
                          </div>
                        </NavbarLowerList>
                      </NavbarLower>
                    </Container>
                  </NavbarList>
                </>
              )}

              <>
                <Menu
                  id="menu-appbar"
                  elevation={0}
                  // getContentAnchorEl={null}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  // anchorPosition={{
                  //   top: 300,
                  // }}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                >
                  {token ? (
                    <MenuItem>
                      <NavLinking>
                        {profileData.paymenttype == "Cash" && (
                          <>
                            <Paragraph
                              color="#2a2866"
                              size="14px"
                              style={{ fontStyle: "italic" }}
                            >
                              Balance:
                            </Paragraph>
                            <Paragraph
                              color="#2a2866"
                              size="14px"
                              bold="700"
                              margin="0 0 .4rem 0"
                            >
                              {profileData.balance}
                            </Paragraph>
                          </>
                        )}
                        {profileData.paymenttype == "Credit" && (
                          <>
                            <Paragraph
                              // margin="0 1rem 0 0"
                              color="#2a2866"
                              size="14px"
                              style={{ fontStyle: "italic" }}
                            >
                              My Credit Limit:
                            </Paragraph>
                            <Paragraph
                              color="#FFF"
                              size="14px"
                              bold="700"
                              margin="0 0 .4rem 0"
                            >
                              {profileData.creditlimit}
                            </Paragraph>

                            <Paragraph
                              color="#2a2866"
                              size="14px"
                              style={{ fontStyle: "italic" }}
                            >
                              Balance:
                            </Paragraph>
                            <Paragraph
                              color="#2a2866"
                              size="14px"
                              bold="700"
                              margin="0 0 .4rem 0"
                            >
                              {profileData.balance}
                            </Paragraph>
                          </>
                        )}
                        {profileData.paymenttype == "Perpaid" && (
                          <>
                            <Paragraph
                              color="#2a2866"
                              size="14px"
                              style={{ fontStyle: "italic" }}
                            >
                              Debit Balance:
                            </Paragraph>
                            <Paragraph
                              color="#2a2866"
                              size="14px"
                              bold="700"
                              margin="0 0 .4rem 0"
                            >
                              {profileData.depositbalance}
                            </Paragraph>
                          </>
                        )}
                        <div>
                          <Button
                            label="My Account"
                            bg="#2a2866"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            href="/account"
                            style={{ marginBottom: ".6rem" }}
                          />

                          <Button
                            label="Sign Out"
                            bg="#2a2866"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            onClick={() => handleLogout()}
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  ) : (
                    <MenuItem>
                      <NavLinking>
                        <div>
                          <Button
                            label="Register"
                            bg="#FFF"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            style={{ marginBottom: ".6rem" }}
                            href="/register"
                          />
                          <Button
                            label="Login"
                            bg="#FFF"
                            color="#fff"
                            bold="500"
                            br="4px"
                            pd=".2rem 1rem"
                            size="12px"
                            // onClick={() => handleOpenLogin()}

                            href="/login"
                          />
                        </div>
                      </NavLinking>
                    </MenuItem>
                  )}
                </Menu>
              </>
            </Toolbar>
          </AppBar>
        </Container>
      </NavbarContainer>
    </>
  );
};

export default Navbar;
