import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

const config = {
  thePadd: {
    padding: "5rem 1rem",
    border: "1px solid #d7d7d7",
    ...md("md", {
      padding: "5rem 2rem",
    }),
  },
  theBoxz: {
    flexDirection: "column-reverse",
    ...md("md", {
      flexDirection: "row",
    }),
  },

  thePadd2: {
    height: "220px",
    padding: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    ...md("md", {
      height: "auto",
    }),
  },
};

export default makeStyles(config);
