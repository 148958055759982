import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import building from "../../assets/images/building.jpg";

const config = {
  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "flex"
    },
  },

  theIMGG: {
    position: "relative",
    width: "70%",
    margin: "0 auto",
    "& img": {
      width: "100%",
      display: "flex"
    },
  },

  theIMG2: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      height: "350px",
      display: "flex",
      objectFit: "cover"
    },
  },

  theAboutIMG: {
    position: "relative",
    width: "60%",
    "& img": {
      width: "100%",
      display: "flex",
      height: "150px",
      objectFit: "cover",
      ...md("sm", {
        height: "auto",
        objectFit: "unset",
      }),
    },
  },

  theBackG: {
    background: "#2a2866",
    height: "150px",
    ...md("sm", {
      height: "auto",
    }),
  },
  theBanner: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "800px",
      objectFit: "cover"
    },
  },
  theOverlay: {
    position: "absolute",
    width: "100%",
    // height: "100%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
  },

  theOverlayText:{
    position: "absolute",
    width: "90%",
    // height: "100%",
    transform: "translate(-50%, -50%)",
    bottom: "0",
    left: "50%"
  },

  theOverlayText2:{
    position: "absolute",
    width: "90%",
    // height: "100%",
    transform: "translate(-50%, -50%)",
    top: "10%",
    left: "50%"
  },
  theDis: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },

  theDisIMG: {
    width: "11%",
    "& img":{
      width: "100%"
    }
  },

  theClient: {
    display: "flex",
  flexWrap: "wrap"
  },

  theClientIMG: {
    flexBasis: "20%",
    flexGrow: 0,
    maxWidth: "20%",
    "& img":{
      width: "80%",
      margin: "0 auto"
    }
  },

  theBGG: {
    height: "450px",
    backgroundImage: "url(" + building + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    // display: "none",
    ...md("md", {
      height: "80vh",
      backgroundImage: "url(" + building + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
    }),
  },

};

export default makeStyles(config);
