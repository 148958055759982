import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl } from "@mui/material";

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-clip: padding-box;
  // border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  // margin-bottom: 1rem !important;

  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  theBG_: {
    position: "relative",
    padding: "2rem",
    background: "#402313",
  },

  theLogo: {
    position: "relative",
    width: "50%",
    margin: "0 auto",

    "& img": {
      width: "100%",
    },
  },

  theBox_: {
    position: "relative",
    background: "#fff",
    padding: "1rem",
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
  },

  theIMG: {
    position: "relative",
    width: "20%",
    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "20%",
    }),

    ...md("md", {
      width: "10%",
    }),
  },

  theWidth: {
    width: "100vw",

    ...md("sm", {
      width: "50vw",
    }),

    ...md("md", {
      width: "100%",
    }),
  },

  theWidth2: {
    width: "150px",

    ...md("sm", {
      width: "unset",
    }),

    ...md("md", {
      // width: "150px",
      width: "unset",
    }),
  },

  theFlexx: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "flex-end",
    }),
  },

  theRight: {
    marginBottom: "1rem",
    ...md("sm", {
      marginRight: "1rem",
      marginBottom: 0,
    }),
  },

  theFlex_: {
    display: "block",
    marginBottom: "1rem",

    ...md("sm", {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "0",
    }),
  },

  theWidths: {
    width: "100%",

    ...md("sm", {
      width: "80%",
    }),

    ...md("md", {
      width: "60%",
    }),
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: "1rem 0",
    justifyContent: "center",
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "75%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theButtonStyle2: {
    color: "#353535",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  theListAddress: {
    border: "2px solid #353535",
    borderRadius: "6px",
    padding: "1rem",
    marginBottom: "1rem",
  },

  theListAddressDefault: {
    border: "1px solid #d7d7d7",
    borderRadius: "6px",
    padding: "1rem",
    marginBottom: "1rem",
  },

  thePurchaseBox: {
    position: "relative",
    width: "100%",
    margin: "0 auto",
    marginBottom: "1rem",

    ...md("sm", {
      width: "60%",
    }),

    ...md("md", {
      width: "40%",
    }),

    ...md("xmd", {
      width: "35%",
    }),

    "& img": {
      width: "100%",
    },
  },

  thePurchase: {
    position: "relative",
    width: "64%",
    margin: "0 auto",
    marginBottom: "1rem",

    ...md("md", {
      width: "84%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theFlexx_: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "center",
    }),
  },
};

export default makeStyles(config);
