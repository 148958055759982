import React from "react";
import FormLabel from "../Typography/FormLabel";
import { FormGroup, Span, TextInput } from "./styles";

const FormInput = ({
  children,
  textLabelColor,
  bold,
  label,
  margin,
  bg,
  textcolor,
  mb,
  border,
  br,
  required,
  ...props
}) => {
  return (
    <FormGroup mb={mb}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormLabel
          required={required}
          textLabelColor={textLabelColor}
          bold={bold}
          margin={margin}
        >
          {label}
        </FormLabel>
        <Span
          required={required}
          style={{ color: "#F92000", marginLeft: "4px" }}
        >
          *
        </Span>
      </div>
      <TextInput
        bg={bg}
        br={br}
        textcolor={textcolor}
        border={border}
        {...props}
      >
        {children}
      </TextInput>
    </FormGroup>
  );
};

export default FormInput;
