import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "34px",
    ...md("sm", {
      fontSize: "44px",
    }),
    ...md("md", {
      fontSize: "54px",
    }),
    ...md("xmd", {
      fontSize: "64px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("md", {
      fontSize: "20px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theBox: {
    position: "absolute",
    // width: "100%",
    top: "50%",
    left: "5%",
    transform: "translateY(-50%)",

    ...md("md", {
      top: "50%",
      width: "50%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theProdIMG: {
    position: "rerlative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theGalleryList: {
    position: "relative",
    display: "flex",
    // background: "#333",
    overflow: "auto",
    whiteSpace: "nowrap",
  },

  theGalleryx: {
    position: "relative",
    marginRight: "1rem",
    "& img": {
      width: "100px",
    },
  },

  theGallery: {
    position: "relative",
    width: "100%",
    marginBottom: ".4rem",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },
  theIcon: {
    position: "relative",
    width: "8%",
    marginRight: ".4rem",
    "& img": {
      width: "100%",
    },
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    // margin: "1rem 0",
    // border: "1px solid #BA2025",
    width: "30%",
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "100%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "1.4rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theButtonStyle2: {
    color: "#353535",
    fontSize: "1.4rem",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theBox: {
    position: "relative",
    width: "50%",
    borderRadius: "6px",
    padding: " 0.65rem 0",
  },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  theSizeBtn: {
    width: "100%",
    ...md("md", {
      width: "20%",
      float: "right",
    }),
  },
};

export default makeStyles(config);
