import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { FormControl } from "@mui/material";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //   border: 1px solid #c5c5c5;
  // border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #CED4DA;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "24px",
    ...md("md", {
      fontSize: "34px",
    }),
    ...md("xmd", {
      fontSize: "44px",
    }),
    ...md("lg", {
      fontSize: "54px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("xmd", {
      fontSize: "23px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theBox: {
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",

    ...md("md", {
      top: "50%",
      left: "50%",
      //   width: "100%",
    }),

    ...md("xmd", {
      width: "60%",
    }),
  },

  theFlex: {
    display: "block",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    ...md("md", {
      display: "flex",
    }),
  },

  theTabList: {
    display: "block",
    width: "100%",
    ...md("md", {
      display: "flex",
      justifyContent: "center",
    }),
  },

  theMB: {
    marginTop: "2rem",
    display: "block",
    fontStyle: "italic",
    ...md("md", {
      marginTop: "0rem",
      fontStyle: "unset",
    }),
  },

  theTabActive: {
    borderBottom: "2px solid #800000",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  theTab: {
    borderBottom: "2px solid #FFF",
    marginRight: "1.5rem",
    cursor: "pointer",
  },
  // theGallery: {
  //   position: "relative",
  //   width: "100%",
  //   // marginBottom: "1rem",

  //   "& img": {
  //     width: "100%",
  //     display: "flex",
  //   },
  // },

  theWidth: {
    display: "flex",
    width: "100%",
    alignItems: "center",

    ...md("sm", {
      width: "45%",
    }),

    ...md("md", {
      width: "40%",
    }),

    ...md("xmd", {
      width: "45%",
    }),
  },

  theWidth01: {
    width: "25%",
    ...md("sm", {
      width: "30%",
    }),
    ...md("md", {
      width: "35%",
    }),
    ...md("xmd", {
      width: "25%",
    }),
  },

  theWidth02: {
    marginLeft: "1rem",
    width: "100%",

    ...md("md", {
      width: "35%",
    }),
  },

  theProdIMG: {
    position: "rerlative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theGalleryList: {
    position: "relative",
    display: "flex",
    // background: "#333",
    overflow: "auto",
    whiteSpace: "nowrap",
  },

  theGalleryx: {
    position: "relative",
    marginRight: "1rem",
    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "contain"
    },
  },

  theGallery: {
    position: "relative",
    width: "100%",
    marginBottom: ".4rem",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },
  theIcon: {
    position: "relative",
    width: "12%",
    marginRight: ".4rem",
    "& img": {
      width: "100%",
    },
  },

  theFlexQuantity: {
    position: "relative",
    alignItems: "center",
    display: "flex",
    margin: ".8rem 0",
    justifyContent: "center",
    // border: "1px solid #BA2025",
    // width: '30%'
  },

  theIncrement: {
    justifyContent: "space-between",
    alignItems: "center",
    display: "flex",
    width: "52%",
  },

  theButtonStyle: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theButtonStyle2: {
    color: "#353535",
    fontSize: "14px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  // theBox: {
  //   position: "relative",
  //   width: "50%",
  //   borderRadius: "6px",
  //   padding: " 0.65rem 0",
  // },

  theCount: {
    color: "#264653",
    fontWeight: "600",
    textAlign: "center",
    margin: 0,
  },

  theBtn: {
    position: "relative",
    margin: "21px 0 0 0",
  },

  theSizeBtn: {
    width: "100%",
    ...md("md", {
      width: "20%",
      float: "right",
    }),
  },

  theIMGx: {
    position: "relative",
    width: "180px",
    "& img": {
      width: "100%",
    },

    ...md("sm", {
      width: "50%",
    }),

    ...md("md", {
      width: "25%",
    }),
  },

  // theWidth: {
  //   display: "flex",
  //   width: "100%",
  //   alignItems: "center",

  //   ...md("sm", {
  //     width: "70%",
  //   }),

  //   ...md("md", {
  //     width: "60%",
  //   }),

  //   ...md("xmd", {
  //     width: "45%",
  //   }),
  // },

  theWidth2: {
    width: "150px",

    ...md("sm", {
      width: "unset",
    }),

    ...md("md", {
      // width: "150px",
      width: "unset",
    }),
  },

  theFlexx: {
    display: "block",

    ...md("sm", {
      display: "flex",
      justifyContent: "flex-end",
    }),
  },

  theRight: {
    marginBottom: "1rem",
    ...md("sm", {
      marginRight: "1rem",
      marginBottom: 0,
    }),
  },

  theBoxx: {
    width: "100%",
    ...md("md", {
      width: "50%",
      margin: "0 auto",
    }),
  },

  theProdShirt: {
    position: "relative",
    width: "60%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theOverlayy: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,.3)",
    borderRadius: "4px",
  },

  theTextt: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "#CC0000",
    padding: ".6rem 1rem",
    width: "60%"
  },
};

export default makeStyles(config);
