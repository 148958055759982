import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Remove,
} from "@mui/icons-material";
import headerdecorative from "../../assets/images/decorative.jpg";
import { get_decorative, get_website_banner } from "../../API/API";
import glow from "../../assets/images/glow.png";

export default function Decorative() {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const [decorative, setDecorative] = React.useState([]);

  const getDecorative = () => {
    get_decorative({ foo: "asd" }).then((json) => {
      setDecorative(json.data);
    });
  };

  React.useEffect(() => {
    getDecorative();
  }, []);

  const [banner, setBanner] = React.useState([]);

  const getBanner = () => {
    get_website_banner().then((json) => {
      setBanner(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />

      {banner
        .filter((item) => item.page === "Decorative")
        .sort((a, b) => a.weight - b.weight)
        .map((item) => (
          <div className={classes.theBanner} key={item.banner_id}>
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt={`Banner ${item.banner_id}`}
            />
            <Box className={classes.theGlow}>
              <img src={glow} />
              <div className={classes.theOverlay}>
                {/* <div className={classes.backgroundBlur}></div> */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    zIndex: 999,
                  }}
                >
                  <Paragraph
                    color="#58595b"
                    size="28px"
                    margin="0 0 1rem 0"
                    bold="600"
                    center
                    style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
                  >
                    {item.title}
                  </Paragraph>
                  <Paragraph
                    color="#58595b"
                    size="18px"
                    bold="600"
                    center
                    style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
                  >
                    {item.subtitle}
                  </Paragraph>
                </Box>
              </div>
            </Box>
          </div>
        ))}

      <Container sx={{ height: "500px" }}>
        <Section pd="2rem 0">
          <div style={{ marginTop: "0" }}>
            <Slider className={classes.root} {...settings}>
              {decorative
                .sort((a, b) => a.decorative_id - b.decorative_id)
                .map((item) => (
                  <div>
                    <div className={classes.theBoxx}>
                      <div
                        style={{
                          width: "70%",
                          margin: "0 auto",
                          boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
                          // padding: "3rem 2rem",
                          background: "#fff",
                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.theIMG}>
                              <img src={item.image} />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <div className={classes.theText}>
                              <Paragraph
                                bold="600"
                                color="#125396"
                                size="20px"
                                margin="0 0 1rem 0"
                              >
                                {item.name}
                              </Paragraph>
                              <Paragraph color="#000" size="14px">
                                {item.description}
                              </Paragraph>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        </Section>
      </Container>
      <Footer />
    </React.Fragment>
  );
}
