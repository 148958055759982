import React, { useContext } from "react";
import { validate_cart } from "../API/API";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);

  const [token, setToken] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState(0);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    // console.log("CURRENT TOKEN", token);
  }, []);

  const calculateCart = async () => {
    let postparam = {
      item: JSON.stringify(cart),
      token: token,
    };

    var res = await validate_cart(postparam);
    if (res.status) {
      setTotalAmount(res.total_amount);

    }
  };

  function getCart() {
    // calculateCart();
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    // calculateCart();
    setCart(cart);
  }

  // function addProduct(item) {
  //   console.log(item);
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var newItem = { ...item };

  //   newItem.price = getPriceByQuantity(newItem, newItem.quantity).toFixed(2);

  //   if (cartTemp.length === 0) {
  //     newItem["index"] = 0;  
  //     cartTemp.push(newItem);
  //   } else {
  //     var existed = 0;
  //     var index = 0;

  //     for (var i = 0; i < cartTemp.length; i++) {
  //       if (cartTemp[i].product_id == newItem.product_id) {
  //         existed = 1;
  //         index = i;
  //       }
  //     }

  //     if (existed == 1) {
  //       var new_quantity =
  //         parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);
  //       cartTemp[index]["quantity"] = new_quantity;
  //       cartTemp[index]["price"] = parseFloat(
  //         getPriceByQuantity(cartTemp[index], new_quantity).toFixed(2)
  //       );
  //     } else {
  //       newItem["index"] = cartTemp.length;
  //       cartTemp.push(newItem);
  //     }
  //   }
  //   setCart(cartTemp);
  //   Storage(cartTemp);
  // }

  // function addProduct(item) {
  //   console.log(item);
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var newItem = { ...item };

  //   newItem.price = getPriceByQuantity(newItem, newItem.quantity).toFixed(2);

  //   if (cartTemp.length === 0) {
  //     newItem["index"] = 0;
  //     cartTemp.push(newItem);
  //   } else {
  //     var existed = 0;
  //     var index = 0;

  //     for (var i = 0; i < cartTemp.length; i++) {
  //       if (cartTemp[i].product_id == newItem.product_id) {
  //         existed = 1;
  //         index = i;
  //       }
  //     }

  //     if (existed == 1) {
  //       var new_quantity =
  //         parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);
  //       cartTemp[index]["quantity"] = new_quantity;
  //       cartTemp[index]["price"] = parseFloat(
  //         getPriceByQuantity(cartTemp[index], new_quantity).toFixed(2)
  //       );
  //     } else {
  //       newItem["index"] = cartTemp.length;
  //       cartTemp.push(newItem);
  //     }
  //   }
  //   setCart(cartTemp);
  //   Storage(cartTemp);
  // }

  // function addProduct(item) {
  //   console.log(item);
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var newItem = { ...item };
  //   console.log(cartTemp);
  //   newItem.price = getPriceByQuantity(newItem, newItem.quantity).toFixed(2);
  //   console.log(cartTemp.length);
  //   if (cartTemp.length === 0) {
  //     newItem["index"] = 0;
  //     cartTemp.push(newItem);
  //   } else {
  //     var existed = 0;
  //     var index = 0;

  //     for (var i = 0; i < cartTemp.length; i++) {
  //       console.log(cartTemp[i].item);

  //       if (cartTemp[i].product_id == newItem.product_id) {
  //         existed = 1;
  //         index = i;
  //       }

  //       let arrItem = cartTemp[i].item;

  //       for (var j = 0; j < arrItem.length; j++) {
  //         if (arrItem[i].internalid == newItem.internalid) {
  //           existed = 1;
  //           index = i;
  //         }
  //       }
  //     }

  //     if (existed == 1) {
  //       var new_quantity =
  //         parseFloat(cartTemp[index].quantity) + parseFloat(newItem.quantity);
  //       cartTemp[index]["quantity"] = new_quantity;
  //       cartTemp[index]["price"] = parseFloat(
  //         getPriceByQuantity(cartTemp[index], new_quantity).toFixed(2)
  //       );
  //     } else {
  //       newItem["index"] = cartTemp.length;
  //       cartTemp.push(newItem);
  //     }
  //   }
  //   setCart(cartTemp);
  //   Storage(cartTemp);
  // }

  // function addProduct(item) {
  //   // console.log(item);
  //   if (cart.length == 0) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   // var newItem = { ...item };
  //   // cartTemp.push(newItem);

  //   setCart(item);
  //   Storage(item);
  // }

  function addProduct(item) {
    console.log(item);
    var cartTemp = [...cart];
    
    for (var i = 0; i < item.length; i++) {
      var newItem = { ...item[i] };
      var existingIndex = cartTemp.findIndex(cartItem => cartItem.internalid === newItem.internalid);
    
      if (existingIndex !== -1) {
        cartTemp[existingIndex].quantity += newItem.quantity; // Update quantity
      } else {
        newItem.index = cartTemp.length;
        cartTemp.push(newItem);
      }
    }
    
    setCart(cartTemp);
    Storage(cartTemp);
  }
  
  

  //   function addProduct(item) {
  //   console.log(item);
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var newItem = { ...item };

  //   if (cartTemp.length === 0) {
  //     cartTemp.push(newItem);
  //   } else {
  //     var existed = 0;
  //     var index = 0;

  //     for (var i = 0; i < cartTemp.length; i++) {
  //       if (cartTemp[i].internalid == newItem.internalid) {
  //         existed = 1;
  //         index = i;
  //       }
  //     }

  //     if (existed == 1) {
  //     } else {
  //       newItem["index"] = cartTemp.length;
  //       cartTemp.push(newItem);
  //     }
  //   }
  //   setCart(cartTemp);
  //   Storage(cartTemp);
  // }

  function getTotalCart() {
    
    var total_amount = 0;

    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }

    for (var i = 0; i < cartTemp.length; i++) {
      var arr = cartTemp[i].price;
      total_amount = parseFloat(total_amount) + parseFloat(arr);
    }
   
    return total_amount;
  }

  // function getTotalCart() {
  //   var total_amount = 0;

  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }
  //   var newArr = [];

  //   for (var i = 0; i < cartTemp.length; i++) {
  //     newArr = cartTemp[i].item;

  //     for (var j = 0; j < newArr.length; j++) {
  //       var newPrice = newArr[j].price;
  //       total_amount = parseFloat(total_amount) + parseFloat(newPrice);
  //     }
  //   }
  //   return total_amount;
  // }

  function removeProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }

    let postparam ={
      item: JSON.stringify(cartTemp), token: token
    }

    validate_cart(postparam).then(
      (json) => {
        // window.localStorage.setItem("cart", JSON.stringify(json.data));
      }
    );
    // calculateCart();
    setCart(cartTemp);
    Storage(cartTemp);
  }

  // function removeProduct(index) {
  //   if (cart.length) {
  //     var cartTemp = [...cart];
  //   } else {
  //     var cartTemp = [];
  //   }

  //   var newArr = [];

  //   for (var i = 0; i < cartTemp.length; i++) {
  //     newArr = cartTemp[i].item;
  //     console.log(newArr);
  //     for (var j = 0; j < newArr.length; j++) {
  //       newArr.splice(index, 1);

  //       newArr["index"] = i;
  //     }f
  //   }

  //   if (newArr == []) {
  //     cartTemp.splice(index, 1);

  //     for (var i = 0; i < cartTemp.length; i++) {
  //       cartTemp[i]["index"] = i;
  //     }
  //   }

  //   setCart(cartTemp);
  //   Storage(cartTemp);
  // }

  function getPriceByQuantity(cartRowItem, qty) {
    return cartRowItem.single_price * qty;
  }

  function increaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) + 1;
    cartTemp[index]["quantity"] = quantity;
    cartTemp[index]["price"] = parseFloat(
      getPriceByQuantity(cartTemp[index], quantity)
    );

    calculateCart();
    setCart(cartTemp);
    Storage(cartTemp);
  }

  function decreaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) - 1;

    if (quantity > 0) {
      cartTemp[index]["quantity"] = quantity;
      cartTemp[index]["price"] = getPriceByQuantity(cartTemp[index], quantity);
    } else {
      cartTemp.splice(index, 1);
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]["index"] = i;
      }
    }
    // if (cart.length) {
    //   var cartTemp = [...cart];
    //   var newArr = [];
    //   for (var i = 0; i < cartTemp.length; i++) {
    //     newArr = cartTemp[i].item;
    //     // console.log(newArr);
    //   }
    // } else {
    //   var cartTemp = [];
    // }

    // for (var i = 0; i < cartTemp.length; i++) {
    //   newArr = cartTemp[i].item;

    //   var quantity = parseFloat(newArr[index].quantity) - 1;

    //   if (quantity > 0) {
    //     newArr[index]["quantity"] = quantity;
    //     newArr[index]["price"] = parseFloat(
    //       newArr[index]["single_price"] * quantity
    //     );
    //   } else {
    //     newArr.splice(index, 1);
    //     for (var i = 0; i < newArr.length; i++) {
    //       newArr[i]["index"] = i;
    //     }
    //   }
    // }
    calculateCart();
    setCart(cartTemp);
    Storage(cartTemp);
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    setCartFunction,
    decreaseProduct,
    increaseProduct,
  };
};

export default useCart;
