import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import {
  Container,
  Grid,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";

import "./styles.css";
import {
  custom_made,
  get_apparel,
  get_color,
  get_material,
  get_decoration,
  get_website_banner,
} from "../../API/API";

import Loader from "react-js-loader";

import custommade from "../../assets/images/custommade.jpg";
import glow from "../../assets/images/glow.png";

export default function CustomMade() {
  const classes = useStyles();

  // API

  const [color, setColor] = React.useState([]);
  const [material, setMaterial] = React.useState([]);
  const [apparel, setApparel] = React.useState([]);
  const [decoration, setDecoration] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const getColor = () => {
    setLoading(true);
    get_color({ foo: "asd" }).then((json) => {
      setColor(json.data);
      setLoading(false);
    });
  };

  const getMaterial = () => {
    setLoading(true);
    get_material({ foo: "asd" }).then((json) => {
      setMaterial(json.data);
      setLoading(false);
    });
  };

  const getApparel = () => {
    setLoading(true);
    get_apparel({ foo: "asd" }).then((json) => {
      setApparel(json.data);
      setLoading(false);
    });
  };

  const getDecoration = () => {
    setLoading(true);
    get_decoration({ foo: "asd" }).then((json) => {
      setDecoration(json.data);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    getColor();
    getMaterial();
    getApparel();
    getDecoration();
  }, []);

  const [customData, setCustomData] = React.useState({
    name: "",
    company: "",
    contact: "",
    email: "",
  });

  const handleChange = (e) => {
    setCustomData({
      ...customData,
      [e.target.name]: e.target.value,
    });
  };

  const [chooseColor, setChooseColor] = React.useState("");
  const [chooseMaterial, setChooseMaterial] = React.useState("");
  const [choosePattern, setChoosePattern] = React.useState("");
  const [chooseDecoration, setChooseDecoration] = React.useState("");

  const handleChooseColor = (e) => {
    setChooseColor(e.target.value);
  };

  const handleChooseMaterial = (e) => {
    setChooseMaterial(e.target.value);
  };

  const handleChoosePattern = (e) => {
    setChoosePattern(e.target.value);
  };

  const handleChooseDecoration = (e) => {
    setChooseDecoration(e.target.value);
  };

  const handleSubmit = () => {
    if (chooseColor == "") {
      alert("Please choose a color");
      return;
    }

    if (chooseMaterial == "") {
      alert("Please choose a material");
      return;
    }

    if (choosePattern == "") {
      alert("Please choose a pattern");
      return;
    }

    if (chooseDecoration == "") {
      alert("Please choose a decoration");
      return;
    }

    if (
      customData.name.length == "" ||
      customData.company.length == "" ||
      customData.contact.length == "" ||
      customData.email.length == ""
    ) {
      alert("All Field Required");
      return;
    }

    custom_made({
      ...customData,
      color: chooseColor,
      material: chooseMaterial,
      apparel_pattern: choosePattern,
      decoration: chooseDecoration,
    }).then((json) => {
      if (json.status) {
        alert("Enquiry Successful");
        window.location.reload();
      } else {
        alert(json.data);
      }
    });
  };

  const [banner, setBanner] = React.useState([]);

  const getBanner = () => {
    get_website_banner().then((json) => {
      setBanner(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />

      {banner
        .filter((item) => item.page === "Custom Made")
        .sort((a, b) => a.weight - b.weight)
        .map((item) => (
          <div className={classes._theBanner} key={item.banner_id}>
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt={`Banner ${item.banner_id}`}
            />
            <Box className={classes.theGlow}>
              <img src={glow} />
              <div className={classes.theOverlay}>
                {/* <div className={classes.backgroundBlur}></div> */}
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    zIndex: 999,
                  }}
                >
                  <Paragraph
                    color="#58595b"
                    size="28px"
                    bold="600"
                    margin="0 0 1rem 0"
                    center
                    style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
                  >
                    {item.title}
                  </Paragraph>
                  <Paragraph
                    color="#58595b"
                    size="18px"
                    bold="600"
                    center
                    style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
                  >
                    {item.subtitle}
                  </Paragraph>
                </Box>
              </div>
            </Box>
          </div>
        ))}
        

      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#878787"} size={50} />
        </div>
      ) : (
        <Container>
          <Section pd="2rem 0">
            <div style={{ border: "1px solid #2a2866" }}>
              <div style={{ background: "#2a2866", padding: ".8rem 2rem" }}>
                <Paragraph color="#FFF" bold="600">
                  STEP 1: Choose Your Colors & Material
                </Paragraph>
              </div>
              <div style={{ padding: "0 2rem" }}>
                <Paragraph margin="1rem 0" color="#2a2866" bold="600">
                  COLOR
                </Paragraph>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {color
                    .sort((a, b) => a.color_id - b.color_id)
                    .map((item) => (
                      <label
                        className="theRadioColorx"
                        onChange={handleChooseColor}
                      >
                        <input
                          type="radio"
                          name="radio"
                          value={item.color_id}
                        />
                        <span
                          className="checkmarkColorx"
                          style={{ background: item.name }}
                        ></span>
                      </label>
                    ))}
                </div>
                <br />
                <Paragraph margin="1rem 0" color="#2a2866" bold="600">
                  MATERIAL
                </Paragraph>

                {/* <Grid container spacing={3}>
                  {material.map((item) => (
                    <Grid item xs={6} sm={4} md={3}>
                      <label
                        className="theRadioMaterial"
                        onChange={handleChooseMaterial}
                      >
                        <input type="radio" name="radio2" value={item.name} />
                        <div className="checkmarkMaterial">
                          <div className={classes.theMaterial}>
                            <img src={item.image} />
                          </div>
                          <Paragraph center bold="600" margin="1rem 0 0 0">
                            {item.name}
                          </Paragraph>
                        </div>
                      </label>
                    </Grid>
                  ))}
                </Grid> */}
                <Grid container spacing={3}>
                  {material.map((item) => (
                    <Grid item xs={6} sm={4} md={2}>
                      <label
                        className="theRadioPattern"
                        onChange={handleChooseMaterial}
                      >
                        <input type="radio" name="radio2" value={item.name} />
                        <div className="checkmarkPattern">
                          <div className={classes.thePatternIMG}>
                            <img src={item.image} />
                          </div>
                          <Paragraph center bold="600">
                            {item.name}
                          </Paragraph>
                        </div>
                      </label>
                    </Grid>
                  ))}
                </Grid>
                <br />
              </div>
              <br />
            </div>
            <br />
            <div style={{ border: "1px solid #2a2866" }}>
              <div style={{ background: "#2a2866", padding: ".8rem 2rem" }}>
                <Paragraph color="#FFF" bold="600">
                  STEP 2: Select Apparel Pattern
                </Paragraph>
              </div>
              <div style={{ padding: "0 2rem" }}>
                <Paragraph margin="1rem 0" color="#2a2866" bold="600">
                  APPAREL PATTERN
                </Paragraph>

                {/* <label
                  className="theRadioPattern"
                  onChange={handleChoosePattern}
                >
                  <input type="radio" name="radio3" value="Pattern A" />
                  <div className="checkmarkPattern">
                    <div className={classes.thePatternIMG}>
                      <img src="" />
                    </div>
                    <Paragraph
                      color="#878787"
                      size="14px"
                      bold="600"
                      margin=".4rem 0"
                      center
                    >
                      Pattern A
                    </Paragraph>
                  </div>
                </label> */}

                <Grid container spacing={3}>
                  {apparel.map((item) => (
                    <Grid item xs={6} sm={4} md={2}>
                      <label
                        className="theRadioPattern"
                        onChange={handleChoosePattern}
                      >
                        <input
                          type="radio"
                          name="radio3"
                          value={item.apparel_pattern_id}
                        />
                        <div className="checkmarkPattern">
                          <div className={classes.thePatternIMG}>
                            <img src={item.image} />
                          </div>
                          <Paragraph center bold="600">
                            {item.name}
                          </Paragraph>
                        </div>
                      </label>
                    </Grid>
                  ))}
                </Grid>

                <br />
              </div>
              <br />
            </div>
            <br />
            <div style={{ border: "1px solid #2a2866" }}>
              <div style={{ background: "#2a2866", padding: ".8rem 2rem" }}>
                <Paragraph color="#FFF" bold="600">
                  STEP 3: Determine Decoration on Apparel
                </Paragraph>
              </div>
              <div style={{ padding: "0 2rem" }}>
                <Paragraph margin="1rem 0" color="#2a2866" bold="600">
                  APPAREL PATTERN
                </Paragraph>

                {/* <label
                  className="theRadioPattern"
                  onChange={handleChooseDecoration}
                >
                  <input type="radio" name="radio4" value="Slikscreen" />
                  <div className="checkmarkPattern">
                    <div className={classes.thePatternIMG}>
                      <img src="" />
                    </div>
                    <Paragraph
                      color="#878787"
                      size="14px"
                      bold="600"
                      margin=".4rem 0"
                      center
                    >
                      Silkscreen
                    </Paragraph>
                  </div>
                </label> */}

                <Grid container spacing={3}>
                  {decoration
                    .sort((a, b) => a.decoration_id - b.decoration_id)
                    .map((item) => (
                      <Grid item xs={6} sm={4} md={2}>
                        <label
                          className="theRadioPattern"
                          onChange={handleChooseDecoration}
                        >
                          <input
                            type="radio"
                            name="radio4"
                            value={item.decoration_id}
                          />
                          <div className="checkmarkPatternx">
                            <div className={classes.thePatternIMG}>
                              <img src={item.image} />
                            </div>
                            <Paragraph center bold="600" margin="1rem 0">
                              {item.name}
                            </Paragraph>
                          </div>
                        </label>
                      </Grid>
                    ))}
                </Grid>

                <br />
              </div>
              <br />
            </div>
            <br />
            <div style={{ border: "1px solid #2a2866" }}>
              <div style={{ background: "#2a2866", padding: ".8rem 2rem" }}>
                <Paragraph color="#FFF" bold="600">
                  STEP 4: Leave Your Contact Information
                </Paragraph>
                {/* <Paragraph color="#FFF" bold="600">
                STEP 3: Leave Your Contact Information
              </Paragraph> */}
              </div>
              <div style={{ padding: "1rem 2rem" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormInputNoLabel
                      placeholder="Name *"
                      name="name"
                      bg="#eaeaea"
                      br="0px"
                      onChange={handleChange}
                    />
                    <FormInputNoLabel
                      placeholder="Contact Number *"
                      name="contact"
                      bg="#eaeaea"
                      br="0px"
                      type="number"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <FormInputNoLabel
                      placeholder="Company Name *"
                      name="company"
                      bg="#eaeaea"
                      br="0px"
                      onChange={handleChange}
                    />
                    <FormInputNoLabel
                      placeholder="Email Address *"
                      name="email"
                      bg="#eaeaea"
                      br="0px"
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Paragraph
                  color="#2a2866"
                  size="12px"
                  margin="0 0 .4rem 0"
                  bold="500"
                >
                  Terms & Condition:
                </Paragraph>
                <Paragraph
                  color="#878787"
                  size="12px"
                  margin="0 0 .4rem 0"
                  bold="500"
                  style={{ display: "flex" }}
                >
                  <span>* </span> Special dye colors at 400pcs MOQ - Special
                  Fabric development at 5000 MOQ -Private label program.
                </Paragraph>
                <Paragraph
                  color="#878787"
                  size="12px"
                  margin="0 0 .4rem 0"
                  bold="500"
                  style={{ display: "flex" }}
                >
                  <span>* </span>A tolerance of +/- 5% on measurement, weight
                  and +/-5% of shrinkage should be allowed. We will exchange the
                  products in 1 to 1 basis not other than defects in fabrics,
                  workmanshhip @ processing error
                </Paragraph>
                <br />
                <Button
                  label="CONFIRM"
                  box="1px solid #2a2866"
                  color="#2a2866"
                  bold="600"
                  style={{ width: "20%", margin: "0 auto" }}
                  onClick={() => handleSubmit()}
                />
              </div>
              <br />
            </div>
          </Section>
        </Container>
      )}

      <Footer />
    </React.Fragment>
  );
}
