import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
        display: "flex",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 100,
        }),

        ...md("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 100,
        }),

        ...md("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...md("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#adadad",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
  root2: {
    position: "relative",
    margin: "2rem 0",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
      "& > div > div": {
        width: "90%",
        height: "100%",
        margin: "0 auto",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
      },

      "&.slick-next": {
        right: 13,

        ...md("sm", {
          right: 28,
        }),

        ...md("md", {
          right: 34,
        }),

        ...md("lg", {
          right: 44,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#878787",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...md("sm", {
          left: 20,
        }),

        ...md("md", {
          left: 30,
        }),

        ...md("lg", {
          left: 40,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#878787",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "-20px",
      ...md("sm", {
        bottom: "-20px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#192e56",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 10,
            backgroundColor: "#fff",
          },
        },
      },
    },
  },

  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theOverlay: {
    position: "absolute",
    width: "-webkit-fill-available",
    height: "-webkit-fill-available",
    top: 0,
    left: 0,
    padding: "1rem",
    background: "rgba(0,0,0,.5)",
    opacity: 0,
    "&:hover": {
      opacity: 1,
    },
  },

  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },

  theGallery: {
    position: "relative",
    width: "100%",
    // marginBottom: ".6rem",
    // margin: "3rem 0",

    "& img": {
      width: "100%",
      height: "300px",
      display: "flex",
      objectFit: "contain",
    },
  },
  theBSize: {
    position: "relative",
    width: "45%",
    margin: "0 auto",

    ...md("sm", {
      width: "30%",
    }),

    ...md("md", {
      width: "25%",
    }),
  },

  theSliIMG: {
    "& img": {
      width: "100%",
      height: "200px",
      objectFit: "cover",

      ...md("sm", {
        height: "auto",
        objectFit: "unset",
      }),
    },
  },

  theBTitle: {
    fontSize: "24px",
    ...md("sm", {
      fontSize: "34px",
    }),
  },

  theBanner: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },
};

export default makeStyles(config);
