import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles, { FormInputSelect } from "./styles";
import "./styles.css";
import {
  Person,
  Lock,
  Bookmark,
  Inbox,
  Close,
  Edit,
  Delete,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import {
  add_address,
  delete_address,
  edit_address,
  edit_profile,
  get_address,
  get_address_detail,
  get_orders_history,
  get_customer_profile,
  change_password,
  topup,
  process_order,
  get_country,
  get_state,
  validate_state,
} from "../../API/API";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { useNavigate } from "react-router-dom";

export default function Address() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  const [country, setCountry] = React.useState([]);
  const [chooseCountry, setChooseCountry] = React.useState("");

  const getCountry = () => {
    get_country({ token: token }).then((json) => {
      setCountry(json.data);
    });
  };

  const handleChooseCountry = (e) => {
    setChooseCountry(e.target.value);
    setSelectedCountry(e.target.value);
  };

  const [state, setState] = React.useState([]);
  const [chooseState, setChooseState] = React.useState("");

  const getState = () => {
    get_state({ token: token }).then((json) => {
      setState(json.data);
    });
  };

  const handleChooseState = (e) => {
    setChooseState(e.target.value);
    setSelectedState(e.target.value);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedState, setSelectedState] = React.useState("");

  const handleOpenEditModal = (customer_address_id) => {
    get_address_detail({ customer_address_id: customer_address_id }).then(
      (json) => {
        setAddressBookDetail(json.data);
        setSelectedCountry(json.data.country);
        setSelectedState(json.data.state);
        setZipCode(json.data.zip);

        if (json.data.defaultbilling == 1) {
          setDefaultBillingAddress(true);
          setChecked(true);
        } else {
          setDefaultBillingAddress(false);
          setChecked(false);
        }

        if (json.data.defaultshipping == 1) {
          setDefaultShippingAddress(true);
          setChecked2(true);
        } else {
          setDefaultShippingAddress(false);
          setChecked2(false);
        }
      }
    );

    setOpenEditModal(true, customer_address_id);
    setAddressBookID(customer_address_id);
    setCheckValidate(true);
  };

  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);

  const handleOpenModalTopUp = () => {
    setOpenTopUpModal(true);
  };

  // console.log(defaultBillingAddress);

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [profileData, setProfileData] = React.useState({
    naem: "",
    contact: "",
  });

  const [addressData, setAddressData] = React.useState({
    // name: "",
    contact_name: "",
    contact_number: "",
    addr1: "",
    addr2: "",
    state: "",
    country: chooseCountry,
    // phone: "",
    state: chooseState,
    city: "",
  });

  const [passwordData, setPasswordData] = React.useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const [creditData, setCreditData] = React.useState({
    amount: "",
  });

  const [addressList, setAddressList] = React.useState([]);

  const [addressBookID, setAddressBookID] = React.useState(0);

  const [zipCode, setZipCode] = React.useState("");

  const [addressBookDetail, setAddressBookDetail] = React.useState({
    // name: "",
    contact_name: "",
    contact_number: "",
    addr1: "",
    addr2: "",
    // country: selectedCountry,
    // zip: zipCode,
    // phone: "",
    city: "",
    // state: selectedState,
  });

  const [oderList, setOderList] = React.useState([]);

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
    setAddressBookDetail({
      ...addressBookDetail,
      [e.target.name]: e.target.value,
    });
    setCreditData({
      ...creditData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeZip = (e) => {
    setZipCode(e.target.value);
  };

  const handleChangeAddValid = (event) => {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputValue.length > 40) {
      alert(`${inputName} should not exceed 40 characters.`);
      inputValue = inputValue.slice(0, 40);
      event.target.value = inputValue;
    }

    setAddressData((prevData) => ({
      ...prevData,
      [inputName]: inputValue,
    }));
  };

  const handleChangeEditValid = (event) => {
    const inputName = event.target.name;
    let inputValue = event.target.value;

    if (inputValue.length > 40) {
      alert(`${inputName} should not exceed 40 characters.`);
      inputValue = inputValue.slice(0, 40);
      event.target.value = inputValue;
    }

    setAddressBookDetail((prevData) => ({
      ...prevData,
      [inputName]: inputValue,
    }));
  };

  const [checkValidate, setCheckValidate] = React.useState(true);

  // const getValidate2 = () => {
  //   validate_state({ zip: zipCode, state: selectedState }).then((json) => {
  //     if (json.status) {
  //       setCheckValidate(json.status);
  //     } else {
  //       setCheckValidate(json.status);
  //     }
  //   });
  // };

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  const getOderListtory = () => {
    get_orders_history({ token: token }).then((json) => {
      if (json.status) {
        setOderList(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  const getAddressBook = () => {
    get_address({ token: token }).then((json) => {
      if (json.status) {
        setAddressList(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  const getAddressBookDetail = () => {
    // get_address_detail({ customer_address_id: addressBookID }).then((json) => {
    //   setAddressBookDetail(json.data);
    //   if (json.data.defaultbilling == 1) {
    //     setDefaultBillingAddress(true);
    //     setChecked(true);
    //   } else {
    //     setDefaultBillingAddress(false);
    //     setChecked(false);
    //   }
    //   if (json.data.defaultshipping == 1) {
    //     setDefaultShippingAddress(true);
    //     setChecked2(true);
    //   } else {
    //     setDefaultShippingAddress(false);
    //     setChecked2(false);
    //   }
    // });
  };

  const [checked, setChecked] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);

  const [defaultBillingAddress, setDefaultBillingAddress] = React.useState("");
  const [defaultShippingAddress, setDefaultShippingAddress] =
    React.useState("");

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

    console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultBillingAddress("1");
    }
    if (event.target.checked == false) {
      setDefaultBillingAddress("0");
    }
  };

  const handleChangeCheck2 = (event) => {
    setChecked2(event.target.checked);

    console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultShippingAddress("1");
    }

    if (event.target.checked == false) {
      setDefaultShippingAddress("0");
    }
  };

  React.useEffect(() => {
    getProfile();
    getOderListtory();
    getAddressBook();
    getAddressBookDetail();
    getCountry();
    getState();
  }, [addressBookID, chooseState, zipCode]);

  // ADD NEW ADDRESS BOOK

  const handleAddAddress = () => {
    // if (
    //   // addressData.name.length == "" ||
    //   addressData.addr1.length == "" ||
    //   addressData.addr2.length == "" ||
    //   addressData.city.length == ""
    // ) {
    //   alert("All Field Required");
    //   return;
    // }

    // if (zipCode == "") {
    //   alert("All Field Required");
    //   return;
    // }

    // if (chooseCountry == "") {
    //   alert("All Field Required");
    //   return;
    // }

    // if (chooseState == "") {
    //   alert("All Field Required");
    //   return;
    // }

    console.log(addressData);

    validate_state({ zip: zipCode, state: chooseState }).then((json) => {
      if (json.status) {
        setCheckValidate(json.status);
        add_address({
          ...addressData,
          token: token,
          defaultbilling: defaultBillingAddress,
          defaultshipping: defaultShippingAddress,
          country: chooseCountry,
          state: chooseState,
          zip: zipCode,
        }).then((json) => {
          if (json.status) {
            alert("Added Successful");
            window.location.reload();
          } else {
            alert(json.message);
          }
        });
      } else {
        setCheckValidate(json.status);
        // alert(" Please Enter Valid Zip / Postal Code");
        // return;
      }
    });
  };

  // ADDRESS BOOK DETAIL

  // EDIT ADDRESS BOOK

  const handleEditAddress = () => {
    if (
      addressBookDetail.addr1.length == "" ||
      addressBookDetail.addr2.length == "" ||
      addressBookDetail.city.length == ""
    ) {
      alert("All Field Required");
      return;
    }

    if (zipCode == "") {
      alert("All Field Required");
      return;
    }

    if (selectedCountry == "") {
      alert("All Field Required");
      return;
    }

    validate_state({ zip: zipCode, state: selectedState }).then((json) => {
      if (json.status) {
        edit_address({
          ...addressBookDetail,
          token: token,
          customer_address_id: addressBookID,
          defaultbilling: defaultBillingAddress,
          defaultshipping: defaultShippingAddress,
          country: selectedCountry,
          state: selectedState,
          zip: zipCode,
        }).then((json) => {
          if (json.status) {
            alert("Editted Successful");
            window.location.reload();
          } else {
            alert(json.message);
          }
        });
      } else {
        setCheckValidate(json.status);
        // alert(" Please Enter Valid Zip / Postal Code");
        // return;
      }
    });
  };

  console.log(checkValidate);

  // DELETE ADDRESS BOOK

  const handleDeleteAddress = (customer_address_id) => {
    delete_address({ customer_address_id: customer_address_id }).then(
      (json) => {
        if (json.status) {
          alert("Deleted Successful");
          window.location.reload();
        } else {
          alert(json.message);
        }
      }
    );
  };

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ background: "#E0F0F7", padding: "2rem 0 0 0" }}>
        <Container>
          <Paragraph color="#000" size="22px" bold="600" margin="0 0 1rem 0">
            My Account
          </Paragraph>
          <div className={classes.theOverScroll}>
            <a
              // className={tab == 0 ? classes.theLActive : classes.theL}
              // onClick={() => handleChangeTab(0)}
              className={classes.theL}
              href="/account"
            >
              <Paragraph
                color="#000"
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Profile
              </Paragraph>
            </a>
            <a className={classes.theL} href="/balance">
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Balance
              </Paragraph>
            </a>
            <a
              className={classes.theL}
              //   onClick={() => handleChangeTab(2)}
              href="/orderhistory"
            >
              <Paragraph
                color="#000"
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Order
              </Paragraph>
            </a>
            <a
              // className={tab == 3 ? classes.theLActive : classes.theL}
              // onClick={() => handleChangeTab(3)}
              className={classes.theLActive}
              href="/address"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                Address Book
              </Paragraph>
            </a>
            <a className={classes.theL} href="/statementaccount">
              <Paragraph
                color="#000"
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                Statement of Account
              </Paragraph>
            </a>
          </div>
        </Container>
      </div>
      <Container>
        <Section pd="2rem 0">
          <div>
            <div className={classes.theBox}>
              {/* <Paragraph size="20px" color="#353535">
                      Address Book
                    </Paragraph> */}
              <br />
              {addressList.map((item) => (
                <>
                  <div className={classes.theList}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={8}>
                        <div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Paragraph>
                              {item.entityid} -{" "}
                              <span style={{ paddingLeft: "20px" }}>
                                {" "}
                                {item.contact_name} {item.contact_number}
                              </span>
                            </Paragraph>
                          </div>
                          <Paragraph>
                            {item.addr1}, {item.addr2}, {item.city}, {item.zip},{" "}
                            {item.state}, {item.country}
                          </Paragraph>
                          <Paragraph
                            size="14px"
                            color="#637381"
                            margin="0 0 1rem 0"
                          >
                            {item.phone}
                          </Paragraph>
                          <div style={{ display: "flex" }}>
                            {item.defaultbilling == "1" && (
                              <Button
                                label="DEFAULT BILLING ADDRESS"
                                color="#FFF"
                                bg="#125396"
                                // box="1px solid #125396"
                                bold="600"
                                br="4px"
                                pd=".4rem 1rem"
                                size="12px"
                              />
                            )}
                            {item.defaultshipping == "1" && (
                              <Button
                                label="DEFAULT SHIPPING ADDRESS"
                                color="#FFF"
                                bg="#125396"
                                // box="1px solid #125396"
                                bold="600"
                                br="4px"
                                pd=".4rem 1rem"
                                size="12px"
                                style={{ margin: "0 1rem" }}
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={4}
                        style={{ display: "flex" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                          }}
                        >
                          <Edit
                            style={{
                              color: "#353535",
                              marginRight: "1rem",
                            }}
                            onClick={() =>
                              handleOpenEditModal(item.customer_address_id)
                            }
                          />
                          <Delete
                            style={{ color: "#353535", marginRight: "0" }}
                            onClick={() =>
                              handleDeleteAddress(item.customer_address_id)
                            }
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <br />
                </>
              ))}
              <div className={classes.theSizeBtn}>
                <Button
                  label="ADD NEW ADDRESS"
                  color="#fff"
                  bg="#125396"
                  bold="600"
                  hover
                  // br="6px"
                  onClick={() => handleOpenModal()}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
        </Section>
      </Container>

      {/* <Footer /> */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#353535" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#125396">
              Add New Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Contact Name"
                  placeholder="Contact Name"
                  border="1px solid #d7d7d7"
                  name="contact_name"
                  onChange={handleChangeAddValid}
                  // maxLength={40} // Add maxLength attribute
                  required
                />
                <FormInput
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  border="1px solid #d7d7d7"
                  name="addr1"
                  onChange={handleChangeAddValid}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="addr2"
                  onChange={handleChangeAddValid}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                {/* <FormInput
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                  required
                /> */}
                <Paragraph margin="1rem 0">
                  State{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // name="name"
                    value={chooseState}
                    onChange={handleChooseState}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a State
                      </em>
                    </MenuItem>
                    {state.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label="Contact Number"
                  placeholder="Contact Number"
                  border="1px solid #d7d7d7"
                  name="contact_number"
                  type="number"
                  onChange={handleChangeAddValid}
                  // maxLength={40} // Add maxLength attribute
                  required
                />
                <Paragraph margin="1.5rem 0">
                  Country{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // name="name"
                    value={chooseCountry}
                    onChange={handleChooseCountry}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a Country
                      </em>
                    </MenuItem>
                    {country.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                  required
                />
                {/* <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  name="zip"
                  onChange={handleChange}
                  required
                /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <Paragraph>
                    Zip / Postal Code
                    <span style={{ color: "#F92000", marginLeft: "4px" }}>
                      *
                    </span>
                  </Paragraph>
                  {checkValidate == true ? (
                    <></>
                  ) : (
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#F90000",
                        fontSize: "14px",
                      }}
                    >
                      Please Enter Valid Zip / Postal Code
                    </span>
                  )}
                </div>
                <FormInputNoLabel
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  value={zipCode}
                  onChange={handleChangeZip}
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={defaultBillingAddress}
                    />
                  }
                  label="Set As Default Billing Address"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked2}
                      onChange={handleChangeCheck2}
                      value={defaultShippingAddress}
                    />
                  }
                  label="Set As Default Shipping Address"
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* EDIT ADDRESS */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenEditModal(false)}>
                <Close style={{ color: "#353535" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#125396">
              Edit Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                  value={addressBookDetail.name}
                /> */}
                <FormInput
                  label="Contact Name"
                  placeholder="Contact Name"
                  border="1px solid #d7d7d7"
                  name="contact_name"
                  onChange={handleChangeEditValid}
                  value={addressBookDetail.contact_name}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                <FormInput
                  label="Address Line 1"
                  placeholder="Address 1"
                  border="1px solid #d7d7d7"
                  name="addr1"
                  onChange={handleChangeEditValid}
                  value={addressBookDetail.addr1}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="addr2"
                  onChange={handleChangeEditValid}
                  value={addressBookDetail.addr2}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                {/* <FormInput
                  // textDecoration="underline"
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                  value={addressBookDetail.state}
                  required
                /> */}
                <Paragraph margin="1rem 0">
                  State{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>

                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // name="name"
                    value={selectedState}
                    onChange={handleChooseState}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a State
                      </em>
                    </MenuItem>
                    {state.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                  value={addressBookDetail.country}
                /> */}
                <FormInput
                  label="Contact Number"
                  placeholder="Contact Number"
                  border="1px solid #d7d7d7"
                  name="contact_number"
                  onChange={handleChangeEditValid}
                  value={addressBookDetail.contact_number}
                  maxLength={40} // Add maxLength attribute
                  required
                />
                <Paragraph margin="1.5rem 0">
                  Country{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    // name="name"
                    value={selectedCountry}
                    onChange={handleChooseCountry}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a Country
                      </em>
                    </MenuItem>
                    {country.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={addressBookDetail.phone}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                  value={addressBookDetail.city}
                  required
                />{" "}
                {/* <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  name="zip"
                  type="number"
                  onChange={handleChange}
                  value={addressBookDetail.zip}
                  required
                /> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "1rem 0",
                  }}
                >
                  <Paragraph>
                    Zip / Postal Code
                    <span style={{ color: "#F92000", marginLeft: "4px" }}>
                      *
                    </span>
                  </Paragraph>
                  {checkValidate == true ? (
                    <></>
                  ) : (
                    <span
                      style={{
                        fontStyle: "italic",
                        color: "#F90000",
                        fontSize: "14px",
                      }}
                    >
                      Please Enter Valid Zip / Postal Code
                    </span>
                  )}
                </div>
                <FormInputNoLabel
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  value={zipCode}
                  // value={addressBookDetail.zip}
                  onChange={handleChangeZip}
                  required
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={defaultBillingAddress}
                    />
                  }
                  label="Set As Default Billing Address"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked2}
                      onChange={handleChangeCheck2}
                      value={defaultShippingAddress}
                    />
                  }
                  label="Set As Default Shipping Address"
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              br="6px"
              hover
              onClick={() => handleEditAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
