import React from "react";
import Slider from "react-slick";
import useStyles, {
  IncrementAction,
  IncrementAction2,
  FormInputSelect,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";

import silestone001 from "../../assets/images/silestone001.png";
import purchase from "../../assets/images/purchase.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Card from "../../components/Card/Card";
import { get_customer_profile } from "../../API/API";
import { useNavigate } from "react-router-dom";

export default function Payment() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [token, setToken] = React.useState("");

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [profileData, setProfileData] = React.useState({});

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
      }
    });
  };

  React.useEffect(() => {
    getProfile();
  }, []);

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="4rem 0 2rem 0">
          <div className={classes.thePurchaseBox}>
            <Paragraph
              size="24px"
              color="#125396"
              bold="700"
              center
              margin="0 0 2rem 0"
            >
              Thanks for shopping with us
            </Paragraph>
            <div className={classes.thePurchase}>
              <img src={purchase} />
            </div>
            <div>
              <Paragraph margin="0 0 1rem 0" bold="700`" color="#125396" center>
                Thank You! {profileData.name}
              </Paragraph>
              {/* <Paragraph margin="0 0 1rem 0" color="#000" center>
                Your payment is successful.
              </Paragraph> */}
            </div>
            <br />
            <div className={classes.theFlexx_}>
              <div>
                <Button
                  label="Back to Home"
                  //   br="4px"
                  color="#fff"
                  bg="#125396"
                  hover
                  href="/"
                />
              </div>
            </div>
          </div>
        </Section>
      </Container>
    </React.Fragment>
  );
}
