import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles, { FormInputSelect } from "./styles";
import "./styles.css";
import { Close } from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import { get_customer_profile, topup, get_topup_amount } from "../../API/API";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

export default function Balance() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);

  const handleOpenModalTopUp = () => {
    setOpenTopUpModal(true);
  };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [profileData, setProfileData] = React.useState({
    name: "",
    contact: "",
  });

  const [creditData, setCreditData] = React.useState({
    amount: "",
  });

  const [topAmount, setTopAmount] = React.useState([]);

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });

    setCreditData({
      ...creditData,
      [e.target.name]: e.target.value,
    });
  };

  const [checkCurrency, setCheckCurrency] = React.useState("");

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
        setCheckCurrency(json.data.currency);
      } else {
        // alert(json.message);
      }
    });
  };

  const getTopAmount = () => {
    get_topup_amount({ token: token }).then((json) => {
      setTopAmount(json.data);
    });
  };

  React.useEffect(() => {
    getProfile();
    getTopAmount();
  }, []);

  // TOP UP CREDIT

  const [chooseTopAmount, setChooseTopAmount] = React.useState("");

  const [paynowCode, setPaynowCode] = React.useState("");

  const handleChooseTopAmount = (e) => {
    setChooseTopAmount(e.target.value);
  };

  const handleTopUp = () => {
    // if (creditData.amount.length == "") {
    //   alert("All Field Required");
    //   return;
    // }
    if (chooseTopAmount == [] || "") {
      alert("Please Choose an Amount");
      return;
    }
    topup({ amount: chooseTopAmount, token: token }).then((json) => {
      if (json.type == "uob") {
        setOpenModalQR(true);
        setPaynowCode(json.data);
      } else {
        window.location.href = json.data;
        navigate(json.data);
      }
    });
  };

  // QR
  const [openModalQR, setOpenModalQR] = React.useState(false);

  const handleOpenModalQR = () => {
    setOpenModalQR(true);
  };

  const handleCloseQR = () => {
    setOpenModalQR(false);
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Navbar />
      <div style={{ background: "#e0f0f7", padding: "2rem 0 0 0" }}>
        <Container>
          <Paragraph color="#000" size="22px" bold="600" margin="0 0 1rem 0">
            My Account
          </Paragraph>
          <div className={classes.theOverScroll}>
            <a
              // className={tab == 0 ? classes.theLActive : classes.theL}
              // onClick={() => handleChangeTab(0)}
              className={classes.theL}
              href="/account"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Profile
              </Paragraph>
            </a>
            <a className={classes.theLActive} href="/balance">
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Balance
              </Paragraph>
            </a>
            <a
              className={classes.theL}
              //   onClick={() => handleChangeTab(2)}
              href="/orderhistory"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                My Order
              </Paragraph>
            </a>
            <a
              // className={tab == 3 ? classes.theLActive : classes.theL}
              // onClick={() => handleChangeTab(3)}
              className={classes.theL}
              href="/address"
            >
              <Paragraph
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                Address Book
              </Paragraph>
            </a>
            <a className={classes.theL} href="/statementaccount">
              <Paragraph
                color="#000"
                style={{ padding: ".4rem 2rem", width: "max-content" }}
              >
                Statement of Account
              </Paragraph>
            </a>
          </div>
        </Container>
      </div>
      <Container>
        <Section pd="2rem 0">
          <div>
            <>
              <div className={classes.theBox}>
                {profileData.paymenttype == "Credit" && (
                  <>
                    <Paragraph size="20px" bold="600" color="#878787">
                      My Credit Limit
                    </Paragraph>
                    <FormInput
                      label="Credit Limit"
                      placeholder="Credit Limit"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7"
                      value={profileData.creditlimit}
                      // disabled
                    />
                    <FormInput
                      label="Current/Available Balance"
                      placeholder="Current/Available Balance"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7"
                      value={profileData.current_balance}
                      // disabled
                    />
                  </>
                )}

                {profileData.paymenttype == "Cash" && (
                  <>
                    <Paragraph size="20px" bold="600" color="#878787">
                      My Balance
                    </Paragraph>

                    <FormInput
                      label="Current/Available Balance"
                      placeholder="Current/Available Balance"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7"
                      value={profileData.current_balance}
                      // disabled
                    />
                  </>
                )}

                {profileData.paymenttype == "Prepaid" && (
                  <>
                    <Paragraph size="20px" bold="600" color="#878787">
                      My Credit Limit
                    </Paragraph>
                    <FormInput
                      label="Deposit Balance"
                      placeholder="Deposit Balance"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7"
                      value={profileData.current_depositbalance}
                      // disabled
                    />
                  </>
                )}
              </div>
              <br />
              {profileData.paymenttype == "Prepaid" && (
                <div className={classes.theSizeBtn}>
                  <Button
                    label="TOP UP"
                    color="#fff"
                    bg="#125396"
                    bold="600"
                    // br="6px"
                    hover
                    onClick={() => handleOpenModalTopUp()}
                  />
                </div>
              )}

              <br />
              <br />
            </>
          </div>
        </Section>
      </Container>

      {/* TOP UP */}
      <Dialog
        open={openTopUpModal}
        onClose={() => setOpenTopUpModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenTopUpModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#125396" margin="0 0 1rem 0">
              Top Up My Credit
            </Paragraph>

            {/* <FormInput
              label="Top Up Amount"
              placeholder="Top Up Amount"
              border="1px solid #d7d7d7"
              name="amount"
              type="number"
              onChange={handleChange}
            /> */}

            <FormInputSelect>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                name="amount"
                value={chooseTopAmount}
                onChange={handleChooseTopAmount}
                style={{ marginBottom: "1rem" }}
              >
                <MenuItem value="" style={{ fontWeight: "400" }}>
                  <em style={{ color: "#878787" }}>Please Choose an Amount</em>
                </MenuItem>
                {topAmount.map((item) => (
                  <MenuItem value={item.amount} style={{ color: "#000" }}>
                    RM {item.amount}
                  </MenuItem>
                ))}
              </Select>
            </FormInputSelect>

            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              onClick={() => handleTopUp()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* QR */}
      <Dialog
        open={openModalQR}
        onClose={() => setOpenModalQR(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent sx={{ background: "#2a2866" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <a onClick={() => handleCloseQR()}>
              <Close style={{ color: "#fff" }} />
            </a>
          </div>
          <div
            style={{
              padding: "0 2rem 1rem 2rem",
              background: "#2a2866",
            }}
          >
            <Paragraph
              bold="700"
              size="22px"
              color="#fff"
              center
              margin="0 0 1rem 0"
            >
              Pay via PayNow
            </Paragraph>
            <div style={{ textAlign: "center" }}>
              {/* <img
                style={{ width: "100%" }}
                src={
                  "https://chart.apis.google.com/chart?cht=qr&chs=450x450&chl=" +
                  paynowCode
                }
              ></img> */}
              <img
                style={{ width: "100%" }}
                src={
                  "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" +
                  paynowCode
                }
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
