import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Radio,
  RadioGroup,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  Select,
  Box,
  LinearProgress,
  linearProgressClasses,
  styled,
  Tooltip,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles, { FormInputSelect } from "./styles";
import "./styles.css";
import moment from "moment";
import {
  Close,
  InsertDriveFile,
  InsertPhoto,
  Note,
  ExpandMore,
  RemoveRedEye,
  Refresh,
  ErrorRounded,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import {
  add_address,
  delete_address,
  edit_address,
  edit_profile,
  get_address,
  get_orders_history,
  get_completed_orders_history,
  get_customer_profile,
  change_password,
  topup,
  process_order,
  return_order,
  upload_consignment,
  get_upload_file,
  get_report_reason,
  upload_bankslip,
  invoice_payment,
  bulk_order,
  get_upload_consignment_note,
  upload_item_images,
  get_credit_note_by_token,
  get_customer_return_by_token,
  get_orders_history_count,
  get_invoice,
  sync_invoice_list,
  get_rebate,
  get_orders_detail,
  check_invoice_payment,
} from "../../API/API";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import { useDropzone } from "react-dropzone";

import gold from "../../assets/images/gold.svg";
import platinum from "../../assets/images/platinum.png";
import silver from "../../assets/images/silver.svg";
import bronze from "../../assets/images/bronze.svg";
import info from "../../assets/images/info.svg";

export default function OrderHistory() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [tab, setTab] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const [salesOrderID, setSalesOrderID] = React.useState("");
  // const [customerID, setCustomerID] = React.useState("");

  const handleOpenEditModal = (customer_address_id) => {
    setOpenEditModal(true, customer_address_id);
    setAddressBookID(customer_address_id);
  };

  const [openModal2, setOpenModal2] = React.useState(false);

  const handleOpenModal2 = (sales_order_id) => {
    setOpenModal2(true, sales_order_id);
    setSalesOrderID(sales_order_id);
  };

  const [openModal3, setOpenModal3] = React.useState(false);
  const [itemData, setItemData] = React.useState([]);

  const handleOpenModal3 = (sales_order_id, item) => {
    setOpenModal3(true, sales_order_id);
    setSalesOrderID(sales_order_id);
    setItemData(sales_order_id.item);
  };

  const handleCloseModal3 = () => {
    setOpenModal3(false);
    setChooseReason("");
  };

  const [openModal4, setOpenModal4] = React.useState(false);

  const handleOpenModal4 = (sales_order_id) => {
    setOpenModal4(true, sales_order_id);
    setSalesOrderID(sales_order_id);
  };

  const [openModal5, setOpenModal5] = React.useState(false);

  const handleOpenModal5 = (item) => {
    setOpenModal5(true, item);
    // setSalesOrderID(sales_order_id);
  };

  const [checked, setChecked] = React.useState(true);

  const [defaultAddress, setDefaultAddress] = React.useState("T");

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

    // console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultAddress("T");
    }

    if (event.target.checked == false) {
      setDefaultAddress("F");
    }
  };

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, [salesOrderID]);

  const [countHis, setCountHis] = React.useState({});

  const getCountHistory = () => {
    get_orders_history_count({ token: token }).then((json) => {
      setCountHis(json.data);
    });
  };

  React.useEffect(() => {
    getCountHistory();
  }, []);

  const [profileData, setProfileData] = React.useState({
    naem: "",
    contact: "",
  });

  const [addressData, setAddressData] = React.useState({
    // name: "",
    addr1: "",
    addr2: "",
    state: "",
    country: "",
    // phone: "",
    city: "",
    zip: "",
  });

  const [passwordData, setPasswordData] = React.useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const [creditData, setCreditData] = React.useState({
    amount: "",
  });

  const [addressList, setAddressList] = React.useState([]);

  const [addressBookID, setAddressBookID] = React.useState(0);

  const [addressBookDetail, setAddressBookDetail] = React.useState({
    // name: "",
    addr1: "",
    addr2: "",
    state: "",
    country: "",
    // phone: "",
    city: "",
    zip: "",
  });

  const [oderList, setOderList] = React.useState([]);
  const [oderListComplete, setOderListComplete] = React.useState([]);
  const [reasonList, setReasonList] = React.useState([]);

  const [desp, setDesp] = React.useState({
    description: "",
  });

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
    setAddressBookDetail({
      ...addressBookDetail,
      [e.target.name]: e.target.value,
    });
    setCreditData({
      ...creditData,
      [e.target.name]: e.target.value,
    });
    setDesp({
      ...desp,
      [e.target.name]: e.target.value,
    });
  };

  const [checkCountry, setCheckCountry] = React.useState("");

  const [checkShippedData, setCheckShippedData] = React.useState([]);

  const [checkCurrency, setCheckCurrency] = React.useState("");
  const [paymentType, setPaymentType] = React.useState("");
  const [priceLevel, setPriceLevel] = React.useState("");
  const [terms, setTerms] = React.useState("");
  const [customerID, setCustomerID] = React.useState("");
  const [rebateData, setRebateData] = React.useState([]);

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
        setCheckCountry(json.data.subsidiary);
        setCheckCurrency(json.data.currency);
        setPriceLevel(json.data.pricelevel);
        setTerms(json.data.terms);
        setPaymentType(json.data.paymenttype);
        setCustomerID(json.data.customer_id);
      } else {
        alert(json.message);
        navigate("/login");
      }

      get_rebate({ customer_id: json.data.customer_id }).then((json) => {
        setRebateData(json.data.customerinfo);
      });
    });
  };

  const [loadMore, setLoadMore] = React.useState(false);
  const [page, setPage] = React.useState(1);

  // const getOderListtory = () => {
  //   setLoading(true);
  //   get_orders_history({ token: token, per_page: 1, page: 1 }).then((json) => {
  //     if (json.status) {
  //       setOderList(json.data);
  //       setLoading(false);
  //       // if (json.data.status_id == 5) {
  //       //   setCheckShippedData(json.data);
  //       // }
  //     } else {
  //       alert("System error. Please contact admin for futher request");
  //     }
  //   });
  // };

  const getOderListtory = async () => {
    setLoadMore(true);

    try {
      const json = await get_orders_history({
        token: token,
        per_page: page === 1 ? 50 : 10, // Load 50 data initially, then 10 data when "Load More" is clicked
        page: page,
      });

      if (json.status) {
        setOderList((prevData) => [...prevData, ...json.data]);
        setPage((prevPage) => prevPage + 1);
      } else {
        alert("System error. Please contact admin for further requests");
      }
    } catch (error) {
      console.error("ERROR FETCHING DATA:", error);
    }

    setLoadMore(false);
  };

  const [invoiceList, setInvoiceList] = React.useState([]);

  const getInvoice = () => {
    setLoading(true);
    get_invoice({ token: token }).then((json) => {
      if (json.status) {
        setInvoiceList(json.data);
        setLoading(false);
      } else {
        alert("System error. Please contact admin for futher request");
      }
    });
  };

  const [creditNote, setCreditNote] = React.useState([]);

  const getCreditNote = () => {
    get_credit_note_by_token({ token: token }).then((json) => {
      setCreditNote(json.data);
    });
  };

  const getOderListtoryComplete = () => {
    get_completed_orders_history({ token: token }).then((json) => {
      setOderListComplete(json.data);
    });
  };

  const getAddressBook = () => {
    get_address({ token: token }).then((json) => {
      if (json.status) {
        setAddressList(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  // const getAddressBookDetail = () => {
  //   get_address_detail({ customer_address_id: addressBookID }).then((json) => {
  //     // setAddressBookDetail(json.data);
  //   });
  // };

  const getReason = () => {
    get_report_reason({ token: token }).then((json) => {
      setReasonList(json.data);
    });
  };

  const [chooseReason, setChooseReason] = React.useState("");

  // const handleChooseReason = (val) => {
  //   setChooseReason(val);
  // };

  const handleChooseReason = (e) => {
    setChooseReason(e.target.value);
  };

  React.useEffect(() => {
    getProfile();
    getOderListtory();
    getAddressBook();
    // getAddressBookDetail();
    getOderListtoryComplete();
    getReason();
    getCreditNote();
    getInvoice();
  }, [addressBookID]);

  // EDIT PROFILE

  const handleEditProfile = () => {
    if (profileData.name.length == "" || profileData.contact.length == "") {
      alert("All Field Required");
    } else {
      edit_profile({
        ...profileData,
        token: token,
      }).then((json) => {
        if (json.status) {
          alert(json.message);
        } else {
          alert(json.message);
        }
      });
    }
  };

  // CHANGE PASSWORD

  const handleSavePassword = () => {
    if (
      passwordData.old_password.length == "" ||
      passwordData.password.length == "" ||
      passwordData.password2.length == ""
    ) {
      alert("All Field Required");
    } else {
      change_password({
        ...passwordData,
        token: token,
      }).then((json) => {
        if (json.status) {
          alert(json.message);
        } else {
          alert(json.message);
        }
      });
    }
  };

  // ADD NEW ADDRESS BOOK

  const handleAddAddress = () => {
    if (
      // addressData.name.length == "" ||
      addressData.addr1.length == "" ||
      addressData.addr2.length == "" ||
      addressData.city.length == "" ||
      addressData.zip.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert("All Field Required");
    }

    add_address({
      ...addressData,
      token: token,
      is_default: defaultAddress,
    }).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  // ADDRESS BOOK DETAIL

  // EDIT ADDRESS BOOK

  const handleEditAddress = () => {
    if (
      // addressBookDetail.name.length == "" ||
      addressData.addr1.length == "" ||
      addressData.addr2.length == "" ||
      addressData.city.length == "" ||
      addressData.zip.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert("All Field Required");
    }

    edit_address({
      ...addressBookDetail,
      token: token,
      customer_address_id: addressBookID,
      is_default: defaultAddress,
    }).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  // DELETE ADDRESS BOOK

  const handleDeleteAddress = (customer_address_id) => {
    delete_address({ customer_address_id: customer_address_id }).then(
      (json) => {
        if (json.status) {
          alert(json.message);
          window.location.reload();
        } else {
          alert(json.message);
        }
      }
    );
  };

  // TOP UP CREDIT

  const handleTopUp = () => {
    if (creditData.amount.length == "") {
      alert("All Field Required");
      return;
    }
    topup({ ...creditData, token: token }).then((json) => {
      if (json.status) {
        // alert("Top Up Successful");
        // window.location.reload();
        // navigate("https://megah.cysoft.co/admin/API/razer_pay/8/")
        window.location.href = json.data;
        navigate(json.data);
      } else {
        alert(json.message);
      }
    });
  };

  const handlePayNow = (sales_order_id) => {
    setLoading(true);
    process_order({ sales_order_id: sales_order_id }).then((json) => {
      if (json.status) {
        if (json.razer == "") {
          return;
        } else {
          window.open(json.data);
        }
        setLoading(false);
      } else {
        alert(json.message);
        setLoading(false);
      }
    });
  };

  const handleCloseQR = () => {
    setOpenModalQR(false);
    window.location.reload();
  };

  const handlePayNowSG = (sales_order_id) => {
    setLoading(true);

    const checkNotificationId = () => {
      get_orders_detail({ sales_order_id: sales_order_id }).then((json) => {
        setPaynowCode(json.jespa);
        setLoading(false);
        if (json.uob_notificationId == null || json.status == "Processing") {
          setOpenModalQR(true);
        } else {
          clearInterval(intervalId); // stop the interval once you have the notificationId
          navigate(`../orderhistorydetail/${sales_order_id}`);
        }
      });
    };

    // initial check
    checkNotificationId();

    // set up interval to check every 10 seconds
    const intervalId = setInterval(checkNotificationId, 10000);
  };

  const handleDownload = (item) => {
    window.open(item.file);
  };

  const handleDownloadDO = (item) => {
    window.open(item.dolink);
  };

  // const handleSave = () => {
  //   if (desp.description.length == "") {
  //     alert("All Field Required");
  //     return;
  //   } else {
  //     report_sales_order({ ...desp, sales_order_id: sales_order_id }).then(
  //       (json) => {
  //         if (json.status) {
  //           alert("Sumbitted");
  //         }
  //       }
  //     );
  //   }
  // };

  const [uploadPDF, setUploadPDF] = React.useState();

  const handleChangeUpload = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);

    var res = await get_upload_consignment_note(formData);
    if (res.data.status) {
      setUploadPDF({
        ...uploadPDF,
        image: res.data.data,
        sales_order_id: salesOrderID,
      });
    } else {
      alert(res.data.message);
    }
  };

  const [bulkOrder, setBulkOrder] = React.useState();

  const handleChangeUploadBulk = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);

    var res = await get_upload_file(formData);
    if (res) {
      setBulkOrder({
        ...bulkOrder,
        image: res.data.data,
        // sales_order_id: salesOrderID,
      });
    }
  };

  const [uploadReturn, setUploadReturn] = React.useState("");

  const handleChangeUploadReturn = async (e) => {
    var formData = new FormData();
    const files = e.target.files;
    formData.append("image_length", files.length);
    for (let i = 0; i < files.length; i++) {
      formData.append("images_" + i, files[i]);
    }

    upload_item_images(formData).then((res) => {
      if (res.status) {
        setUploadReturn({
          image: JSON.stringify(res.data.data),
        });
      }
    });
  };

  // const handleChangeUploadReturn = async (e) => {
  //   const file = e.target.files[0];
  //   var formData = new FormData();
  //   formData.append("image", file);

  //   var res = await get_upload_file(formData);
  //   if (res.status) {
  //     setUploadReturn({
  //       image: JSON.stringify(res.data.data),
  //     });
  //   }
  // };

  //

  //DropZone

  const dropzone = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: "2px",
    borderRadius: "2px",
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    marginBottom: ".6rem",
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    // width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const [files, setFiles] = React.useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // accept: "image/jpeg, image/png",
    onDrop: async (acceptedFiles) => {
      console.log(acceptedFiles);
      if (acceptedFiles) {
        const file = acceptedFiles[0];
        // const file = Array.from(acceptedFiles);

        var index = 0;

        var formData = new FormData();

        // for (let i = 0; i < file.length; i++) {
        //   formData.append("image[]", file[i]);
        //   index = i;
        // }

        formData.append("image", file);

        var res = await get_upload_consignment_note(formData);

        if (res.data.status) {
          setUploadPDF({
            ...uploadPDF,
            image: res.data.data,
            sales_order_id: salesOrderID,
          });
        } else {
          alert(res.data.message);
        }
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div
      style={{
        background: "#eaeaea",
        borderRadius: "4px",
        padding: "1rem",
        marginBottom: "1rem",
      }}
    >
      <div key={file.name} style={{ display: "flex", alignItems: "center" }}>
        {file.type == "application/pdf" && (
          <InsertDriveFile sx={{ marginRight: "12px", color: "#F90000" }} />
        )}
        {file.type == "image/png" && (
          <InsertPhoto sx={{ marginRight: "12px", color: "#00b5a2" }} />
        )}

        {file.type == "image/jpeg" && (
          <InsertPhoto sx={{ marginRight: "12px", color: "#00b5a2" }} />
        )}

        <p style={{ margin: 0 }}>{file.name}</p>
      </div>
    </div>
  ));

  const handleUpload = () => {
    upload_consignment(uploadPDF).then((json) => {
      if (json.status) {
        alert("Sumitted Successful");
        window.location.reload();
      }
    });
  };

  const handleUploadBulk = () => {
    bulk_order(bulkOrder).then((json) => {
      if (json.status) {
        alert("Sumitted Successful");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const [choosePayment, setChoosePayment] = React.useState("Razer Pay");

  const handleChoosePayment = (e) => {
    setChoosePayment(e.target.value);
  };

  const [bankSlip, setBankSlip] = React.useState("");

  const handleChangeUpload2 = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);

    var res = await get_upload_file(formData);
    if (res) {
      setBankSlip({
        ...bankSlip,
        bankslip: res.data.data,
        sales_order_id: salesOrderID,
      });
    }
  };

  const handleDeposit = (sales_order_id) => {
    setLoading(true);
    if (choosePayment == "Razer Pay") {
      process_order({ sales_order_id: salesOrderID }).then((json) => {
        if (json.status) {
          if (json.razer == "") {
            return;
          } else {
            window.location.href = json.data;
            navigate(json.data);
          }
          setLoading(false);
        } else {
          alert(json.message);
        }
      });
    } else {
      upload_bankslip(bankSlip).then((json) => {
        if (json.status) {
          alert("Payment Successful");
          setLoading(false);
          window.location.reload();
        }
      });
    }
  };

  const [chooseSaleOrderID, setChooseSaleOrderID] = React.useState([]);

  const [selectedData, setSelectedData] = React.useState([]);

  const [selectedSaleOrderIDs, setSelectedSaleOrderIDs] = React.useState([]);
  const [countSelectedAmount, setCountSelectedAmount] = React.useState(0);

  // const handleChooseSaleOrderID = (e) => {
  //   const selectedDataItem = JSON.parse(e.target.value);
  //   const isChecked = e.target.checked;

  //   let newSelectedData = [];
  //   let newSelectedSaleOrderIDs = [];
  //   if (isChecked) {
  //     newSelectedData = [...chooseSaleOrderID, selectedDataItem];
  //     newSelectedSaleOrderIDs = [
  //       ...selectedSaleOrderIDs,
  //       selectedDataItem.invoice_id,
  //     ];
  //   } else {
  //     newSelectedData = chooseSaleOrderID.filter(
  //       (item) => item.invoice_id !== selectedDataItem.invoice_id
  //     );
  //     newSelectedSaleOrderIDs = selectedSaleOrderIDs.filter(
  //       (id) => id !== selectedDataItem.invoice_id
  //     );
  //   }
  //   setChooseSaleOrderID(newSelectedData);
  //   setSelectedSaleOrderIDs(newSelectedSaleOrderIDs);

  //   // console.log(newSelectedSaleOrderIDs)

  //   const total = newSelectedData.reduce(
  //     (acc, item) => parseFloat(acc) + parseFloat(item.remainingamt),
  //     0
  //   );

  //   const formattedTotal = total.toLocaleString(undefined, {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });

  //   setCountSelectedAmount(formattedTotal);

  //   // console.log(newSelectedData);
  //   // console.log(newSelectedSaleOrderIDs);
  // };

  // Define a state variable for the "Select All" checkbox
  const [selectAll, setSelectAll] = React.useState(false);

  // Function to toggle the state of the "Select All" checkbox
  const handleCheckAll = () => {
    setSelectAll(!selectAll);

    // If the "Select All" checkbox is checked, select all individual checkboxes
    if (!selectAll) {
      setChooseSaleOrderID([...invoiceList]);
      setSelectedSaleOrderIDs(invoiceList.map((item) => item.invoice_id));
    } else {
      // If the "Select All" checkbox is unchecked, clear the selections
      setChooseSaleOrderID([]);
      setSelectedSaleOrderIDs([]);
    }

    const total = invoiceList.reduce(
      (acc, item) => parseFloat(acc) + parseFloat(item.remainingamt),
      0
    );

    const formattedTotal = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setCountSelectedAmount(formattedTotal);
  };

  // Function to handle individual checkbox clicks
  const handleChooseSaleOrderID = (e) => {
    const selectedDataItem = JSON.parse(e.target.value);
    const isChecked = e.target.checked;

    let newSelectedData = [];
    let newSelectedSaleOrderIDs = [];

    if (isChecked) {
      newSelectedData = [...chooseSaleOrderID, selectedDataItem];
      newSelectedSaleOrderIDs = [
        ...selectedSaleOrderIDs,
        selectedDataItem.invoice_id,
      ];
    } else {
      newSelectedData = chooseSaleOrderID.filter(
        (item) => item.invoice_id !== selectedDataItem.invoice_id
      );
      newSelectedSaleOrderIDs = selectedSaleOrderIDs.filter(
        (id) => id !== selectedDataItem.invoice_id
      );
    }

    setChooseSaleOrderID(newSelectedData);
    setSelectedSaleOrderIDs(newSelectedSaleOrderIDs);
    // calculateTotal()
    const total = newSelectedData.reduce(
      (acc, item) => parseFloat(acc) + parseFloat(item.remainingamt),
      0
    );

    const formattedTotal = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setCountSelectedAmount(formattedTotal);

    // console.log(newSelectedData);
    // console.log(newSelectedSaleOrderIDs);
  };

  // Function to calculate the total amount
  const calculateTotal = () => {
    const total = chooseSaleOrderID.reduce(
      (acc, item) => parseFloat(acc) + parseFloat(item.remainingamt),
      0
    );

    const formattedTotal = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setCountSelectedAmount(formattedTotal);
  };
  // const handleChooseSaleOrderID = (e) => {
  //   // const { value, checked } = e.target;
  //   // const { sales_order_id } = chooseSaleOrderID;

  //   // if (checked) {
  //   //   setChooseSaleOrderID({
  //   //     sales_order_id: [...sales_order_id, value],
  //   //   });
  //   // } else {
  //   //   setChooseSaleOrderID({
  //   //     sales_order_id: sales_order_id.filter((e) => e !== value),
  //   //   });
  //   // }

  //   var arr = [...chooseSaleOrderID];
  //   if (e.target.checked) {
  //     arr = [...chooseSaleOrderID, e.target.value];

  //     console.log(arr)

  //   } else {
  //     arr.splice(chooseSaleOrderID.indexOf(e.target.value), 1);
  //   }
  //   setChooseSaleOrderID(arr);

  // };

  // console.log(chooseSaleOrderID.sales_order_id);

  const handleInvoicePayment = () => {
    setLoading(true);
    if (chooseSaleOrderID == "") {
      alert("Please choose an order to do payment");
      setLoading(false);
      return;
    }
    invoice_payment({
      invoice_id: JSON.stringify(selectedSaleOrderIDs),
    }).then((json) => {
      if (json.status) {
        window.location.href = json.data;
        navigate(json.data);
      }
    });
  };

  // QR
  const [openModalQR, setOpenModalQR] = React.useState(false);

  const handleOpenModalQR = () => {
    setOpenModalQR(true);
  };

  //JESPA

  const [paynowCode, setPaynowCode] = React.useState("");

  const handleInvoicePaymentJespa = () => {
    if (chooseSaleOrderID === "") {
      alert("Please choose an order to do payment");
      setLoading(false);
      return;
    }

    // setOpenModalQR(true);

    invoice_payment({
      invoice_id: JSON.stringify(selectedSaleOrderIDs),
    }).then((json) => {
      setPaynowCode(json.data);
      setOpenModalQR(true);
      // set up interval to check invoice payment every 10 seconds
      const intervalId = setInterval(() => {
        check_invoice_payment({
          invoice_payment_id: json.invoice_payment_id,
        }).then((res) => {
          if (res.uobid == null) {
            // continue checking
            // console.log("Payment is still pending");
            setOpenModalQR(true);
            // setPaynowCode(json.data);

            invoice_payment({
              invoice_id: JSON.stringify(selectedSaleOrderIDs),
            }).then((json) => {
              setPaynowCode(json.data);
            });
          } else {
            // payment is completed, navigate to "/orderhistory"
            clearInterval(intervalId); // stop the interval
            setOpenModalQR(false); // close the modal if it's still open
            navigate("/orderhistory");
          }
        });
      }, 10000); // 10 seconds interval
    });
  };

  const [returnData, setReturnData] = React.useState([]);

  const getReturnData = () => {
    get_customer_return_by_token({ token: token }).then((json) => {
      setReturnData(json.data);
    });
  };

  React.useEffect(() => {
    getReturnData();
  }, []);

  // ITEM DATA QUANTITY

  // const handleChangeQuantityItem = (e, index) => {
  //   // const newValue = Number(e);
  //   // if (!isNaN(newValue)) {
  //   //   const temp_data = [...itemData];
  //   //   temp_data[index].quantity = newValue;
  //   //   setItemData(temp_data);
  //   // }
  //   var temp_data = [...itemData];
  //   var newQty = parseInt(e.target.value);
  //   if (isNaN(newQty)) {
  //     temp_data[index].return_quantity = "";
  //   } else {
  //     temp_data[index].return_quantity = newQty;
  //   }
  //   setItemData(temp_data);
  // };

  const handleChangeQuantityItem = (e, index) => {
    var temp_data = [...itemData];
    var newQty = parseInt(e.target.value);

    // Check if the input is a valid number and doesn't start with 0
    if (!isNaN(newQty) && String(newQty).charAt(0) !== "0") {
      temp_data[index].return_quantity = newQty;
    } else {
      temp_data[index].return_quantity = "";
    }

    setItemData(temp_data);
  };

  // CHECK ORDER
  const [chooseSaleOrderItem, setChooseSaleOrderItem] = React.useState([]);

  // const handleChooseSaleOrderItem = (e, item) => {
  //   if (e.target.checked) {
  //     setChooseSaleOrderItem([...chooseSaleOrderItem, item]);
  //   } else {
  //     setChooseSaleOrderItem(chooseSaleOrderItem.filter((e) => e !== item));
  //   }
  // };

  // RETURN ORDER ITEM

  const handleReturn = () => {
    if (chooseReason == "") {
      alert("Please Choose a Reason");
      return;
    }

    if (desp.description.length == "") {
      alert("All Field Required");
      return;
    }

    if (chooseSaleOrderItem.length == 0) {
      alert("Please Select an Item");
      return;
    }

    if (uploadReturn == "") {
      alert("Please Upload a Image");
      return;
    }

    let postparam = {
      item: JSON.stringify(chooseSaleOrderItem),
      ...desp,
      ...uploadReturn,
      sales_order_id: salesOrderID.sales_order_id,
      reason: chooseReason,
    };

    // console.log(postparam);

    return_order(postparam).then((json) => {
      if (json.status) {
        alert(
          "Thank you for your support, our admin will contact you within 24 business hour for further detail"
        );
        setOpenModal3(false);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const [loadSync, setLoadSync] = React.useState(false);

  const handleSyncInvoice = () => {
    setLoadSync(true);
    sync_invoice_list({ token: token }).then((json) => {
      if (json.status) {
        // window.location.reload();
        setLoadSync(false);
      }
    });
  };

  // PROGRESS BAR

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 25,
    borderRadius: 15,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
      border: "1px solid",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 0,
      backgroundColor: theme.palette.mode === "light" ? "#125396" : "#308fe8",
      // border: "1px solid"
    },
  }));

  const ProgressWithLabel = (props) => {
    const { value, ...rest } = props;
    return (
      <Box display="flex" alignItems="center">
        <Box minWidth={70}>
          <Paragraph bold="700"> RM 5000</Paragraph>
        </Box>
        <Box width="100%" mr={1} ml={1}>
          <BorderLinearProgress variant="determinate" value={value} {...rest} />
          <Paragraph
            bold="700"
            style={{
              position: "absolute",
              top: "65%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {`${Math.round(value)}%`}
          </Paragraph>
        </Box>
        <Box minWidth={80}>
          <Paragraph bold="700">RM 10000</Paragraph>
        </Box>
      </Box>
      // <Box display="flex" alignItems="center">
      //   <Box width="100%" mr={1}>
      //     <BorderLinearProgress variant="determinate" value={value} {...rest} />
      //     <Paragraph
      //       bold="700"
      //       style={{
      //         position: "absolute",
      //         top: "50%",
      //         left: "50%",
      //         transform: "translate(-50%, -50%)",
      //       }}
      //     >
      //       {`${Math.round(value)}%`}
      //     </Paragraph>
      //   </Box>
      // </Box>
    );
  };

  // console.log(JSON.parse(rebateData))

  return (
    <React.Fragment>
      <Navbar />
      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#125396"} size={50} />
        </div>
      ) : (
        <>
          <div style={{ background: "#E0F0F7", padding: "2rem 0 0 0" }}>
            <Container>
              <Paragraph
                color="#000"
                size="22px"
                bold="600"
                margin="0 0 1rem 0"
              >
                My Account
              </Paragraph>
              <div className={classes.theOverScroll}>
                <a
                  // className={tab == 0 ? classes.theLActive : classes.theL}
                  // onClick={() => handleChangeTab(0)}
                  className={classes.theL}
                  href="/account"
                >
                  <Paragraph
                    color="#000"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    My Profile
                  </Paragraph>
                </a>
                <a className={classes.theL} href="/balance">
                  <Paragraph
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    My Balance
                  </Paragraph>
                </a>
                <a
                  className={classes.theLActive}
                  //   onClick={() => handleChangeTab(2)}
                  href="/orderhistory"
                >
                  <Paragraph
                    color="#fff"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    My Order
                  </Paragraph>
                </a>
                <a
                  // className={tab == 3 ? classes.theLActive : classes.theL}
                  // onClick={() => handleChangeTab(3)}
                  className={classes.theL}
                  href="/address"
                >
                  <Paragraph
                    color="#000"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    Address Book
                  </Paragraph>
                </a>
                <a className={classes.theL} href="/statementaccount">
                  <Paragraph
                    color="#000"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    Statement of Account
                  </Paragraph>
                </a>
              </div>
            </Container>
          </div>
          <Container>
            <Section pd="2rem 0">
              <div>
                <div className={classes.theBox}>
                  <Grid container spacing={3}>
                    {rebateData.map((item) => (
                      <>
                        {item.display == true ? (
                          <Grid item xs={12} sm={12} md={6}>
                            <>
                              <Box
                                sx={{
                                  padding: "1rem",
                                  borderRadius: "10px",
                                  background: "#2a2866",
                                  boxShadow: " 7px 7px 9px 1px rgba(0,0,0,.2)",
                                }}
                              >
                                <Box sx={{ flexGrow: 1, position: "relative" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "flex-start",
                                      justifyContent: "space-between",
                                      marginBottom: ".4rem",
                                    }}
                                  >
                                    <Paragraph
                                      bold="700"
                                      size="29px"
                                      color={item.color}
                                      style={{ textShadow: "2px 2px #000" }}
                                    >
                                      {item.month} | {item.current_tier_name}
                                    </Paragraph>
                                    <Box sx={{ width: "15%" }}>
                                      <img
                                        style={{ width: "100%" }}
                                        src={item.image}
                                      />
                                    </Box>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      position: "absolute",
                                      top: "60%",
                                      left: "45%",
                                      transform: "translate(-50%, -50%)",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Paragraph color="#fff" bold="700">
                                      {item.amount}
                                    </Paragraph>
                                    {parseFloat(
                                      item.maxvalue - item.amount
                                    ).toFixed(2) == "0.00" ? (
                                      <></>
                                    ) : (
                                      <Tooltip
                                        title={
                                          <p
                                            style={{
                                              fontSize: "14px",
                                              margin: 0,
                                              padding: "0 .4rem",
                                            }}
                                          >
                                            Spend{" "}
                                            {parseFloat(
                                              item.maxvalue - item.amount
                                            ).toFixed(2)}{" "}
                                            more to achieve next tier with{" "}
                                            {item.next_tier_percent}
                                          </p>
                                        }
                                        placement="right"
                                      >
                                        {/* <ErrorRounded
                                          sx={{
                                            color: "#F92000",
                                            marginLeft: "1rem",
                                            cursor: "pointer"
                                          }}
                                        /> */}
                                        <Box
                                          sx={{
                                            width: "8%",
                                            color: "#F92000",
                                            marginLeft: "1rem",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={info}
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                            }}
                                          />
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </Box>
                                  <Box display="flex" alignItems="center">
                                    <Box>
                                      <Paragraph
                                        color="#fff"
                                        bold="700"
                                        margin="0 1rem 0 0"
                                        center
                                      >
                                        {" "}
                                        {item.minvalue}
                                      </Paragraph>
                                    </Box>

                                    <Box
                                      sx={{
                                        position: "relative",
                                        width: "100%",
                                      }}
                                    >
                                      <Box sx={{ position: "relative" }}>
                                        <BorderLinearProgress
                                          variant="determinate"
                                          value={item.progress_bar_value}
                                        />
                                      </Box>
                                    </Box>
                                    <Box>
                                      <Paragraph
                                        color="#fff"
                                        bold="700"
                                        margin="0 0 0 1rem"
                                        center
                                      >
                                        {item.maxvalue}
                                      </Paragraph>
                                    </Box>
                                  </Box>
                                </Box>
                                <br />

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                  }}
                                >
                                  <Paragraph color="#fff" bold="700" center>
                                    Total Spent: RM {item.amount}{" "}
                                  </Paragraph>
                                  <Box
                                    sx={{
                                      borderRight: "2px solid #fff",
                                      height: "21px",
                                    }}
                                  />
                                  <Paragraph color="#fff" bold="700" center>
                                    Rebate Earned: RM{" "}
                                    {parseFloat(item.rebate).toFixed(2)}
                                  </Paragraph>
                                </Box>
                              </Box>
                            </>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </>
                    ))}
                  </Grid>

                  <br />
                  <br />
                  <Box
                    sx={{
                      margin: "0 auto",
                    }}
                  >
                    <Paragraph
                      size="20px"
                      bold="600"
                      color="#000"
                      margin="0 0 1rem 0"
                    >
                      Upload Bulk Order
                      <span style={{ color: "#F92000", marginLeft: "4px" }}>
                        *
                      </span>
                    </Paragraph>

                    <div className={classes.theFlexBulk}>
                      <FormInputNoLabel
                        label="Upload Bulk Order"
                        border="1px solid #eaeaea"
                        placeholder="Upload Bulk Order"
                        type="file"
                        onChange={handleChangeUploadBulk}
                        sx={{
                          width: { xs: "100%", md: "70%" },
                        }}
                        className="theCustomm"
                      />
                      <div className={classes.theBulkBtn}>
                        <Button
                          label="UPLOAD BULK ORDER"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          // br="6px"
                          style={{ fontSize: "1rem" }}
                          hover
                          onClick={() => handleUploadBulk()}
                        />
                      </div>
                    </div>
                  </Box>
                  <br />
                  <br />
                  <hr />
                  <Box
                    sx={{
                      display: "flex",
                      margin: "1rem 0",
                      alignItems: "center",
                    }}
                  >
                    <Paragraph
                      size="20px"
                      bold="600"
                      color="#000"
                      margin=" 0 1rem 0 0"
                    >
                      My Order List
                    </Paragraph>
                    {loadSync ? (
                      <Loader
                        type="snipper-default"
                        bgColor={"#878787"}
                        size={30}
                      />
                    ) : (
                      <a
                        style={{
                          color: "#fff",
                          padding: "0.4rem 1rem",
                          display: "block",
                          background: "#125396",
                          display: "flex",
                          alignItems: "center",
                        }}
                        onClick={() => handleSyncInvoice()}
                      >
                        <Refresh sx={{ color: "#FFF", marginRight: ".5rem" }} />
                        Sync Order List
                      </a>
                    )}
                  </Box>

                  <br />

                  {tab == 0 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>

                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>

                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Outstanding Amount
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.sales_order_id
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                return (
                                  <>
                                    {item.status_id == 1 && (
                                      <TableRow>
                                        <a
                                          style={{ display: "contents" }}
                                          href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>
                                            {item.created_date}
                                          </TableCell>
                                          <TableCell>
                                            {item.sales_order_id}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.outstanding_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency === "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency === "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                        </a>
                                        {profileData.dropship == 1 && (
                                          <TableCell>
                                            <Button
                                              label="UPLOAD CONSIGNMENT NOTE"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem 1rem"
                                              hover
                                              onClick={() =>
                                                handleOpenModal2(
                                                  item.sales_order_id
                                                )
                                              }
                                            />
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 1 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Outstanding Amount
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.sales_order_id
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                return (
                                  <>
                                    {item.status_id == 2 && (
                                      <TableRow>
                                        <a
                                          style={{ display: "contents" }}
                                          href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>
                                            {item.created_date}
                                          </TableCell>
                                          <TableCell>
                                            {item.sales_order_id}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.outstanding_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency === "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency === "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                        </a>

                                        {item.deposit_amount > 0 &&
                                        item.is_deposit_paid == 0 ? (
                                          <TableCell>
                                            <Button
                                              label="DEPOSIT"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem 1rem"
                                              hover
                                              onClick={() =>
                                                handleOpenModal4(
                                                  item.sales_order_id
                                                )
                                              }
                                            />
                                          </TableCell>
                                        ) : (
                                          <TableCell>
                                            {/* {profileData.pricelevel ==
                                              "Jespa" &&
                                              profileData.paymenttype ==
                                                "Cash" && (
                                                <>
                                                  <Button
                                                    label="PAY NOW"
                                                    color="#fff"
                                                    bg="#125396"
                                                    bold="500"
                                                    // br="6px"
                                                    size="12px"
                                                    pd=".4rem 1rem"
                                                    hover
                                                    href={`../orderhistorydetail/${item.sales_order_id}`}
                                                  />
                                                </>
                                              )}

                                            {profileData.pricelevel !=
                                              "Jespa" &&
                                              profileData.paymenttype ==
                                                "Cash" && (
                                                <Button
                                                  label="PAY NOW"
                                                  color="#fff"
                                                  bg="#125396"
                                                  bold="500"
                                                  // br="6px"
                                                  size="12px"
                                                  pd=".4rem 1rem"
                                                  hover
                                                  onClick={() =>
                                                    handlePayNow(
                                                      item.sales_order_id
                                                    )
                                                  }
                                                />
                                              )} */}

                                            <>
                                              {checkCurrency == "SGD" &&
                                              paymentType == "Credit" ? (
                                                <>
                                                  <Button
                                                    label="PAY NOW"
                                                    color="#fff"
                                                    bg="#125396"
                                                    bold="500"
                                                    hover
                                                    // br="6px"
                                                    size="12px"
                                                    pd=".4rem 1rem"
                                                    onClick={() =>
                                                      handlePayNowSG(
                                                        item.sales_order_id
                                                      )
                                                    }
                                                  />
                                                </>
                                              ) : (
                                                <Button
                                                  label="PAY NOW"
                                                  color="#fff"
                                                  bg="#125396"
                                                  bold="500"
                                                  // br="6px"
                                                  size="12px"
                                                  pd=".4rem 1rem"
                                                  hover
                                                  onClick={() =>
                                                    handlePayNow(
                                                      item.sales_order_id
                                                    )
                                                  }
                                                />
                                              )}
                                            </>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 2 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Outstanding Amount
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.sales_order_id
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                return (
                                  <>
                                    {item.status_id == 3 && (
                                      <TableRow>
                                        <a
                                          style={{ display: "contents" }}
                                          href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>
                                            {item.created_date}
                                          </TableCell>
                                          <TableCell>
                                            {item.sales_order_id}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.outstanding_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency === "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency === "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                        </a>
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 3 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Outstanding Amount
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                              {/* <TableCell></TableCell> */}
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.sales_order_id
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                return (
                                  <>
                                    {item.status_id == 4 && (
                                      <TableRow>
                                        <a
                                          style={{ display: "contents" }}
                                          href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>
                                            {item.created_date}
                                          </TableCell>
                                          <TableCell>
                                            {item.sales_order_id}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.outstanding_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency === "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency === "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                        </a>
                                        {/* {item.outstanding_amount > 0 && (
                                        <TableCell>
                                          <Button
                                            label="PROCEED"
                                            color="#fff"
                                            bg="#125396"
                                            bold="500"
                                            // br="6px"
                                            size="12px"
                                            pd=".4rem 1rem"
                                            hover
                                            onClick={() =>
                                              handlePayNow(item.sales_order_id)
                                            }
                                          />
                                        </TableCell>
                                      )} */}
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 4 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {/* <TableCell></TableCell> */}
                              <TableCell>Invoice Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              {/* <TableCell>Remaining Amount</TableCell> */}
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                              <TableCell>Due Date</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.sales_order_id
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                const daysDifference = moment().diff(
                                  moment(item.created_date),
                                  "days"
                                );

                                return (
                                  <>
                                    {item.status_id == 5 && (
                                      <TableRow>
                                        {/* <TableCell>
        {item.remainingamt != 0 && (
          <input
            type="checkbox"
            value={item.sales_order_id}
            name="sales_order_id"
            // onClick={() =>
            //   handleChooseSaleOrderID(
            //     item.sales_order_id
            //   )
            // }
            onChange={handleChooseSaleOrderID}
          />
        )}
      </TableCell> */}
                                        <a
                                          style={{ display: "contents" }}
                                          href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>{item.trandate}</TableCell>
                                          <TableCell>
                                            {item.sales_order_id}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {/* {checkCurrency == "MYR" && (
          <TableCell>
            MYR {item.remainingamt}
          </TableCell>
        )}
        {checkCurrency == "SGD" && (
          <TableCell>
            SGD {item.remainingamt}
          </TableCell>
        )}
        {checkCurrency == "USD" && (
          <TableCell>
            USD {item.remainingamt}
          </TableCell>
        )} */}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          <TableCell>{item.duedate}</TableCell>
                                        </a>
                                        {item.file == "" ? (
                                          <></>
                                        ) : (
                                          <TableCell>
                                            <Button
                                              label="INVOICE"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem .8rem"
                                              hover
                                              onClick={() =>
                                                handleDownload(item)
                                              }
                                            />
                                          </TableCell>
                                        )}
                                        {item.dolink == "" ? (
                                          <></>
                                        ) : (
                                          <TableCell>
                                            <Button
                                              label="DELIVER ORDER"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem .8rem"
                                              hover
                                              onClick={() =>
                                                handleDownloadDO(item)
                                              }
                                            />
                                          </TableCell>
                                        )}
                                        {daysDifference < 14 && (
                                          <TableCell>
                                            <Button
                                              label="RETURN"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              hover
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem .8rem"
                                              // onClick={() =>
                                              //   handleOpenModal3(
                                              //     item.sales_order_id
                                              //   )
                                              // }
                                              onClick={() =>
                                                handleOpenModal3(item)
                                              }
                                            />
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 5 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Outstanding Amount
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.sales_order_id
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                return (
                                  <>
                                    {item.status_id == 6 && (
                                      <TableRow>
                                        <a
                                          style={{ display: "contents" }}
                                          href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>
                                            {item.created_date}
                                          </TableCell>
                                          <TableCell>
                                            {item.sales_order_id}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.outstanding_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency === "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency === "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                        </a>
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 6 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      {/* <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Order Date</TableCell>
                              <TableCell>Order ID</TableCell>
                              <TableCell sx={{textAlign:"right"}}>Total Amout</TableCell>

                             <TableCell sx={{textAlign:"center"}}>Order Status</TableCell>
                             <TableCell sx={{textAlign:"right"}}>Outstanding Amount</TableCell>
                             <TableCell sx={{textAlign:"right"}}>Deposit Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {oderList.map((item) => (
                                <>
                                  {item.status_id == 7 && (
                                    <TableRow>
                                      <a
                                        style={{ display: "contents" }}
                                        href={`../orderhistorydetail/${item.sales_order_id}`}
                                      >
                                        <TableCell>
                                          {item.created_date}
                                        </TableCell>
                                        <TableCell>
                                          {item.sales_order_id}
                                        </TableCell>

                                        {checkCurrency == "MYR" && (
                                          <TableCell>
                                            MYR {item.final_amount}
                                          </TableCell>
                                        )}
                                        {checkCurrency == "SGD" && (
                                          <TableCell>
                                            SGD {item.final_amount}
                                          </TableCell>
                                        )}
                                        {checkCurrency == "USD" && (
                                          <TableCell>
                                            USD {item.final_amount}
                                          </TableCell>
                                        )}

                                       <TableCell sx={{textAlign:"center"}}>{item.status}</TableCell>

                                        {checkCurrency == "MYR" && (
                                          <TableCell>
                                            MYR {item.outstanding_amount}
                                          </TableCell>
                                        )}
                                        {checkCurrency == "SGD" && (
                                          <TableCell>
                                            SGD {item.outstanding_amount}
                                          </TableCell>
                                        )}
                                        {checkCurrency == "USD" && (
                                          <TableCell>
                                            USD {item.outstanding_amount}
                                          </TableCell>
                                        )}

                                        {checkCurrency == "MYR" && (
                                          <TableCell
                                            className={
                                              item.is_deposit_paid == 0
                                                ? classes.theRed
                                                : classes.theDefault
                                            }
                                          >
                                            MYR {item.deposit_amount}
                                          </TableCell>
                                        )}
                                        {checkCurrency == "SGD" && (
                                          <TableCell
                                            className={
                                              item.is_deposit_paid == 0
                                                ? classes.theRed
                                                : classes.theDefault
                                            }
                                          >
                                            SGD {item.deposit_amount}
                                          </TableCell>
                                        )}
                                        {checkCurrency == "USD" && (
                                          <TableCell
                                            className={
                                              item.is_deposit_paid == 0
                                                ? classes.theRed
                                                : classes.theDefault
                                            }
                                          >
                                            USD {item.deposit_amount}
                                          </TableCell>
                                        )}
                                      </a>
                                    </TableRow>
                                  )}
                                </>
                              ))}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer> */}

                      <div style={{ borderBottom: "1px solid #eaeaea" }}></div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>

                      <br />
                      <div className={classes.theSection}>
                        <Grid container spacing={3}>
                          {returnData.map((item) => {
                            if (searchKey != "") {
                              if (
                                item.sales_order_id
                                  .toUpperCase()
                                  .indexOf(searchKey.toUpperCase()) <= -1
                              ) {
                                return;
                              }
                            }
                            return (
                              <Grid item xs={12} sm={12} md={6}>
                                <div
                                  style={{
                                    boxShadow: "unset",
                                    borderRadius: "0",
                                    padding: "1rem",
                                    border: "1px solid #eaeaea",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      marginBottom: ".4rem",
                                    }}
                                  >
                                    <Paragraph
                                      bold="600"
                                      color="#265EA1"
                                      size="20px"
                                    >
                                      Return Order ID :{" "}
                                      {item.customer_goods_return_id}
                                    </Paragraph>
                                    <Paragraph
                                      color="#F90000"
                                      bold="600"
                                      size="20px"
                                      style={{ fontStyle: "italic" }}
                                    >
                                      {item.returnreason}
                                    </Paragraph>
                                  </div>

                                  <Paragraph
                                    bold="600"
                                    color="#265EA1"
                                    size="20px"
                                    margin="0 0 .4rem 0"
                                  >
                                    Sale Order ID : {item.sales_order_id}
                                  </Paragraph>
                                  <Paragraph margin="0 0 .4rem 0">
                                    Other Reason: {item.otherreason}
                                  </Paragraph>
                                  <Paragraph margin="0 0 .4rem 0">
                                    Memo: {item.otherreason}
                                  </Paragraph>
                                  {item.file != "" && (
                                    <Paragraph margin="0 0 .4rem 0">
                                      File:{" "}
                                      <a
                                        style={{ color: "#125396" }}
                                        href={item.file}
                                      >
                                        {item.file}
                                      </a>
                                    </Paragraph>
                                  )}

                                  <Paragraph margin="0 0 .4rem 0">
                                    Full Return:{" "}
                                    {item.fullreturn == 0 ? <>NO</> : <>YES</>}
                                  </Paragraph>
                                  <Paragraph margin="0 0 .4rem 0">
                                    Stock Return:{" "}
                                    {item.stockreturn == 0 ? <>NO</> : <>YES</>}
                                  </Paragraph>
                                  <Paragraph margin="0 0 .4rem 0">
                                    Customer Return:{" "}
                                    {item.customerreturn == 0 ? (
                                      <>NO</>
                                    ) : (
                                      <>YES</>
                                    )}
                                  </Paragraph>
                                  <Paragraph margin="0 0 .4rem 0">
                                    Status: {item.status}
                                  </Paragraph>
                                  <br />
                                  <div style={{ display: "flex" }}>
                                    {item.rmalink != "" && (
                                      <Button
                                        label="Download RMA"
                                        bg="#265EA1"
                                        color="#fff"
                                        onClick={() =>
                                          window.open(item.rmalink)
                                        }
                                        style={{ marginRight: "14px" }}
                                        pd=".4rem 1rem"
                                      />
                                    )}
                                    {item.cnlink != "" && (
                                      <Button
                                        label="Download CN"
                                        bg="#265EA1"
                                        color="#fff"
                                        onClick={() => window.open(item.cnlink)}
                                        style={{ marginRight: "14px" }}
                                        pd=".4rem 1rem"
                                      />
                                    )}
                                  </div>
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={
                                        <ExpandMore
                                          sx={{
                                            margin: "0 10px",
                                            color: "#265EA1",
                                          }}
                                        />
                                      }
                                      aria-controls="panel1a-content"
                                      // id={index}
                                      sx={{ justifyContent: "unset" }}
                                      style={
                                        {
                                          // border: "1px solid #eaeaea",
                                          // padding: "1rem",
                                        }
                                      }
                                    >
                                      <div style={{ display: "flex" }}>
                                        <RemoveRedEye
                                          sx={{
                                            marginRight: "10px",
                                            color: "#265EA1",
                                          }}
                                        />
                                        <Paragraph> View Item List</Paragraph>
                                      </div>
                                    </AccordionSummary>
                                    <AccordionDetails
                                      style={{
                                        border: "1px solid #eaeaea",
                                      }}
                                    >
                                      <Table>
                                        <TableHead className={classes.theHead}>
                                          <TableRow>
                                            <TableCell>Item Name</TableCell>
                                            <TableCell>Quantity</TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {item.item.map((x) => (
                                            <TableRow>
                                              <TableCell>
                                                {x.itemname}
                                              </TableCell>
                                              <TableCell>
                                                {x.quantity}
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </div>
                    </>
                  )}

                  {tab == 7 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>SO</TableCell>
                              <TableCell>Amount</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Credit Memo</TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {creditNote.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.ecommso
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                return (
                                  <>
                                    <TableRow>
                                      <TableCell>{item.date}</TableCell>
                                      <TableCell>{item.ecommso}</TableCell>
                                      <TableCell>{item.amount}</TableCell>
                                      <TableCell sx={{ textAlign: "center" }}>
                                        {item.status}
                                      </TableCell>

                                      <TableCell>
                                        <a
                                          style={{
                                            color: "#125396",
                                            alignItems: "center",
                                            display: "flex",
                                          }}
                                          href={item.cmlink}
                                          target="_blank"
                                        >
                                          <Note sx={{ marginRight: "12px" }} />{" "}
                                          <span>View Now</span>
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      {loadMore ? (
                        <Loader
                          type="snipper-default"
                          bgColor={"#878787"}
                          size={30}
                        />
                      ) : (
                        <Button
                          label="LOAD MORE"
                          color="#fff"
                          bg="#125396"
                          bold="600"
                          onClick={getOderListtory}
                        />
                      )}
                    </>
                  )}

                  {tab == 8 && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div className={classes.theOverScroll}>
                          <a
                            className={
                              tab == 0 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(0)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Reviewing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 1 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(1)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Customer Confirmation
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 2 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(2)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Processing
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 3 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(3)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Confirmed
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 4 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(4)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Shipped
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 8 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(8)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Unpaid
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 5 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(5)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Cancelled
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 6 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(6)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Return Order
                            </Paragraph>
                          </a>
                          <a
                            className={
                              tab == 7 ? classes.theLActive : classes.theL
                            }
                            onClick={() => handleChangeTab(7)}
                          >
                            <Paragraph
                              style={{
                                padding: ".4rem 2rem",
                                width: "max-content",
                              }}
                            >
                              Credit Note
                            </Paragraph>
                          </a>
                        </div>
                      </div>
                      <br />
                      <div className={classes.theSizeSearch}>
                        <FormInputNoLabel
                          placeholder="Search Order ID"
                          border="1px solid #d7d7d7"
                          br="4px"
                          onChange={handleSearch}
                          value={searchKey}
                          className="theNomargin"
                        />
                      </div>
                      <br />

                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <input
                                  type="checkbox"
                                  onClick={handleCheckAll}
                                  style={{ width: "20px", height: "20px" }}
                                />
                              </TableCell>
                              <TableCell>Invoice Date</TableCell>
                              <TableCell>Invoice ID</TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Total Amout
                              </TableCell>
                              {/* <TableCell>Shipping Fee</TableCell> */}
                              <TableCell sx={{ textAlign: "center" }}>
                                Order Status
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Remaining Amount
                              </TableCell>
                              <TableCell sx={{ textAlign: "right" }}>
                                Deposit Amount
                              </TableCell>
                              <TableCell>Due Date</TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {loading == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "600px",
                              }}
                            >
                              <Loader
                                type="snipper-default"
                                bgColor={"#878787"}
                                size={50}
                              />
                            </div>
                          ) : (
                            <TableBody>
                              {invoiceList.map((item) => {
                                if (searchKey != "") {
                                  if (
                                    item.invnumber
                                      .toUpperCase()
                                      .indexOf(searchKey.toUpperCase()) <= -1
                                  ) {
                                    return;
                                  }
                                }
                                const daysDifference = moment().diff(
                                  moment(item.created_date),
                                  "days"
                                );

                                return (
                                  <>
                                    {item.remainingamt != 0 && (
                                      <TableRow>
                                        <TableCell>
                                          {item.remainingamt != 0 && (
                                            <input
                                              type="checkbox"
                                              value={JSON.stringify(item)}
                                              // checked={selectAll}
                                              // value={item.sales_order_id}
                                              name="invoice_id"
                                              // onClick={() =>
                                              //   handleChooseSaleOrderID(
                                              //     item.sales_order_id
                                              //   )
                                              // }
                                              style={{
                                                width: "20px",
                                                height: "20px",
                                              }}
                                              checked={chooseSaleOrderID.some(
                                                (selectedItem) =>
                                                  selectedItem.invoice_id ===
                                                  item.invoice_id
                                              )}
                                              onChange={handleChooseSaleOrderID}
                                              // checked={selectAll}
                                              // Add an 'onChange' handler to toggle selectAll
                                              // onChange={handleCheckAll}
                                            />
                                          )}
                                        </TableCell>
                                        <a
                                          style={{ display: "contents" }}
                                          // href={`../orderhistorydetail/${item.sales_order_id}`}
                                        >
                                          <TableCell>{item.trandate}</TableCell>
                                          <TableCell>
                                            {item.invnumber}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR{" "}
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD
                                              {item.final_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}

                                          <TableCell
                                            sx={{ textAlign: "center" }}
                                          >
                                            {item.status}
                                          </TableCell>

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              MYR {item.remainingamt}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              SGD {item.remainingamt}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                            >
                                              USD {item.remainingamt}
                                            </TableCell>
                                          )}

                                          {checkCurrency == "MYR" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              MYR{" "}
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "SGD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              SGD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          {checkCurrency == "USD" && (
                                            <TableCell
                                              sx={{ textAlign: "right" }}
                                              className={
                                                item.is_deposit_paid == 0
                                                  ? classes.theRed
                                                  : classes.theDefault
                                              }
                                            >
                                              USD
                                              {item.deposit_amount.toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )}
                                            </TableCell>
                                          )}
                                          <TableCell>{item.duedate}</TableCell>
                                        </a>
                                        {item.file == "" ? (
                                          <></>
                                        ) : (
                                          <TableCell>
                                            <Button
                                              label="INVOICE"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem 1rem"
                                              hover
                                              onClick={() =>
                                                handleDownload(item)
                                              }
                                            />
                                          </TableCell>
                                        )}
                                        {item.dolink == "" ? (
                                          <></>
                                        ) : (
                                          <TableCell>
                                            <Button
                                              label="DELIVER ORDER"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem 1rem"
                                              hover
                                              onClick={() =>
                                                handleDownloadDO(item)
                                              }
                                            />
                                          </TableCell>
                                        )}
                                        {daysDifference < 14 && (
                                          <TableCell>
                                            <Button
                                              label="RETURN"
                                              color="#fff"
                                              bg="#125396"
                                              bold="500"
                                              hover
                                              // br="6px"
                                              size="12px"
                                              pd=".4rem 1rem"
                                              // onClick={() =>
                                              //   handleOpenModal3(
                                              //     item.sales_order_id
                                              //   )
                                              // }
                                              onClick={() =>
                                                handleOpenModal3(item)
                                              }
                                            />
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    )}
                                  </>
                                );
                              })}
                            </TableBody>
                          )}
                        </Table>
                      </TableContainer>
                      <br />
                      <br />
                      <Box
                        sx={{
                          display: { xs: "block", md: "flex" },
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {checkCurrency == "MYR" && (
                          <Paragraph bold="700" margin="0 2rem 0 0">
                            SELECTED INVOICE AMOUNT: MYR {countSelectedAmount}
                          </Paragraph>
                        )}

                        {checkCurrency == "SGD" && (
                          <Paragraph bold="700" margin="0 2rem 0 0">
                            SELECTED INVOICE AMOUNT: SGD {countSelectedAmount}
                          </Paragraph>
                        )}

                        {checkCurrency == "USD" && (
                          <Paragraph bold="700" margin="0 2rem 0 0">
                            SELECTED INVOICE AMOUNT: USD {countSelectedAmount}
                          </Paragraph>
                        )}

                        {tab == 8 && (
                          <Box
                            sx={{
                              width: { xs: "100%", md: "50%" },
                              margin: { xs: "1rem 0 0 0", md: "0 0 0 4rem" },
                            }}
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "100px",
                                  height: "35px",
                                }}
                              >
                                <Loader
                                  type="snipper-default"
                                  bgColor={"#878787"}
                                  size={30}
                                />
                              </div>
                            ) : (
                              <>
                                {checkCurrency == "SGD" &&
                                paymentType == "Credit" ? (
                                  <>
                                    <Button
                                      label="PAY VIA PAYNOW"
                                      color="#fff"
                                      bg="#125396"
                                      bold="500"
                                      hover
                                      size="12px"
                                      pd=".4rem 1rem"
                                      onClick={() =>
                                        handleInvoicePaymentJespa()
                                      }
                                    />
                                  </>
                                ) : (
                                  <Button
                                    label="PROCEED TO PAY"
                                    color="#fff"
                                    bg="#125396"
                                    bold="500"
                                    hover
                                    size="12px"
                                    pd=".4rem 1rem"
                                    onClick={() => handleInvoicePayment()}
                                  />
                                )}
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    </>
                  )}
                </div>
              </div>
            </Section>
          </Container>
        </>
      )}

      {/* <Footer /> */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#353535">
              Add New Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  border="1px solid #d7d7d7"
                  name="addr1"
                  onChange={handleChange}
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="addr2"
                  onChange={handleChange}
                />
                <FormInput
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                />
                <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                />
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                />
                <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  name="zip"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              br="6px"
              hover
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* EDIT ADDRESS */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenEditModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#353535">
              Edit Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                  value={addressBookDetail.name}
                /> */}
                <FormInput
                  label="Address Line 1"
                  placeholder="Address 1"
                  border="1px solid #d7d7d7"
                  name="address"
                  onChange={handleChange}
                  value={addressBookDetail.addr1}
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="address"
                  onChange={handleChange}
                  value={addressBookDetail.addr2}
                />
                <FormInput
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                  value={addressBookDetail.state}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                  value={addressBookDetail.country}
                />
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={addressBookDetail.phone}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                  value={addressBookDetail.city}
                />{" "}
                <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                  value={addressBookDetail.zip}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={checked}
                    />
                  }
                  label="Set As Default Address"
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              br="6px"
              hover
              onClick={() => handleEditAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/*  Upload Consignment */}
      <Dialog
        open={openModal2}
        onClose={() => setOpenModal2(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal2(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            {/* <Paragraph center size="24px" color="#353535" margin="0 0 1rem 0">
              Upload Consignment Note
            </Paragraph>

            <FormInput
              label="Upload Consignment Note"
              border="1px solid #eaeaea"
              placeholder="Upload Consignment Note"
              type="file"
              onChange={handleChangeUpload}
              accept=".jpg, .jpeg, pdf, .png"
              required
            /> */}
            <>
              <Paragraph
                bold="600"
                color="#125396"
                size="22px"
                margin="0 0 1rem 0"
              >
                Upload Consignment Note
              </Paragraph>
              <div
                {...getRootProps({ className: "dropzone" })}
                style={dropzone}
              >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <>
                {files == [] ? (
                  <div {...getRootProps({})}>
                    <input {...getInputProps()} />
                  </div>
                ) : (
                  <div {...getRootProps({})}>{thumbs}</div>
                )}
              </>
            </>
            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              bold="600"
              // br="6px"
              hover
              onClick={() => handleUpload()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/*  Return Order */}
      <Dialog
        open={openModal3}
        // onClose={() => setOpenModal3(false)}
        onClose={() => handleCloseModal3()}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a
                // onClick={() => setOpenModal3(false)}
                onClick={() => handleCloseModal3()}
              >
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" margin="0 0 1rem 0" color="#353535">
              Return Order
            </Paragraph>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Order ID</TableCell>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Sales Quantity</TableCell>
                    <TableCell sx={{ textAlign: "center" }}>
                      Return Quantity
                    </TableCell>
                    <TableCell>Returned Quantity</TableCell>
                    <TableCell sx={{ textAlign: "right" }}>
                      Total Amout
                    </TableCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemData.map((item, index) => (
                    <>
                      {item.is_display == "1" && (
                        <TableRow>
                          <TableCell>
                            <>
                              {item.quantity == item.returned_quantity ? (
                                <></>
                              ) : (
                                <>
                                  {item.remainingamt != 0 && (
                                    <input
                                      type="checkbox"
                                      value={item}
                                      // onChange={(e) => handleChooseSaleOrderItem(e, index, item)}
                                      // onChange={(e) => {
                                      //   const isChecked = e.target.checked;
                                      //   if (isChecked) {
                                      //     setChooseSaleOrderItem([
                                      //       ...chooseSaleOrderItem,
                                      //       item,
                                      //     ]);
                                      //   } else {
                                      //     setChooseSaleOrderItem(
                                      //       chooseSaleOrderItem.filter(
                                      //         (value) => value !== item
                                      //       )
                                      //     );
                                      //   }
                                      // }}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
                                        if (isChecked) {
                                          if (!item.return_quantity) {
                                            alert(
                                              "Please enter a value for return quantity"
                                            );
                                            e.target.checked = false;
                                          } else {
                                            setChooseSaleOrderItem([
                                              ...chooseSaleOrderItem,
                                              item,
                                            ]);
                                          }
                                        } else {
                                          setChooseSaleOrderItem(
                                            chooseSaleOrderItem.filter(
                                              (value) => value !== item
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          </TableCell>

                          <TableCell>{item.sales_order_item_id}</TableCell>
                          <TableCell>{item.displayname}</TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {item.quantity}
                          </TableCell>

                          <TableCell>
                            <input
                              style={{
                                border:
                                  item.return_quantity > item.remain_quantity
                                    ? "1px solid #F90000"
                                    : "1px solid #eaeaea",
                                padding: "0.475rem 1rem",
                                textAlign: "center",
                              }}
                              disabled={
                                item.quantity == item.returned_quantity
                                  ? true
                                  : false
                              }
                              // type="number"
                              onChange={(e) =>
                                handleChangeQuantityItem(e, index)
                              }
                              value={item.return_quantity}
                            />
                          </TableCell>

                          <TableCell sx={{ textAlign: "center" }}>
                            {item.returned_quantity}
                          </TableCell>
                          {checkCurrency == "MYR" && (
                            <TableCell>MYR {item.final_amount}</TableCell>
                          )}
                          {checkCurrency == "SGD" && (
                            <TableCell>SGD {item.final_amount}</TableCell>
                          )}
                          {checkCurrency == "USD" && (
                            <TableCell>USD {item.final_amount}</TableCell>
                          )}
                        </TableRow>
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            {/* <Paragraph margin="0 0 .4rem 0">
              Choose a reason{" "}
              <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
            </Paragraph>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={chooseReason}
              name="radio-buttons-group"
              sx={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {reasonList.map((item) => (
                <FormControlLabel
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                  onChange={() => handleChooseReason(item.name)}
                />
              ))}
            </RadioGroup> */}
            <Paragraph margin="1rem 0">
              Choose Reason{" "}
              <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
            </Paragraph>
            <FormInputSelect>
              <Select
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                name="name"
                value={chooseReason}
                onChange={handleChooseReason}
                style={{ marginBottom: "1rem" }}
              >
                <MenuItem value="" style={{ fontWeight: "400" }}>
                  <em style={{ color: "#878787" }}>Please Choose a Reason</em>
                </MenuItem>
                {reasonList.map((item) => (
                  <MenuItem value={item.name} style={{ color: "#000" }}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormInputSelect>
            <FormInput
              label="Description"
              border="1px solid #eaeaea"
              placeholder="Description"
              multiline
              rows="3"
              onChange={handleChange}
              name="description"
              required
            />

            <Paragraph color="#000" margin="1rem 0">
              Upload Images
              <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
            </Paragraph>
            <input
              style={{
                color: "#000",
                border: "1px solid #eaeaea",
                borderRadius: 4,
                padding: ".4rem 1rem",
                width: "-webkit-fill-available",
                lineHeight: "1.4375em",
                fontSize: "1rem",
              }}
              type="file"
              name="image"
              onChange={handleChangeUploadReturn}
              multiple
            />
            <br />
            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              bold="600"
              hover
              // br="6px"
              onClick={() => handleReturn()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/*  Deposit */}
      <Dialog
        open={openModal4}
        onClose={() => setOpenModal4(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal4(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" margin="0 0 1rem 0" color="#353535">
              Choose Deposit Payment
            </Paragraph>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={choosePayment}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Razer Pay"
                control={<Radio />}
                label="Razer Pay"
                onChange={handleChoosePayment}
              />
              <FormControlLabel
                value="Bank In"
                control={<Radio />}
                label="Bank In"
                onChange={handleChoosePayment}
              />
            </RadioGroup>
            {choosePayment == "Bank In" && (
              <FormInput
                label="Upload Bank Slip"
                border="1px solid #eaeaea"
                placeholder="Upload Bank Slip"
                type="file"
                onChange={handleChangeUpload2}
                name="bankslip"
              />
            )}

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              bold="600"
              hover
              // br="6px"
              onClick={() => handleDeposit()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/*  Return Detail */}
      <Dialog
        open={openModal5}
        onClose={() => setOpenModal5(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal5(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph
              center
              size="24px"
              margin="0 0 1rem 0"
              color="#353535"
            ></Paragraph>
            {returnData.map((item, index) => (
              <Grid item xs={12} sm={12} md={6}>
                <div
                  style={{
                    position: "relative",
                    padding: "1rem",
                    width: "100%",
                  }}
                >
                  <Paragraph
                    color="#265EA1"
                    bold="700"
                    margin="0 0 .4rem 0"
                    size="20px"
                  >
                    Sale Order ID: {item.sales_order_id}
                  </Paragraph>
                  <Paragraph
                    color="#F90000"
                    bold="600"
                    margin="0 0 .4rem 0"
                    style={{ fontStyle: "italic" }}
                  >
                    {item.returnreason}
                  </Paragraph>
                  <Paragraph margin="0 0 .4rem 0">
                    Other Reason: {item.otherreason}
                  </Paragraph>
                  <Paragraph margin="0 0 .4rem 0">
                    Memo: {item.otherreason}
                  </Paragraph>
                  {item.file != "" && (
                    <Paragraph margin="0 0 .4rem 0">
                      File:{" "}
                      <a style={{ color: "#125396" }} href={item.file}>
                        {item.file}
                      </a>
                    </Paragraph>
                  )}

                  <Paragraph margin="0 0 .4rem 0">
                    Full Return: {item.fullreturn == 0 ? <>NO</> : <>YES</>}
                  </Paragraph>
                  <Paragraph margin="0 0 .4rem 0">
                    Stock Return: {item.stockreturn == 0 ? <>NO</> : <>YES</>}
                  </Paragraph>
                  <Paragraph margin="0 0 .4rem 0">
                    Customer Return:{" "}
                    {item.customerreturn == 0 ? <>NO</> : <>YES</>}
                  </Paragraph>
                  <Paragraph margin="0 0 .4rem 0">
                    Status: {item.status}
                  </Paragraph>
                  {item.rmalink != "" && (
                    <Paragraph margin="0 0 .4rem 0">
                      RMA Link:{" "}
                      <a onClick={() => window.opne(item.rmalink)}>
                        Download Now
                      </a>
                    </Paragraph>
                  )}
                  {item.cnlink != "" && (
                    <Paragraph margin="0 0 .4rem 0">
                      CN Link:{" "}
                      <a onClick={() => window.opne(item.cnlink)}>
                        {item.cnlink}
                      </a>
                    </Paragraph>
                  )}
                </div>

                <Table>
                  <TableHead className={classes.theHead}>
                    <TableRow>
                      <TableCell>Item Name</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.item.map((x) => (
                      <TableRow>
                        <TableCell>{x.itemname}</TableCell>
                        <TableCell>{x.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <br />
              </Grid>
            ))}
          </div>
          <br />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openModalQR}
        onClose={() => setOpenModalQR(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent sx={{ background: "#2a2866" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <a onClick={() => handleCloseQR()}>
              <Close style={{ color: "#fff" }} />
            </a>
          </div>
          <div
            style={{
              padding: "0 2rem 1rem 2rem",
              // display: "flex",
              // justifyContent: "space-between",
              // alignItems: "center",
              background: "#2a2866",
            }}
          >
            <Paragraph
              bold="700"
              size="22px"
              color="#fff"
              center
              margin="0 0 1rem 0"
            >
              Pay via PayNow
            </Paragraph>
            <div style={{ textAlign: "center" }}>
              {/* <img src={paynowCode}/> */}
              {/* <img
                style={{ width: "100%" }}
                src={
                  "https://chart.apis.google.com/chart?cht=qr&chs=450x450&chl=" +
                  paynowCode
                }
              ></img> */}
              <img
                style={{ width: "100%" }}
                src={
                  "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" +
                  paynowCode
                }
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
