import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import Paragraph from "../../components/Typography/Paragraph";

import { Container, Grid, Box } from "@mui/material";

import Button from "../../components/Button/Button";

export default function Catalogue() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="2rem 0">
          <Paragraph
            color="#2a2866"
            bold="700"
            size="24px"
            margin="0 0 2rem 0"
            center
          >
            OUR CATALOGUE
          </Paragraph>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <Paragraph
                  color="#2a2866"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  GILDAN CATALOGUE
                </Paragraph>
                <a
                  href="https://app.box.com/s/58c3c5z844tn09fff0uq6anr1bmz3j87/file/1258592634437"
                  target="_blank"
                >
                  <Box sx={{ border: "1px solid #CCC" }}>
                    <img
                      style={{ width: "100%", display: "flex" }}
                      src="../../../../assets/images/cat.png"
                    />
                  </Box>
                  <Paragraph color="#125396" margin=".4rem 0 0 0">
                    Collection of Year 2023 -2025
                  </Paragraph>
                </a>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={4}>
                <Paragraph
                  color="#2a2866"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  LATEST COLLECTION
                </Paragraph>
                <a
                  href="https://app.box.com/s/58c3c5z844tn09fff0uq6anr1bmz3j87/file/1578928931434"
                  target="_blank"
                >
                  <Box sx={{ border: "1px solid #CCC" }}>
                    <img
                      style={{ width: "100%", display: "flex" }}
                      src="../../../../assets/images/cat2.png"
                    />
                  </Box>
                  <Paragraph color="#125396" margin=".4rem 0 0 0">
                    The Latest Collection 2024 - 2025
                  </Paragraph>
                </a>
              </Grid> */}

              <Grid item xs={12} sm={12} md={4}>
                <Paragraph
                  color="#2a2866"
                  bold="700"
                  size="24px"
                  margin="0 0 1rem 0"
                >
                  LATEST COLLECTION
                </Paragraph>
                <a
                  href="https://megahtex.com/thelatest-nov.pdf"
                  target="_blank"
                >
                  <Box sx={{ border: "1px solid #CCC" }}>
                    <img
                      style={{ width: "100%", display: "flex" }}
                      src="../../../../assets/images/cat2.png"
                    />
                  </Box>
                  <Paragraph color="#125396" margin=".4rem 0 0 0">
                    The Latest Collection Nov 2024 - 2025
                  </Paragraph>
                </a>
              </Grid>
            </Grid>
          </Box>
        </Section>
      </Container>

      <Footer />
    </React.Fragment>
  );
}
