import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  AccountCircleRounded,
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Remove,
} from "@mui/icons-material";

import headercontactus from "../../assets/images/contact.jpg";
import { contact_us, get_website_banner } from "../../API/API";

export default function Contact() {
  const classes = useStyles();

  const [contactData, setContactData] = React.useState({
    name: "",
    companyname: "",
    contact: "",
    email: "",
    message: "",
  });

  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      contactData.name.length == "" ||
      contactData.companyname.length == "" ||
      contactData.contact.length == "" ||
      contactData.email.length == "" ||
      contactData.message.length == ""
    ) {
      alert("All Field Required");
      return;
    }
    contact_us(contactData).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const [banner, setBanner] = React.useState([]);

  const getBanner = () => {
    get_website_banner().then((json) => {
      setBanner(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      {/* <Box className={classes.theBGG}>
       <div className={classes.theOverlay}>
          <div className={classes.backgroundBlur}></div>
          <Paragraph
            color="#FFF"
            size="50px"
            margin="0 0 .4rem 0"
            bold="600"
            center
            style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px", borderBottom: "1px solid #FFF" }}
          >
            CONTACT US
          </Paragraph>
         
          <Paragraph
            color="#FFF"
            size="20px"
            bold="600"
            margin=".5rem 0 0 0"
            center
            style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
          >
            Let’s get in touch & explore business opportunities
          </Paragraph>
        </div>
       </Box> */}
      {/* <div className={classes.theBanner}>
        <img src={headercontactus} />
        <div className={classes.theOverlay}>
          <div className={classes.backgroundBlur}></div>
          <Paragraph
            color="#FFF"
            size="50px"
            margin="0 0 .4rem 0"
            bold="600"
            center
            style={{
              textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px",
              borderBottom: "1px solid #FFF",
            }}
          >
            CONTACT US
          </Paragraph>

          <Paragraph
            color="#FFF"
            size="20px"
            bold="600"
            margin=".5rem 0 0 0"
            center
            style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
          >
            Let’s get in touch & explore business opportunities
          </Paragraph>
        </div>
      </div> */}

      {banner
        .filter((item) => item.page === "Contact")
        .sort((a, b) => a.weight - b.weight)
        .map((item) => (
          <div className={classes.theBanner} key={item.banner_id}>
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt={`Banner ${item.banner_id}`}
            />
            <div className={classes.theOverlay}>
              <div className={classes.backgroundBlur}></div>
              <Paragraph
                color="#FFF"
                size="50px"
                margin="0 0 .4rem 0"
                bold="600"
                center
                style={{
                  textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px",
                  borderBottom: "1px solid #FFF",
                }}
              >
                {item.title}
              </Paragraph>

              <Paragraph
                color="#FFF"
                size="20px"
                bold="600"
                margin=".5rem 0 0 0"
                center
                style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
              >
                {item.subtitle}
              </Paragraph>
            </div>
          </div>
        ))}

      <div style={{ background: "#FFF" }}>
        <Container>
          <Section pd="2rem 0">
            <Paragraph
              size="22px"
              color="#2a2866"
              bold="600"
              margin="0 0 .4rem 0"
              center
            >
              LET'S CONNECT
            </Paragraph>
            <Paragraph color="#878787" size="14px" margin="0 0 1rem 0" center>
              We're always happy to hear from you! Simply fill out the column
              below with your contact information and we'll be in touch shortly.
              Let us know how we can assist you.
            </Paragraph>
            <br />
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInputNoLabel
                    placeholder="Name *"
                    name="name"
                    bg="#D7D7D7"
                    br="0px"
                    onChange={handleChange}
                    style={{ marginBottom: "1rem" }}
                  />
                  <FormInputNoLabel
                    placeholder="Contact Number *"
                    name="contact"
                    bg="#D7D7D7"
                    br="0px"
                    onChange={handleChange}
                    style={{ marginBottom: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInputNoLabel
                    placeholder="Company Name *"
                    name="companyname"
                    bg="#D7D7D7"
                    br="0px"
                    onChange={handleChange}
                    style={{ marginBottom: "1rem" }}
                  />
                  <FormInputNoLabel
                    placeholder="Email Address *"
                    name="email"
                    bg="#D7D7D7"
                    br="0px"
                    onChange={handleChange}
                    style={{ marginBottom: "0" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormInputNoLabel
                    placeholder="Message *"
                    name="message"
                    bg="#D7D7D7"
                    br="0px"
                    rows="3"
                    multiline
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <br />
              <div style={{ width: "30%", float: "right" }}>
                <Button
                  label="SUBMIT"
                  bg="#2a2866"
                  // box="1px solid #2a2866"
                  color="#FFF"
                  // pd=".4rem 1rem"
                  hover
                  size="12px"
                  onClick={() => handleSubmit()}
                />
              </div>
            </div>
          </Section>
        </Container>
        <br />
      </div>
      {/* <Container>
        <Section pd="2rem 0">
          <Paragraph
            size="22px"
            color="#2a2866"
            bold="600"
            margin="0 0 .4rem 0"
            center
          >
            FIND US ON GOOGLE MAP
          </Paragraph>
          <Paragraph color="#000" size="12px" margin="0 0 1rem 0" center>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            a erat eget lorem efficitur finibus vel vitae magna. Vestibulum quis
            massa metus.
          </Paragraph>
          <div className={classes.theIMG}>
            <img src="" />
          </div>
        </Section>
      </Container> */}

      <br />
      <br />
      <Footer />
    </React.Fragment>
  );
}
