import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import "./styles.css";
import { Container, Grid, Box, Dialog, DialogContent } from "@mui/material";
import Button from "../../components/Button/Button";
import { AccountCircleRounded, Close } from "@mui/icons-material";

import Loader from "react-js-loader";
import {
  contact_us,
  get_parent_item,
  get_website_banner,
  get_decorative,
  get_best_seller,
} from "../../API/API";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const classes = useStyles();
  const navigate = useNavigate();

  const settingsHome = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const settings2 = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const settings3 = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  //
  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
  }, []);

  const [contactData, setContactData] = React.useState({
    name: "",
    companyname: "",
    contact: "",
    email: "",
    message: "",
  });

  const [openSub, setOpenSub] = React.useState(false);
  const [subData, setSubData] = React.useState({
    name: "",
    email: "",
  });

  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value,
    });
    setSubData({
      ...subData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      contactData.name.length == "" ||
      contactData.companyname.length == "" ||
      contactData.contact.length == "" ||
      contactData.email.length == "" ||
      contactData.message.length == ""
    ) {
      alert("All Field Required");
      return;
    }
    contact_us(contactData).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const [loading, setLoading] = React.useState(false);
  const [banner, setBanner] = React.useState([]);
  const [product, setProduct] = React.useState([]);
  const [decorative, setDecorative] = React.useState([]);

  const [prodSld, setProdSld] = React.useState([]);

  const getBanner = () => {
    get_website_banner().then((json) => {
      setBanner(json.data);
    });
  };

  const getProduct = () => {
    setLoading(true);
    get_best_seller({}).then((json) => {
      setProduct(json.data);
      setProdSld(json.data.products);
      setLoading(false);
    });
  };

  const getDecorative = () => {
    get_decorative({ foo: "asd" }).then((json) => {
      setDecorative(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
    getProduct();
    getDecorative();
  }, []);

  const handleNext = (item_id) => {
    if (token != undefined || token != "" || token != null) {
      navigate("../shopdetail/" + item_id);
    } else {
      navigate("/login");
    }
  };

  const [bestSellerList, setBestSellerList] = React.useState([]);

  const getBestSellerList = () => {
    get_best_seller().then((json) => {
      setBestSellerList(json.data);
    });
  };

  React.useState(() => {
    getBestSellerList();
  }, []);

  React.useEffect(() => {
    const hasOpenedBefore = localStorage.getItem("hasOpenedSubscribe");
    if (!hasOpenedBefore) {
      setOpenSub(true);
      localStorage.setItem("hasOpenedSubscribe", true);
    }
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <div className={classes.theBanner}>
        <Slider {...settingsHome}>
          {/* {banner
            .sort((a, b) => a.weight - b.weight)
            .map((item) => (
              <div key={item.banner_id}>
                <img
                  style={{ width: "100%" }}
                  src={item.image}
                  alt={`Banner ${item.banner_id}`}
                />
              </div>
            ))} */}
          {banner
            .filter((item) => item.page === "Home")
            .sort((a, b) => a.weight - b.weight)
            .map((item) => (
              <div key={item.banner_id}>
                <img
                  style={{ width: "100%" }}
                  src={item.image}
                  alt={`Banner ${item.banner_id}`}
                />
              </div>
            ))}
        </Slider>
      </div>
      <Container maxWidth="xl">
        <Section pd="2rem 0">
          <Paragraph
            size="22px"
            color="#2a2866 "
            bold="600"
            margin="0 0 1rem 0"
            center
          >
            OUR SERVICE
          </Paragraph>
          <Grid container spacing={3}>
            {decorative
              .sort((a, b) => a.decorative_id - b.decorative_id)
              .map((r) => (
                <Grid item xs={6} sm={3} md={2}>
                  <a style={{ position: "relative" }} href="/decorative">
                    <div
                      style={{
                        position: "relative",
                        // height: "250px",
                        // background: "#d7d7d7",
                      }}
                    >
                      <img
                        style={{
                          width: "100%",
                        }}
                        src={r.image}
                      />
                      <Paragraph color="#000" size="14px" center>
                        {r.name}
                      </Paragraph>
                    </div>
                  </a>
                </Grid>
              ))}
          </Grid>
        </Section>
      </Container>
        <Box sx={{padding: "3rem 0"}}>
          <Paragraph
            color="#2a2866 "
            margin="0 0 .4rem 0"
            size="22px"
            bold="600"
            center
          >
            OUR BEST SELLER
          </Paragraph>
          <Paragraph size="14px" color="#000" margin="0 0 1rem 0" center>
            Whether you're looking for a comfortable tee or the premium work
            wear, you'll find everything you need to enhance your look here.
            <br />
            Discover the best selling and trending products that our customers
            are obsessed with and get your hands on our most popular pieces.
          </Paragraph>

          {loading == true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "600px",
              }}
            >
              <Loader type="snipper-default" bgColor={"#878787"} size={50} />
            </div>
          ) : (
            <>
              {product.map((item) => (
                <>
                  <Paragraph
                    color="#2a2866 "
                    margin="2rem 0"
                    size="22px"
                    bold="600"
                    center
                    style={{ textTransform: "uppercase" }}
                  >
                    {item.title}
                  </Paragraph>

                  <Slider
                    className={classes.root2}
                    {...settings2}
                    // key={prod.product_id}
                  >
                    {item.products.map((prod) => (
                      <a>
                        <Box
                          style={{
                            background: "#fff",
                            padding: " .4rem",
                            borderRadius: 0,
                            // height: "400px",
                            marginBottom: "15px",
                            // justifyContent: "center",
                            // alignItems: "center",
                            // display: "flex",
                            // flexDirection: "column",
                            border: "1px solid #DDD",
                          }}
                        >
                          <div className={classes.theGallery}>
                            <img
                              src={prod.image}
                              alt={`Product ${prod.product_id}`}
                            />
                          </div>
                          <div style={{ marginTop: "1rem" }}>
                            <Paragraph
                              className="_theWording2"
                              size="14px"
                              color="#333335"
                              bold="600"
                              margin="0 0 .2rem 0"
                            >
                              {prod.item_name}
                            </Paragraph>
                            <Paragraph
                              size="14px"
                              color="#333335"
                              bold="600"
                              margin="0 0 .2rem 0"
                            >
                              {prod.code}
                            </Paragraph>
                            <Paragraph
                              color="#878787"
                              size="14px"
                              bold="400"
                              margin="0 0 .2rem 0"
                            >
                              {prod.brand}
                            </Paragraph>
                            {/* <Paragraph
                              color="#333335"
                              size="14px"
                              bold="400"
                              margin="0 0 .2rem 0"
                            >
                              {prod.color}
                            </Paragraph> */}
                          </div>
                        </Box>
                      </a>
                    ))}
                  </Slider>
                </>
              ))}
            </>
          )}
        </Box>

      <Container>
        <Section pd="2rem 0">
          <Paragraph
            size="22px"
            color="#2a2866 "
            bold="600"
            margin="0 0 1rem 0"
            center
          >
            CUSTOM MADE
          </Paragraph>
          <img
            src="../assets/images/custommadev5.png"
            style={{
              width: "80%",
              margin: "0 auto",
              textAlign: "center",
              display: "block",
            }}
          />
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{}}>
                <Paragraph color="#878787" size="14px" center>
                  Choose Color & Material
                </Paragraph>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{}}>
                <Paragraph color="#878787" size="14px" center>
                  Select Apparel Pattern
                </Paragraph>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{}}>
                <Paragraph color="#878787" size="14px" center>
                  Determine Decoration on Apparel
                </Paragraph>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <div style={{}}>
                <Paragraph color="#878787" size="14px" center>
                  Leave your Contact Information
                </Paragraph>
              </div>
            </Grid>
          </Grid> */}
          <br />
          <br />
          <div className={classes.theBSize}>
            <Button
              label="GET STARTED"
              bg="#fff"
              box="1px solid #2a2866 "
              color="#2a2866 "
              bold="600"
              // pd=".4rem 1rem"
              size="14px"
              href="/custommade"
            />
          </div>
        </Section>
      </Container>
      <div style={{ background: "#CBCEE8" }}>
        <Container>
          <Section pd="2rem 0">
            <Paragraph
              size="22px"
              color="#2a2866 "
              bold="600"
              margin="0 0 .4rem 0"
              center
            >
              WHAT OUR CLIENT SAYS
            </Paragraph>
            <Paragraph color="#2a2866 " size="12px" margin="0 0 1rem 0" center>
              Feedback is an essential part of our commitment to excellence at
              Megah Textile. By incorporating real comments from our clients and
              agents, we're able to continually improve our services to meet
              their needs. We appreciate all feedback we receive and encourage
              you to read our testimonials to see what sets us apart.
            </Paragraph>
            <br />
            <Slider className={classes.root2} {...settings3}>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    1st class service , very friendly and approachable, Sales
                    person have the natural ability to understand & feel which
                    meet our needs effectively, you have a real instinct to
                    understand customer needs. Good work
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Chin Bee
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    For the past 10 years, I have been fully satisfied with Ms
                    Gina service. She have been very helpful and friendly,
                    always trying your best to solve any problems that arise.
                    Keep up the good work!"
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Ms Tan & Suimin - 96 pte ltd
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    " I highly recommend Megah Textile for their exceptional
                    service. Ms Sarifah offer quick and effective solutions to
                    any issue, ensuring complete customer satisfaction."
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Michelle - ASK creative
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "It has been my pleasure working with Ms LiYin all along as
                    she is very hardworking and friendly. She delivers
                    outstanding customer service with remarkable efficiency,
                    rapid communication, and diligent follow-ups throughout the
                    entire process. Aside from her unwavering responsibility,
                    impressive problem-solving skills, and tireless work ethic,
                    she provides her customers with a hassle-free, awesome
                    shopping experience that sets her apart"
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Mr Chen - Brew Up Creative
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "The journey was over 5 years with Megah Textile. Overall we
                    are satisfied with the product & services."
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Tuan Bahar Nordin - More Dream
                    </Paragraph>
                  </div>
                </Card>
              </a>

              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    It's been a pleasure working with Megah Textile! The team've
                    been very accommodating to all of our requests, from
                    arranging for us to see fabric swatches to providing test
                    print samples for us to check the colors. The delivery times
                    have been consistent, which is something we really
                    appreciate. If there are any issues with the clothing,
                    you're always willing to replace or resolve the problem. We
                    give you 5 stars!
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Mr Jeat - Everytees
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "Very good service from Gina, 5 stars. Very helpful and
                    trusted."
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Mr Sunny - Data Stitch
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "By time being I’m truly impressed with the system &
                    excellent services especially Ms Sarifah. Keep up the
                    momentum!"
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Nik Nasruddin - Ainix Resources
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "I'm extremely pleased with the service provided by Megah
                    Textile. The shipping process was smooth and efficient, and
                    I'm happy to see it has improved even further. I highly
                    recommend their services."
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Suhaznie Kiai - ASK creative
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "Good professional, enthusiastic and patient service
                    attitude from Ms Liyin. Ordering process was easy, the
                    shipping was fast and the product was exactly what I wanted.
                    I highly recommend them to anyone looking for a hassle-free
                    shopping experience. Thank you for the exceptional service!"
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Ms Wong - Thomtex Trading
                    </Paragraph>
                  </div>
                </Card>
              </a>
              <a>
                <Card
                  style={{
                    background: "#fff",
                    padding: "1rem",
                    borderRadius: 0,
                    height: "220px",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "column",
                  }}
                >
                  <Paragraph size="12px" margin="1rem 0" center>
                    "I am completely satisfied. Deliveries are always on time
                    and often arrive earlier than expected! Excellent service
                    from Ms Gina"
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <AccountCircleRounded sx={{ color: "#565656" }} />
                    <Paragraph size="14px" center>
                      Tommy - Teeplus
                    </Paragraph>
                  </div>
                </Card>
              </a>
            </Slider>
          </Section>
        </Container>
      </div>
      <div
        style={{
          background: "#192e56 ",
        }}
      >
        <Container>
          <Section pd="2rem 0">
            <Paragraph
              size="22px"
              color="#fff"
              bold="600"
              margin="0 0 .4rem 0"
              center
            >
              GET IT TOUCH
            </Paragraph>
            <Paragraph color="#fff" size="12px" margin="0 0 1rem 0" center>
              We're always happy to hear from you! Simply fill out the column
              below with your contact information and we'll be in touch shortly.
              Let us know how we can assist you.
            </Paragraph>
            <br />
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInputNoLabel
                    placeholder="Name"
                    name="name"
                    bg="#eaeaea"
                    br="0px"
                    onChange={handleChange}
                  />
                  <FormInputNoLabel
                    placeholder="Contact Number"
                    name="contact"
                    bg="#eaeaea"
                    br="0px"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <FormInputNoLabel
                    placeholder="Company Name"
                    name="companyname"
                    bg="#eaeaea"
                    br="0px"
                    onChange={handleChange}
                  />
                  <FormInputNoLabel
                    placeholder="Email Address"
                    name="email"
                    bg="#eaeaea"
                    br="0px"
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
              <FormInputNoLabel
                placeholder="Message"
                name="message"
                bg="#eaeaea"
                br="0px"
                rows="3"
                multiline
                onChange={handleChange}
              />
              <br />
              <div style={{ width: "30%", float: "right" }}>
                <Button
                  label="SUBMIT"
                  bg="#fff"
                  box="1px solid #2a2866 "
                  color="#2a2866 "
                  // pd=".4rem 1rem"
                  size="14px"
                  bold="600"
                  onClick={() => handleSubmit()}
                />
              </div>
              <br />
              <br />
            </div>
          </Section>
        </Container>
      </div>

      <Footer />
      <Dialog
        open={openSub}
        onClose={() => setOpenSub(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenSub(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <Paragraph size="24px" bold="600" color="#2a2866">
              EXCLUSIVE ACCESS
            </Paragraph>
            <Paragraph margin="0  0 1.5rem 0">
              Updates on new arrivals, limited edition collaborations and more.
            </Paragraph>

            <FormInputNoLabel
              placeholder="Email *"
              name="email"
              border="1px solid #D7D7D7"
              br="0px"
              onChange={handleChange}
              style={{ marginBottom: "0" }}
            />
            <Paragraph colo="#eaeaea" size="13px" margin="0 0 1rem 0">
              * You can unsubcribe at any time throuh the unsubscribe link in
              each email.
            </Paragraph>

            <Button
              label="SUBSCRIBE"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              margin=".6rem 0 0 0"
              // onClick={() => handleAddAddress()}
            />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
