import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Remove,
} from "@mui/icons-material";

export default function CustomMadeDetail() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section pd="2rem 0">

        </Section>
      </Container>
    </React.Fragment>
  );
}
