import React from "react";
import Slider from "react-slick";
import useStyles, {
  IncrementAction,
  IncrementAction2,
  IncrementActionDisable,
  IncrementActionDisable2,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/FormInput/FormInput";
import silestone001 from "../../assets/images/silestone001.png";

import Paragraph from "../../components/Typography/Paragraph";
import Button from "../../components/Button/Button";
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";
import {
  get_customer_cart,
  get_customer_profile,
  validate_cart,
} from "../../API/API";
import { throttle } from "lodash";

export default function Cart() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [count, setCount] = React.useState(1);
  const [cart, setCart] = React.useState([]);

  const { getCart, removeProduct } = useCart();

  const getcart = JSON.stringify(getCart());
  const productCartList = getcart.replace(/\\/g, "");

  const [qtyCount, setQtyCount] = React.useState(0);
  const [stockAva, setStockAva] = React.useState([]);
  const [token, setToken] = React.useState([]);
  const [checkCountry, setCheckCountry] = React.useState("");
  const [customerContactID, setCustomerContactID] = React.useState("");
  const [checkCurrency, setCheckCurrency] = React.useState("");
  const [currentData, setCurrentData] = React.useState([]);
  const [totalAmount, setTotalAmount] = React.useState("");
  const [subTotal, setSubTotal] = React.useState();

  const [newCartist, setNewCartList] = React.useState([]);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  React.useEffect(() => {
    let storedCart = window.localStorage.getItem("cart");
    setNewCartList(JSON.parse(storedCart) || []);
  }, [window.localStorage.getItem("cart")]);

  function updateProductQuantity(index, increment) {
    let cartTemp = newCartist.length ? [...newCartist] : [];

    if (cartTemp[index]) {
      const quantity =
        parseFloat(cartTemp[index].quantity) + (increment ? 1 : -1);

      if (quantity > 0) {
        cartTemp[index].quantity = quantity;
      } else {
        cartTemp.splice(index, 1);
        cartTemp.forEach((item, i) => (item.index = i));
      }

      updateCart(cartTemp);
    }
  }

  const [isDisable, setIsDisable] = React.useState(false);

  function increaseProduct(index) {
    if (!isDisable) {
      updateProductQuantity(index, true);
      // setIsDisable(true);
      // setTimeout(() => {
      //   setIsDisable(false);
      // }, 2000);
    }
  }

  function decreaseProduct(index) {
    if (!isDisable) {
      updateProductQuantity(index, false);
      // setIsDisable(true);
      // setTimeout(() => {
      //   setIsDisable(false);
      // }, 2000);
    }
  }

  // const increaseProduct = throttle((index) => {
  //   updateProductQuantity(index, true);
  // }, 1000);

  // function increaseProduct(index) {
  //   updateProductQuantity(index, true);
  // }

  // function decreaseProduct(index) {
  //   updateProductQuantity(index, false);
  // }

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const handleCheckout = () => {
    if (!token) {
      navigate("/login");
    } else {
      if (newCartist.length === 0) {
        alert("Your Cart is Empty");
        return;
      }

      if (stockAva.length > 0) {
        alert("Please Check Stock Available");
        return;
      } else {
        navigate("/payment");
        // calculateCart();
      }
    }
  };

  const updateCart = async (cartTemp) => {
    const updatedItems = cartTemp.map(({ name, ...rest }) => rest);

    let postparam = {
      item: JSON.stringify(updatedItems),
      token: token,
    };

    try {
      setIsDisable(true);
      const res = await validate_cart(postparam);
      if (res.status) {
        setCurrentData(res.data);
        setTotalAmount(res.new_total_amount);
        setSubTotal(res.data[0]?.new_subtotal || 0);
        setStockAva([]);
      } else {
        alert(res.message);
        setStockAva(res.out_of_stock);
      }
    } catch (error) {
      console.error("Error updating cart:", error);
    } finally {
      setIsDisable(false);
    }
  };

  const getCustomerProfileAndCart = async () => {
    try {
      const customerProfile = await get_customer_profile({ token: token });
      setCheckCountry(customerProfile.data.subsidiary);
      setCustomerContactID(customerProfile.data.customer_contact_id);
      setCheckCurrency(customerProfile.data.currency);

      const cartData = await get_customer_cart({
        customer_contact_id: customerProfile.data.customer_contact_id,
        token: token,
      });

      setCurrentData(cartData.data);
      window.localStorage.setItem("cart", JSON.stringify(cartData.data));

      const validationRes = await validate_cart({
        item: JSON.stringify(cartData.data),
        token: token,
      });

      if (!validationRes.status) {
        alert(validationRes.message);
        setStockAva(validationRes.out_of_stock);
      }
    } catch (error) {
      console.error("Error fetching customer profile and cart:", error);
    }
  };

  const getTotalCart = () => {
    var total_amount = 0;

    if (newCartist.length) {
      var cartTemp = [...newCartist];
    } else {
      var cartTemp = [];
    }
    for (var i = 0; i < cartTemp.length; i++) {
      var arr = cartTemp[i].given_price;
      var arr_dis = cartTemp[i].given_discount;
      var q = cartTemp[i].quantity;

      var m = parseFloat(arr - arr_dis) * parseFloat(q);

      total_amount = parseFloat(total_amount) + parseFloat(m);
    }
    return total_amount;
  };

  React.useEffect(() => {
    getCustomerProfileAndCart();
  }, [totalAmount]);

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="4rem 0 2rem 0">
          <Paragraph
            size="22px"
            color="#125396"
            bold="600"
            // center
            margin="0 0 2rem 0"
          >
            Shopping Cart
          </Paragraph>

          <TableContainer>
            <Table>
              <TableHead className={classes.theHead}>
                <TableRow>
                  <TableCell sx={{ width: "50%" }}>Product</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Price</TableCell>
                  {checkCurrency == "MYR" && (
                    <TableCell sx={{ textAlign: "center" }}>
                      Discount Per Pcs
                    </TableCell>
                  )}
                  <TableCell sx={{ textAlign: "center" }}>Quatity</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>Total</TableCell>
                  <TableCell sx={{ textAlign: "center" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newCartist != undefined && newCartist.length > 0
                  ? newCartist.map((item, index) => (
                      <TableRow
                        key={item.internalid}
                        // sx={{
                        //   border: stockAva.includes(item.internalid)
                        //     ? "2px solid red"
                        //     : "unset",
                        // }}
                      >
                        <TableCell>
                          <div
                            style={{ display: "flex" }}
                            className={classes.theWidth}
                          >
                            <div className={classes.theIMGx}>
                              <img src={item.image} />
                              {item.no_stock}
                            </div>
                            <div style={{ marginLeft: "1rem" }}>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                bold="700"
                                size="14px"
                                className="_theWording"
                                color={
                                  stockAva.includes(item.internalid)
                                    ? "red"
                                    : "#000"
                                }
                              >
                                {item.displayname}
                              </Paragraph>
                              {/* <Paragraph
                                margin="0 0 .2rem 0"
                                bold="700"
                                size="14px"
                                className="_theWording"
                              >
                                {item.description}
                              </Paragraph> */}
                              <pre
                                style={{
                                  margin: 0,
                                  color: "#878787",
                                  fontFamily: "Roboto,Helvetica, Arial",
                                }}
                              >
                                {item.description != "" &&
                                item.description != null
                                  ? item.description
                                  : ""}
                              </pre>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="_theWording"
                              >
                                {item.brand}
                              </Paragraph>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="_theWording"
                              >
                                {item.size}
                              </Paragraph>
                              <Paragraph
                                margin="0 0 .2rem 0"
                                size="12px"
                                className="_theWording"
                              >
                                {item.color}
                              </Paragraph>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            {checkCurrency == "MYR" && (
                              <Paragraph center>
                                MYR {parseFloat(item.given_price).toFixed(2)}
                              </Paragraph>
                            )}
                            {checkCurrency == "SGD" && (
                              <Paragraph center>
                                SGD {parseFloat(item.given_price).toFixed(2)}
                              </Paragraph>
                            )}
                            {checkCurrency == "USD" && (
                              <Paragraph center>
                                USD {parseFloat(item.given_price).toFixed(2)}
                              </Paragraph>
                            )}
                          </div>
                        </TableCell>
                        {checkCurrency == "MYR" && (
                          <TableCell>
                            <div className={classes.theWidth2}>
                              <Paragraph center>
                                MYR {parseFloat(item.given_discount).toFixed(2)}
                              </Paragraph>
                            </div>
                          </TableCell>
                        )}
                        <TableCell>
                          <div className={classes.theWidth2}>
                            <div className={classes.theFlexQuantity}>
                              <div className={classes.theIncrement}>
                                {isDisable ? (
                                  <IncrementActionDisable2 margin="0 1rem 0 0">
                                    <Remove
                                      className={
                                        classes.theButtonStyle2Disable2
                                      }
                                    />
                                  </IncrementActionDisable2>
                                ) : (
                                  <IncrementAction2
                                    margin="0 1rem 0 0"
                                    onClick={() => {
                                      decreaseProduct(index);
                                      // setQtyCount(qtyCount - 1);
                                    }}
                                  >
                                    <Remove
                                      className={classes.theButtonStyle2}
                                    />
                                  </IncrementAction2>
                                )}

                                {count == 0 ? (
                                  <div className={classes.theBox}>
                                    <p className={classes.theCount}>0</p>
                                  </div>
                                ) : (
                                  <div className={classes.theBox}>
                                    <p
                                      className={classes.theCount}
                                      style={{
                                        color: stockAva.includes(
                                          item.internalid
                                        )
                                          ? "red"
                                          : "#000",
                                      }}
                                    >
                                      {item.quantity}
                                    </p>
                                  </div>
                                )}

                                {isDisable ? (
                                  <IncrementActionDisable margin="0 0 0 1rem">
                                    <Add className={classes.theButtonStyle} />
                                  </IncrementActionDisable>
                                ) : (
                                  <IncrementAction
                                    margin="0 0 0 1rem"
                                    onClick={() => {
                                      increaseProduct(index);
                                      // setQtyCount(qtyCount + 1);
                                    }}
                                  >
                                    <Add className={classes.theButtonStyle} />
                                  </IncrementAction>
                                )}
                                {/* <IncrementAction
                                  margin="0 0 0 1rem"
                                  onClick={() => {
                                    increaseProduct(index);
                                    setQtyCount(qtyCount + 1);
                                  }}
                                >
                                  <Add className={classes.theButtonStyle} />
                                </IncrementAction> */}
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className={classes.theWidth2}>
                            {checkCurrency == "MYR" && (
                              <Paragraph center>
                                MYR{" "}
                                {parseFloat(
                                  (item.given_price - item.given_discount) *
                                    item.quantity
                                ).toFixed(2)}
                              </Paragraph>
                            )}
                            {checkCurrency == "SGD" && (
                              <Paragraph center>
                                SGD{" "}
                                {parseFloat(
                                  (item.given_price - item.given_discount) *
                                    item.quantity
                                ).toFixed(2)}
                              </Paragraph>
                            )}
                            {checkCurrency == "USD" && (
                              <Paragraph center>
                                USD{" "}
                                {parseFloat(
                                  (item.given_price - item.given_discount) *
                                    item.quantity
                                ).toFixed(2)}
                              </Paragraph>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {isDisable ? (
                            <a>
                              <Close sx={{ color: "#bebebe" }} />
                            </a>
                          ) : (
                            <a onClick={() => removeProduct(index)}>
                              <Close />
                            </a>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <br />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={7}></Grid>
            <Grid item xs={12} sm={12} md={5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "1rem",
                  // paddingTop: "1rem",
                  // borderTop: "1px solid #d7d7d7",
                }}
              >
                <Paragraph size="1.2rem">Subtotal</Paragraph>
                {newCartist != undefined &&
                newCartist.length != 0 &&
                newCartist != [] ? (
                  <>
                    {checkCurrency == "MYR" && (
                      <>MYR {getTotalCart().toFixed(2)}</>
                    )}
                    {checkCurrency == "SGD" && (
                      <>SGD {getTotalCart().toFixed(2)}</>
                    )}
                    {checkCurrency == "USD" && (
                      <>USD {getTotalCart().toFixed(2)}</>
                    )}
                  </>
                ) : (
                  <>
                    {checkCurrency == "MYR" && <>MYR 0.00</>}
                    {checkCurrency == "SGD" && <>SGD 0.00</>}
                    {checkCurrency == "USD" && <>USD 0.00</>}
                  </>
                )}
              </div>
            </Grid>
          </Grid>

          <br />

          <br />

          <div className={classes.theFlexx}>
            <div className={classes.theRight}>
              <Button
                href="/"
                label="Continue Shopping"
                br="4px"
                color="#125396"
                box="1px solid #125396"
              />
            </div>
            <div>
              {isDisable ? (
                <Button
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#bebebe"
                />
              ) : (
                <Button
                  onClick={() => handleCheckout()}
                  label="Proceed to Checkout"
                  br="4px"
                  color="#fff"
                  bg="#125396"
                  hover
                />
              )}
            </div>
          </div>
        </Section>
      </Container>
    </React.Fragment>
  );
}
