import React, { useState } from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import "./styles.css";

import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Close,
  Remove,
} from "@mui/icons-material";
import { customer_login, forgot_password } from "../../API/API";
import { useNavigate } from "react-router-dom";
import FormInput from "../../components/FormInput/FormInput";

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = () => {
    if (loginData.email.length == "" || loginData.password.length == "") {
      alert("All Field Required");
      return;
    }

    customer_login(loginData).then((json) => {
      if (json.status) {
        if (json.data.is_reset == 0) {
          alert("Please reset your password");
          window.localStorage.setItem("token", json.data.token);
          window.localStorage.setItem("cart", JSON.stringify(json.cart));
          navigate("/account");
        } else {
          setOpenModal(false);
          window.localStorage.setItem("token", json.data.token);
          window.localStorage.setItem("cart", JSON.stringify(json.cart));
          alert("Welcome Back");
          navigate("/");
        }
      } else {
        alert(json.message);
      }
    });
  };

  const [passwordData, setPasswordData] = React.useState({
    email: "",
  });

  const handleSavePassword = () => {
    if (passwordData.email.length == "") {
      alert("All Field Required");
    } else {
      forgot_password({
        ...passwordData,
      }).then((json) => {
        if (json.status) {
          alert("Please check your email");
        } else {
          alert(json.message);
        }
      });
    }
  };

  React.useEffect(() => {
    let hostname = window.location.hostname;
    if (hostname === "megah.cysoft.co") {
      window.location.href = "https://www.megahtex.com";
    }
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      <Container>
        <Section
          pd="2rem 0"
          style={{
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            position: "absolute",
            width: "80%",
          }}
        >
          <Container>
            <Grid
              container
              spacing={0}
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  sm: "row",
                },
              }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <div className={classes.thePadd}>
                  <Paragraph
                    color="#2a2866"
                    size="22px"
                    bold="600"
                    margin="0 0 1rem 0"
                  >
                    Sign in to Megah Textile
                  </Paragraph>
                  <FormInputNoLabel
                    placeholder="Email"
                    name="email"
                    bg="#eaeaea"
                    br="0px"
                    onChange={handleChange}
                  />
                  <FormInputNoLabel
                    placeholder="Password"
                    type="password"
                    name="password"
                    bg="#eaeaea"
                    br="0px"
                    onChange={handleChange}
                    margin="0"
                  />
                  <a
                    style={{
                      color: "#2a2866",
                      fontSize: "14px",
                      fontStyle: "italic ",
                      display: "block",
                    }}
                    onClick={() => handleOpenModal()}
                  >
                    Forget Password
                  </a>
                  <br />
                  <Button
                    label="LOGIN"
                    box="1px solid #2a2866"
                    color="#2a2866"
                    style={{ width: "30%", margin: "0 auto" }}
                    onClick={() => handleLogin()}
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{
                  background: "#2a2866",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={classes.thePadd2}>
                  <Paragraph
                    size="22px"
                    color="#FFF"
                    margin="0 0 .4rem 0"
                    bold="600"
                    center
                  >
                    Hello, Friend
                  </Paragraph>
                  <Paragraph size="14px" color="#FFF" center>
                    Fill in your personal details and continue your journey
                  </Paragraph>
                  <br />
                </div>
              </Grid>
            </Grid>
          </Container>
        </Section>
      </Container>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#353535" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#2a2866">
              Reset Password
            </Paragraph>
            <FormInput
              label="Email Address"
              placeholder="Email Address"
              // textLabelColor="#878787"
              bold="500"
              border="1px solid #d7d7d7 "
              // type="password"
              name="email"
              onChange={handleChange}
            />

            <Button
              label="SUBMIT"
              color="#fff"
              bg="#2a2866"
              br="6px"
              hover
              onClick={() => handleSavePassword()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
