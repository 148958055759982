import React from "react";
import useStyles, { FormInputSelect } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";
import moment from "moment";

import {
  Container,
  Dialog,
  DialogContent,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  MenuItem,
  Select,
  Box,
} from "@mui/material";
import Button from "../../components/Button/Button";
import { ArrowForwardIos, Close, ExpandMore } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  get_customer_profile,
  get_orders_detail,
  get_upload_file,
  report_sales_order,
  return_order,
  upload_consignment,
  get_report_reason,
  upload_item_images,
} from "../../API/API";
import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";

export default function OrderHistoryDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  let { sales_order_id } = useParams();

  const [loading, setLoading] = React.useState(false);

  const [orderData, setOrderData] = React.useState([]);
  const [itemData, setItemData] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [dlInvoice, setDLInvoice] = React.useState("");
  const [returnData, setReturnData] = React.useState([]);
  const [paynowCode, setPaynowCode] = React.useState(null);

  // const getOrderData = () => {
  //   // setLoading(true);
  //   get_orders_detail({ sales_order_id: sales_order_id }).then((json) => {
  //     if (
  //       json.data.uob_notificationId === null ||
  //       json.data.status == "Processing"
  //     ) {
  //       // retry after 10 seconds
  //       setTimeout(() => {
  //         getOrderData();
  //       }, 10000);
  //     } else {
  //     }
  //     setOrderData(json.data);
  //     setItemData(json.data.item);
  //     setReportData(json.data.report);
  //     setDLInvoice(json.data.file);
  //     setReturnData(json.data.returned);
  //     setPaynowCode(json.jespa);
  //     setLoading(false);
  //   });
  // };

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [checkCountry, setCheckCountry] = React.useState("");
  const [checkCurrency, setCheckCurrency] = React.useState("");

  // const getUser = () => {
  //   get_customer_profile({ token: token }).then((json) => {
  //     setCheckCountry(json.data.subsidiary);
  //     setCheckCurrency(json.data.currency);
  //   });
  // };

  const getUser = () => {};

  const getOrderData = () => {
    setLoading(true);
    get_customer_profile({ token: token }).then((json) => {
      setCheckCountry(json.data.subsidiary);
      setCheckCurrency(json.data.currency);

      get_orders_detail({ sales_order_id: sales_order_id }).then((json) => {
        // if (json.data.currency == "MYR") {
        //   setOrderData(json.data);
        //   setItemData(json.data.item);
        //   setReportData(json.data.report);
        //   setDLInvoice(json.data.file);
        //   setReturnData(json.data.returned);
        //   setPaynowCode(json.jespa);
        //   setLoading(false);
        // }

        // if (json.data.currency == "SGD") {
        //   if (
        //     json.data.uob_notificationId === null ||
        //     json.data.status == "Processing"
        //   ) {
        //     // retry after 10 seconds
        //     setTimeout(() => {
        //       getOrderData();
        //     }, 10000);
        //   } else {
        //     setOrderData(json.data);
        //     setItemData(json.data.item);
        //     setReportData(json.data.report);
        //     setDLInvoice(json.data.file);
        //     setReturnData(json.data.returned);
        //     setPaynowCode(json.jespa);
        //     // setLoading(false);
        //   }
        // }
        setOrderData(json.data);
        setItemData(json.data.item);
        setReportData(json.data.report);
        setDLInvoice(json.data.file);
        setReturnData(json.data.returned);
        setPaynowCode(json.jespa);
        setLoading(false);
      });
    });
  };


  const getOrderUpdate = () => {
    get_orders_detail({ sales_order_id: sales_order_id }).then((json) => {
      if (
        json.data.uob_notificationId === null ||
        json.data.status == "Processing"
      ) {
        // retry after 10 seconds
        setTimeout(() => {
          getOrderUpdate();
        }, 10000);
      } else {
        setOrderData(json.data);
        setItemData(json.data.item);
        setReportData(json.data.report);
        setDLInvoice(json.data.file);
        setReturnData(json.data.returned);
        setPaynowCode(json.jespa);
      }
    });
  }

  const [openModal3, setOpenModal3] = React.useState(false);

  const handleOpenModal3 = () => {
    setOpenModal3(true);
  };

  const [openModal4, setOpenModal4] = React.useState(false);

  const handleOpenModal4 = () => {
    setOpenModal4(true);
  };

  const [reasonList, setReasonList] = React.useState([]);

  const getReason = () => {
    get_report_reason({ token: token }).then((json) => {
      setReasonList(json.data);
    });
  };

  const [chooseReason, setChooseReason] = React.useState("");

  const handleChooseReason = (e) => {
    setChooseReason(e.target.value);
  };

  React.useEffect(() => {
    getOrderData();
    getOrderUpdate();
  }, []);

  React.useEffect(() => {
    // getUser();
    getReason();
  }, []);

  //

  const [desp, setDesp] = React.useState({
    description: "",
  });

  const handleChange = (e) => {
    setDesp({
      ...desp,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    if (desp.description.length == "") {
      alert("All Field Required");
      return;
    } else {
      report_sales_order({ ...desp, sales_order_id: sales_order_id }).then(
        (json) => {
          if (json.status) {
            alert("Sumbitted");
          }
        }
      );
    }
  };

  const [uploadPDF, setUploadPDF] = React.useState({
    sales_order_id: sales_order_id,
  });

  const handleChangeUpload = async (e) => {
    const file = e.target.files[0];
    var formData = new FormData();
    formData.append("image", file);

    var res = await get_upload_file(formData);
    if (res) {
      setUploadPDF({
        ...uploadPDF,
        image: res.data.data,
      });
    }
  };

  const handleUpload = () => {
    upload_consignment(uploadPDF).then((json) => {
      if (json.status) {
        alert("Sumitted");
      }
    });
  };

  const [uploadReturn, setUploadReturn] = React.useState();

  const handleChangeUploadReturn = async (e) => {
    var formData = new FormData();
    const files = e.target.files;
    formData.append("image_length", files.length);
    for (let i = 0; i < files.length; i++) {
      formData.append("images_" + i, files[i]);
    }

    upload_item_images(formData).then((res) => {
      if (res.status) {
        // setUploadReturn({
        //   ...uploadReturn,
        //   image: JSON.stringify(res.data.data),
        //   sales_order_id: salesOrderID,
        // });
        setUploadReturn({
          image: JSON.stringify(res.data.data),
        });
      }
    });
  };

  const [chooseSaleOrderItem, setChooseSaleOrderItem] = React.useState([]);

  // ITEM DATA QUANTITY

  // const handleChangeQuantityItem = (e, index) => {
  //   // const newValue = Number(e);
  //   // if (!isNaN(newValue)) {
  //   //   const temp_data = [...itemData];
  //   //   temp_data[index].quantity = newValue;
  //   //   setItemData(temp_data);
  //   // }
  //   var temp_data = [...itemData];
  //   var newQty = parseInt(e.target.value);
  //   if (isNaN(newQty)) {
  //     temp_data[index].return_quantity = "";
  //   } else {
  //     temp_data[index].return_quantity = newQty;
  //   }
  //   setItemData(temp_data);
  // };

  const handleChangeQuantityItem = (e, index) => {
    var temp_data = [...itemData];
    var newQty = parseInt(e.target.value);

    // Check if the input is a valid number and doesn't start with 0
    if (!isNaN(newQty) && String(newQty).charAt(0) !== "0") {
      temp_data[index].return_quantity = newQty;
    } else {
      temp_data[index].return_quantity = "";
    }

    setItemData(temp_data);
  };

  const handleReturn = () => {
    if (chooseReason == "") {
      alert("Please Choose a Reason");
      return;
    }

    if (desp.description.length == "") {
      alert("All Field Required");
      return;
    }

    if (chooseSaleOrderItem.length == 0) {
      alert("Please Select an Item");
      return;
    }

    if (uploadReturn == "") {
      alert("Please Upload a Image");
      return;
    }

    return_order({
      item: JSON.stringify(chooseSaleOrderItem),
      ...desp,
      ...uploadReturn,
      sales_order_id: sales_order_id,
      reason: chooseReason,
    }).then((json) => {
      if (json.status) {
        alert(
          "Thank you for your support, our admin will contact you within 24 business hour for further detail"
        );
        setOpenModal3(false);
      }
    });
  };

  const handleDownload = () => {
    window.open(orderData.file);
  };

  const handleDownloadDO = () => {
    window.open(orderData.dolink);
  };

  const currentDate = moment();

  // Get the created date from orderData using Moment.js (assuming it's a valid date string)
  const createdDate = moment(orderData.created_date);

  // Calculate the time difference between currentDate and createdDate in days
  const daysDifference = currentDate.diff(createdDate, "days");

  return (
    <React.Fragment>
      <Navbar />
      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#878787"} size={50} />
        </div>
      ) : (
        <Container>
          <Section pd="2rem 0">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a href="/account">
                  <Paragraph size="14px" color="#125396" bold="600">
                    My Account
                  </Paragraph>
                </a>
                <ArrowForwardIos
                  style={{ fontSize: "12px", margin: "0 .4rem" }}
                />
                <a href="/orderhistory">
                  <Paragraph size="14px" color="#125396" bold="600">
                    My Order
                  </Paragraph>
                </a>
                <ArrowForwardIos
                  style={{ fontSize: "12px", margin: "0 .4rem" }}
                />
                <a href="">
                  <Paragraph size="14px" color="#125396" bold="600">
                    Order ID: {orderData.sales_order_id}
                  </Paragraph>
                </a>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {orderData.file != "" && orderData.status_id == 5 && (
                  <Button
                    label="DOWNLOAD INVOICE"
                    color="#fff"
                    bg="#125396"
                    bold="500"
                    // br="6px"
                    size="13px"
                    pd=".4rem 1rem"
                    hover
                    onClick={() => handleDownload()}
                  />
                )}

                {orderData.dolink != "" && orderData.status_id == 5 && (
                  <Button
                    label="DOWNLOAD DELIVER ORDER"
                    color="#fff"
                    bg="#125396"
                    bold="500"
                    // br="6px"
                    size="13px"
                    pd=".4rem 1rem"
                    hover
                    onClick={() => handleDownloadDO()}
                    style={{ marginLeft: "12px" }}
                  />
                )}
                {orderData.status_id == 7 && (
                  <Button
                    label="RETURNED ORDER"
                    color="#fff"
                    bg="#125396"
                    bold="600"
                    hover
                    // br="6px"
                    onClick={() => handleOpenModal4()}
                  />
                )}
              </div>
            </div>
            <br />
            {paynowCode != "" && orderData.status_id == "2" && (
              <Card
                style={{
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    padding: "1rem 2rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#125396",
                  }}
                >
                  <Paragraph bold="700" size="22px" color="#fff">
                    Pay via PayNow
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      style={{ width: "40%" }}
                      src={
                        "https://chart.apis.google.com/chart?cht=qr&chs=350x350&chl=" +
                        paynowCode
                      }
                    ></img>
                  </div>
                </div>
              </Card>
            )}
            {paynowCode != "" && orderData.status_id == "5" && (
              <Card
                style={{
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    padding: "1rem 2rem",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#125396",
                  }}
                >
                  <Paragraph bold="700" size="22px" color="#fff">
                    Pay via PayNow
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <img
                      style={{ width: "40%" }}
                      src={
                        "https://chart.apis.google.com/chart?cht=qr&chs=350x350&chl=" +
                        paynowCode
                      }
                    ></img>
                  </div>
                </div>
              </Card>
            )}

            <Card style={{ borderRadius: "4px" }}>
              <div
                style={{
                  padding: "1rem 2rem",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  background: "#125396",
                }}
              >
                <Paragraph bold="700" size="22px" color="#fff">
                  Order ID {orderData.sales_order_id}
                </Paragraph>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Paragraph
                    color="#fff"
                    margin="0 1rem 0 0"
                    style={{ fontStyle: "italic" }}
                  >
                    Bypass Credit Limit:{" "}
                    {orderData.bypasscl == 1 ? "YES" : "NO"}
                  </Paragraph>
                  {orderData.status_id == "5" && daysDifference < 14 && (
                    <Button
                      label="RETURN ORDER"
                      color="#fff"
                      bg="#F92000"
                      bold="500"
                      // hover
                      // br="6px"
                      size="16px"
                      pd=".4rem 1rem"
                      onClick={() => handleOpenModal3()}
                    />
                  )}
                </div>
              </div>
              <div style={{ borderBottom: "1px solid #d7d7d7" }} />

              <div style={{ padding: "1rem 2rem" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <>
                      <Paragraph
                        bold="700"
                        // size="14px"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Order Date:
                        <span
                          style={{
                            fontWeight: "400",
                            marginLeft: "1rem",
                            color: "#000",
                          }}
                        >
                          {orderData.created_date}
                        </span>
                      </Paragraph>
                      <Paragraph
                        bold="700"
                        // size="14px"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Company Name:
                        <span
                          style={{
                            fontWeight: "400",
                            marginLeft: "1rem",
                            color: "#000",
                          }}
                        >
                          {orderData.entityid}
                        </span>
                      </Paragraph>
                      <Paragraph
                        bold="700"
                        // size="14px"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Contact Name:
                        <span
                          style={{
                            fontWeight: "400",
                            marginLeft: "1rem",
                            color: "#000",
                          }}
                        >
                          {orderData.contact_name}
                        </span>
                      </Paragraph>
                      <Paragraph
                        bold="700"
                        // size="14px"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Contact Number:
                        <span
                          style={{
                            fontWeight: "400",
                            marginLeft: "1rem",
                            color: "#000",
                          }}
                        >
                          {orderData.contact_number}
                        </span>
                      </Paragraph>
                      <Paragraph
                        bold="700"
                        // size="14px"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Company Email:
                        <span
                          style={{
                            fontWeight: "400",
                            marginLeft: "1rem",
                            color: "#000",
                          }}
                        >
                          {orderData.email}
                        </span>
                      </Paragraph>
                      <br />
                      <Paragraph
                        bold="700"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Invoicing Address:
                      </Paragraph>
                      <Paragraph color="#000">
                        {orderData.billing_addr1}, {orderData.billing_addr2},{" "}
                        {orderData.billing_city}, {orderData.billing_zip},{" "}
                        {orderData.billing_state}, {orderData.billing_country}
                      </Paragraph>
                      <br />
                      <Paragraph
                        bold="700"
                        color="#125396"
                        margin="0 0 .4rem 0"
                      >
                        Shipping Address:
                      </Paragraph>
                      <Paragraph color="#000">
                        {orderData.addr1}, {orderData.addr2}, {orderData.city},{" "}
                        {orderData.zip}, {orderData.state}, {orderData.country}
                      </Paragraph>
                      <br />
                    </>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <Paragraph bold="700" color="#125396" margin="0 0 .4rem 0">
                      Shipping Method:
                    </Paragraph>
                    <Paragraph color="#000">
                      {orderData.shippingmethod}
                    </Paragraph>
                    <br />
                    <Paragraph bold="700" color="#125396" margin="0 0 .4rem 0">
                      Shipping Carrier:
                    </Paragraph>
                    <Paragraph color="#000">
                      {orderData.shippingcarrier}
                    </Paragraph>
                    <br />
                    <Paragraph
                      bold="700"
                      // size="18px"
                      color="#125396"
                      margin="0 0 .4rem 0"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      Status:
                      {orderData.status == "Confirmed" ? (
                        <Box
                          sx={{
                            background: "green",
                            padding: "5px 8px",
                            width: "fit-content",
                            marginLeft: "1rem",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "400",
                              // marginLeft: "1rem",
                              color: "#FFF",
                            }}
                          >
                            {orderData.status}
                          </span>
                        </Box>
                      ) : (
                        <span
                          style={{
                            fontWeight: "400",
                            marginLeft: "1rem",
                            color: "#000",
                            // fontWeight: "700",
                            // textTransform: "uppercase",
                            // fontStyle: "italic",
                          }}
                        >
                          {orderData.status}
                        </span>
                      )}
                    </Paragraph>
                    {orderData.status_id == "6" && (
                      <>
                        <br />
                        <Paragraph
                          bold="700"
                          // size="18px"
                          color="#125396"
                          margin="0 0 .4rem 0"
                        >
                          Cancel Date & Time:
                          <span
                            style={{
                              fontWeight: "400",
                              marginLeft: "1rem",
                              color: "#000",
                              // fontWeight: "700",
                              // textTransform: "uppercase",
                              // fontStyle: "italic",
                            }}
                          >
                            {orderData.cancelled_date}
                          </span>
                        </Paragraph>
                      </>
                    )}
                    <br />
                    <Paragraph
                      bold="700"
                      // size="18px"
                      color="#125396"
                      margin="0 0 .4rem 0"
                    >
                      Tracking Reference:
                      <span
                        style={{
                          fontWeight: "400",
                          marginLeft: "1rem",
                          color: "#000",
                          // fontWeight: "700",
                          // textTransform: "uppercase",
                          // fontStyle: "italic",
                        }}
                      >
                        {orderData.trackingref}
                      </span>
                    </Paragraph>
                    <br />
                    <Paragraph
                      bold="700"
                      // size="18px"
                      color="#125396"
                      margin="0 0 .4rem 0"
                    >
                      Number of Packs:
                      <span
                        style={{
                          fontWeight: "400",
                          marginLeft: "1rem",
                          color: "#000",
                          // fontWeight: "700",
                          // textTransform: "uppercase",
                          // fontStyle: "italic",
                        }}
                      >
                        {orderData.item_fulfilment}
                      </span>
                    </Paragraph>
                    <br />
                    <br />
                  </Grid>
                </Grid>

                {reportData.length != 0 && (
                  <>
                    <br />
                    <Paragraph bold="700" color="#F92000" margin="0 0 .4rem 0">
                      RETURN REASON:
                    </Paragraph>
                    <Paragraph color="#F92000">{reportData.reason}</Paragraph>
                    <br />
                    <br />
                    <Paragraph bold="700" color="#F92000" margin="0 0 .4rem 0">
                      DESCRIPTION :
                    </Paragraph>
                    <Paragraph color="#F92000">
                      {reportData.description}
                    </Paragraph>
                    <br />
                  </>
                )}

                <br />
                <Paragraph
                  bold="700"
                  size="20px"
                  color="#125396"
                  margin="0 0 1rem 0"
                >
                  Product List
                </Paragraph>

                <TableContainer>
                  <Table>
                    <TableHead className={classes.theHead}>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Price
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Discount Per Pcs
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Quantity
                        </TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Total
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.map((item) => (
                        <>
                          {item.is_display == 1 && (
                            <>
                              <TableRow>
                                <TableCell>
                                  <div
                                    style={{ display: "flex" }}
                                    className={classes.theWidth}
                                  >
                                    {/* <div className={classes.theIMG}>
                              <img src={item.image} />
                            </div> */}
                                    <div style={{ marginLeft: "1rem" }}>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        bold="700"
                                        size="14px"
                                        className="_theWording"
                                        color="#125396"
                                      >
                                        {item.displayname}
                                      </Paragraph>
                                      <pre
                                        style={{
                                          margin: 0,
                                          color: "#878787",
                                          fontFamily: "Roboto,Helvetica, Arial",
                                        }}
                                      >
                                        {item.description != "" &&
                                        item.description != null
                                          ? item.description
                                          : ""}
                                      </pre>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        size="12px"
                                        className="_theWording"
                                      >
                                        {item.brand}
                                      </Paragraph>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        size="12px"
                                        className="_theWording"
                                      >
                                        {item.size}
                                      </Paragraph>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        size="12px"
                                        className="_theWording"
                                      >
                                        {item.color}
                                      </Paragraph>
                                      <Paragraph
                                        margin="0 0 .2rem 0"
                                        size="12px"
                                        // className="_theWording"
                                        style={{
                                          display: "flex",
                                          width: "450px",
                                        }}
                                      >
                                        {item.description}
                                      </Paragraph>
                                    </div>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={classes.theWidth2}>
                                    {checkCurrency == "MYR" && (
                                      <Paragraph center>
                                        MYR {item.price}
                                      </Paragraph>
                                    )}
                                    {checkCurrency == "SGD" && (
                                      <Paragraph center>
                                        SGD {item.price}
                                      </Paragraph>
                                    )}
                                    {checkCurrency == "USD" && (
                                      <Paragraph center>
                                        USD {item.price}
                                      </Paragraph>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={classes.theWidth2}>
                                    {checkCurrency == "MYR" && (
                                      <Paragraph center>
                                        MYR{" "}
                                        {parseFloat(
                                          item.discountamount
                                        ).toFixed(2)}
                                      </Paragraph>
                                    )}
                                    {checkCurrency == "SGD" && (
                                      <Paragraph center>
                                        SGD{" "}
                                        {parseFloat(
                                          item.discountamount
                                        ).toFixed(2)}
                                      </Paragraph>
                                    )}
                                    {checkCurrency == "USD" && (
                                      <Paragraph center>
                                        USD{" "}
                                        {parseFloat(
                                          item.discountamount
                                        ).toFixed(2)}
                                      </Paragraph>
                                    )}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div>
                                    <Paragraph center>
                                      {item.quantity}
                                    </Paragraph>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className={classes.theWidth2}>
                                    {checkCurrency == "MYR" && (
                                      <Paragraph center>
                                        MYR{" "}
                                        {parseFloat(item.subtotal).toFixed(2)}
                                      </Paragraph>
                                    )}
                                    {checkCurrency == "SGD" && (
                                      <Paragraph center>
                                        SGD{" "}
                                        {parseFloat(item.subtotal).toFixed(2)}
                                      </Paragraph>
                                    )}
                                    {checkCurrency == "USD" && (
                                      <Paragraph center>
                                        USD{" "}
                                        {parseFloat(item.subtotal).toFixed(2)}
                                      </Paragraph>
                                    )}
                                  </div>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                <Paragraph bold="700" color="#125396" margin="0 0 .4rem 0">
                  Sales Remark:
                </Paragraph>
                <Paragraph>{orderData.sales_remark}</Paragraph>
                <br />
                <Paragraph bold="700" color="#125396" margin="0 0 .4rem 0">
                  Customer Remark:
                </Paragraph>
                <Paragraph>{orderData.customer_remark}</Paragraph>
                <br />
                {/* <Paragraph bold="700" color="#125396" margin="0 0 .4rem 0">
                  Memo:
                </Paragraph>
                <Paragraph>{orderData.memo}</Paragraph>
                <br /> */}
                <br />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={8}>
                    <>
                      {orderData.deposit_amount == 0 ? (
                        <></>
                      ) : (
                        <>
                          <Paragraph
                            color="#878787"
                            bold="600"
                            size="20px"
                            margin="0 0 .4rem 0"
                          >
                            Deposit Amount
                          </Paragraph>
                          {checkCurrency == "MYR" && (
                            <Paragraph
                              className={
                                orderData.is_deposit_paid == 0
                                  ? classes.theRed
                                  : classes.theDefault
                              }
                              size="24px"
                              bold="700"
                            >
                              MYR {orderData.deposit_amount}
                            </Paragraph>
                          )}
                          {checkCurrency == "SGD" && (
                            <Paragraph
                              className={
                                orderData.is_deposit_paid == 0
                                  ? classes.theRed
                                  : classes.theDefault
                              }
                              size="24px"
                              bold="700"
                            >
                              SGD {orderData.deposit_amount}
                            </Paragraph>
                          )}
                          {checkCurrency == "USD" && (
                            <Paragraph
                              className={
                                orderData.is_deposit_paid == 0
                                  ? classes.theRed
                                  : classes.theDefault
                              }
                              size="24px"
                              bold="700"
                            >
                              USD {orderData.deposit_amount}
                            </Paragraph>
                          )}
                        </>
                      )}
                    </>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div
                      style={
                        {
                          // padding: "1rem",
                          // borderRadius: "6px",
                          // border: "1px solid #d7d7d7",
                        }
                      }
                    >
                      <div>
                        {/* <Paragraph
                      size="22px"
                      color="#878787"
                      bold="600"
                      // center
                      margin="0 0 1rem 0"
                    >
                      Order Summary
                    </Paragraph> */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                            paddingTop: "1rem",
                          }}
                        >
                          <Paragraph color="#125396" bold="600">
                            Subtotal
                          </Paragraph>
                          {checkCurrency == "MYR" && (
                            <Paragraph bold="600" color="#878787" center>
                              MYR {orderData.total_amount}
                            </Paragraph>
                          )}
                          {checkCurrency == "SGD" && (
                            <Paragraph bold="600" color="#878787" center>
                              SGD {orderData.total_amount}
                            </Paragraph>
                          )}
                          {checkCurrency == "USD" && (
                            <Paragraph bold="600" color="#878787">
                              USD {orderData.total_amount}
                            </Paragraph>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                            // borderTop: "1px solid #d7d7d7",
                          }}
                        >
                          <Paragraph color="#125396" bold="600">
                            Discount Amount
                          </Paragraph>
                          {checkCurrency == "MYR" && (
                            <Paragraph color="#878787" bold="500">
                              MYR{" "}
                              {parseFloat(orderData.discountamount).toFixed(2)}
                            </Paragraph>
                          )}
                          {checkCurrency == "SGD" && (
                            <Paragraph color="#878787" bold="500">
                              SGD{" "}
                              {parseFloat(orderData.discountamount).toFixed(2)}
                            </Paragraph>
                          )}
                          {checkCurrency == "USD" && (
                            <Paragraph color="#878787" bold="500">
                              USD{" "}
                              {parseFloat(orderData.discountamount).toFixed(2)}
                            </Paragraph>
                          )}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                            // borderTop: "1px solid #d7d7d7",
                          }}
                        >
                          <Paragraph color="#125396" bold="600">
                            Shipping Fee
                          </Paragraph>
                          {checkCurrency == "MYR" && (
                            <Paragraph bold="600" color="#878787">
                              MYR {orderData.shipping_fee}
                            </Paragraph>
                          )}
                          {checkCurrency == "SGD" && (
                            <Paragraph bold="600" color="#878787">
                              SGD {orderData.shipping_fee}
                            </Paragraph>
                          )}
                          {checkCurrency == "USD" && (
                            <Paragraph bold="600" color="#878787">
                              USD {orderData.shipping_fee}
                            </Paragraph>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                            // borderTop: "1px solid #d7d7d7",
                          }}
                        >
                          <Paragraph color="#125396" bold="600">
                            Handling Fee
                          </Paragraph>
                          {checkCurrency == "MYR" && (
                            <Paragraph bold="600" color="#878787">
                              MYR {orderData.handling_fee}
                            </Paragraph>
                          )}
                          {checkCurrency == "SGD" && (
                            <Paragraph bold="600" color="#878787">
                              SGD {orderData.handling_fee}
                            </Paragraph>
                          )}
                          {checkCurrency == "USD" && (
                            <Paragraph bold="600" color="#878787">
                              USD {orderData.handling_fee}
                            </Paragraph>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }}
                        >
                          <>
                            {checkCountry == 2 ? (
                              <>
                                {checkCurrency == "MYR" && (
                                  <>
                                    <Paragraph color="#125396" bold="600">
                                      SST
                                    </Paragraph>
                                    <Paragraph color="#878787" bold="500">
                                      MYR {orderData.sst}
                                    </Paragraph>
                                  </>
                                )}

                                {checkCurrency == "SGD" && (
                                  <>
                                    <Paragraph color="#125396" bold="600">
                                      GST
                                    </Paragraph>
                                    <Paragraph color="#878787" bold="500">
                                      SGD {orderData.gst}
                                    </Paragraph>
                                  </>
                                )}

                                {checkCurrency == "USD" && (
                                  <>
                                    <Paragraph color="#125396" bold="600">
                                      SST
                                    </Paragraph>
                                    <Paragraph color="#878787" bold="500">
                                      USD {orderData.sst}
                                    </Paragraph>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {checkCurrency == "MYR" && (
                                  <>
                                    <Paragraph color="#125396" bold="600">
                                      SST
                                    </Paragraph>
                                    <Paragraph color="#878787" bold="500">
                                      MYR {orderData.sst}
                                    </Paragraph>
                                  </>
                                )}
                                {checkCurrency == "SGD" && (
                                  <>
                                    <Paragraph color="#125396" bold="600">
                                      GST
                                    </Paragraph>
                                    <Paragraph color="#878787" bold="500">
                                      SGD {orderData.gst}
                                    </Paragraph>
                                  </>
                                )}

                                {checkCurrency == "USD" && (
                                  <>
                                    <Paragraph color="#125396" bold="600">
                                      SST
                                    </Paragraph>
                                    <Paragraph color="#878787" bold="500">
                                      USD {orderData.sst}
                                    </Paragraph>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                            paddingTop: "1rem",
                            borderTop: "1px solid #d7d7d7",
                          }}
                        >
                          <Paragraph color="#125396" bold="600">
                            Total
                          </Paragraph>
                          {checkCountry == "2" ? (
                            <Paragraph color="#000" size="24px" bold="500">
                              RM {orderData.final_amount}
                            </Paragraph>
                          ) : (
                            <Paragraph color="#000" bold="500" size="24px">
                              $ {orderData.final_amount}
                            </Paragraph>
                          )}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <br />
                <br />
                {/* <Paragraph
                bold="700"
                size="20px"
                color="#878787"
                margin="0 0 1rem 0"
              >
                Payment Type
              </Paragraph>
              <div style={{ borderBottom: "1px solid #d7d7d7" }} />
              <Paragraph bold="700" color="#878787" margin="1rem 0">
                C.O.D
              </Paragraph> */}
              </div>
            </Card>

            <br />

            {/* {orderData.status_id == 1 && (
              <>
                <FormInput
                  label="Upload Consignment"
                  border="1px solid #eaeaea"
                  placeholder="Upload Consignment"
                  type="file"
                  onChange={handleChangeUpload}
                />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#353535"
                  bold="600"
                  // br="6px"
                  onClick={() => handleUpload()}
                />
              </>
            )}
            {orderData.status_id == 4 && (
              <>
                <FormInput
                  label="Description"
                  border="1px solid #eaeaea"
                  placeholder="Description"
                  multiline
                  rows="3"
                  onChange={handleChange}
                  name="description"
                />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#353535"
                  bold="600"
                  // br="6px"
                  onClick={() => handleSave()}
                />
              </>
            )}
            {orderData.status_id == 5 && (
              <>
                <FormInput
                  label="Description"
                  border="1px solid #eaeaea"
                  placeholder="Description"
                  multiline
                  rows="3"
                  onChange={handleChange}
                  name="description"
                />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#353535"
                  bold="600"
                  // br="6px"
                  onClick={() => handleReturn()}
                />
              </>
            )} */}
          </Section>
          {/*  RETURN ORDER */}
          <Dialog
            open={openModal3}
            onClose={() => setOpenModal3(false)}
            sx={{ width: "100%" }}
            scroll="body"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogContent>
              <div className={classes.theSection}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                  }}
                >
                  <a
                    onClick={() => setOpenModal3(false)}
                    // onClick={() => handleCloseModal3()}
                  >
                    <Close style={{ color: "#c5c5c5" }} />
                  </a>
                </div>

                <br />
                <Paragraph
                  center
                  size="24px"
                  margin="0 0 1rem 0"
                  color="#353535"
                >
                  Return Order
                </Paragraph>

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>Sales Quantity</TableCell>
                        <TableCell sx={{ textAlign: "center" }}>
                          Return Quantity
                        </TableCell>
                        <TableCell>Returned Quantity</TableCell>
                        <TableCell>Total Amout</TableCell>

                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemData.map((item, index) => (
                        <>
                          {item.is_display == "1" && (
                            <TableRow>
                              <TableCell>
                                <>
                                  {item.quantity == item.returned_quantity ? (
                                    <></>
                                  ) : (
                                    <>
                                      {item.remainingamt != 0 && (
                                        <input
                                          type="checkbox"
                                          value={item}
                                          // onChange={(e) => handleChooseSaleOrderItem(e, index, item)}
                                          // onChange={(e) => {
                                          //   const isChecked = e.target.checked;
                                          //   if (isChecked) {
                                          //     setChooseSaleOrderItem([
                                          //       ...chooseSaleOrderItem,
                                          //       item,
                                          //     ]);
                                          //   } else {
                                          //     setChooseSaleOrderItem(
                                          //       chooseSaleOrderItem.filter(
                                          //         (value) => value !== item
                                          //       )
                                          //     );
                                          //   }
                                          // }}
                                          onChange={(e) => {
                                            const isChecked = e.target.checked;
                                            if (isChecked) {
                                              if (!item.return_quantity) {
                                                alert(
                                                  "Please enter a value for return quantity"
                                                );
                                                e.target.checked = false;
                                              } else {
                                                setChooseSaleOrderItem([
                                                  ...chooseSaleOrderItem,
                                                  item,
                                                ]);
                                              }
                                            } else {
                                              setChooseSaleOrderItem(
                                                chooseSaleOrderItem.filter(
                                                  (value) => value !== item
                                                )
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </>
                              </TableCell>

                              <TableCell>{item.sales_order_item_id}</TableCell>
                              <TableCell>{item.displayname}</TableCell>

                              <TableCell sx={{ textAlign: "center" }}>
                                {item.quantity}
                              </TableCell>

                              <TableCell>
                                <input
                                  style={{
                                    border:
                                      item.return_quantity >
                                      item.remain_quantity
                                        ? "1px solid #F90000"
                                        : "1px solid #eaeaea",
                                    padding: "0.475rem 1rem",
                                    textAlign: "center",
                                  }}
                                  disabled={
                                    item.quantity == item.returned_quantity
                                      ? true
                                      : false
                                  }
                                  // type="number"
                                  onChange={(e) =>
                                    handleChangeQuantityItem(e, index)
                                  }
                                  value={item.return_quantity}
                                />
                              </TableCell>

                              <TableCell sx={{ textAlign: "center" }}>
                                {item.returned_quantity}
                              </TableCell>
                              {checkCurrency == "MYR" && (
                                <TableCell>MYR {item.final_amount}</TableCell>
                              )}
                              {checkCurrency == "SGD" && (
                                <TableCell>SGD {item.final_amount}</TableCell>
                              )}
                              {checkCurrency == "USD" && (
                                <TableCell>USD {item.final_amount}</TableCell>
                              )}
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <br />
                {/* <Paragraph margin="0 0 .4rem 0">
              Choose a reason{" "}
              <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
            </Paragraph>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={chooseReason}
              name="radio-buttons-group"
              sx={{ flexDirection: "row", flexWrap: "wrap" }}
            >
              {reasonList.map((item) => (
                <FormControlLabel
                  value={item.name}
                  control={<Radio />}
                  label={item.name}
                  onChange={() => handleChooseReason(item.name)}
                />
              ))}
            </RadioGroup> */}
                <Paragraph margin="1rem 0">
                  Choose Reason{" "}
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <FormInputSelect>
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    name="name"
                    value={chooseReason}
                    onChange={handleChooseReason}
                    style={{ marginBottom: "1rem" }}
                  >
                    <MenuItem value="" style={{ fontWeight: "400" }}>
                      <em style={{ color: "#878787" }}>
                        Please Choose a Reason
                      </em>
                    </MenuItem>
                    {reasonList.map((item) => (
                      <MenuItem value={item.name} style={{ color: "#000" }}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormInputSelect>
                <FormInput
                  label="Description"
                  border="1px solid #eaeaea"
                  placeholder="Description"
                  multiline
                  rows="3"
                  onChange={handleChange}
                  name="description"
                  required
                />
                {/* <FormInput
              label="Upload Images"
              border="1px solid #eaeaea"
              placeholder="Upload Images"
              type="file"
              name="image"
              onChange={handleChangeUploadReturn}
              multiple
              required
            /> */}
                <Paragraph color="#000" margin="1rem 0">
                  Upload Images
                  <span style={{ color: "#F92000", marginLeft: "4px" }}>*</span>
                </Paragraph>
                <input
                  style={{
                    color: "#000",
                    border: "1px solid #eaeaea",
                    borderRadius: 4,
                    padding: ".4rem 1rem",
                    width: "-webkit-fill-available",
                    lineHeight: "1.4375em",
                    fontSize: "1rem",
                  }}
                  type="file"
                  name="image"
                  onChange={handleChangeUploadReturn}
                  multiple
                />
                <br />
                <br />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#125396"
                  bold="600"
                  hover
                  // br="6px"
                  onClick={() => handleReturn()}
                />
                <br />
              </div>
              <br />
            </DialogContent>
          </Dialog>
          {/*  RETURN ORDER ITEM LIST */}
          <Dialog
            open={openModal4}
            onClose={() => setOpenModal4(false)}
            sx={{ width: "100%" }}
            scroll="body"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogContent>
              <div className={classes.theSection}>
                <Paragraph
                  bold="700"
                  color="#265EA1"
                  size="24px"
                  center
                  margin="1rem 0"
                >
                  RETURNED ORDER
                </Paragraph>
                <Grid container spacing={3}>
                  {returnData.map((item, index) => (
                    <Grid item xs={12} sm={12} md={6}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMore
                              sx={{ margin: "0 10px", color: "#265EA1" }}
                            />
                          }
                          aria-controls="panel1a-content"
                          id={index}
                          sx={{ justifyContent: "unset" }}
                          style={{
                            border: "1px solid #eaeaea",
                            padding: "1rem",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              padding: "1rem",
                              width: "100%",
                            }}
                          >
                            <Paragraph
                              color="#F90000"
                              bold="600"
                              margin="0 0 .4rem 0"
                              style={{ fontStyle: "italic" }}
                            >
                              {item.returnreason}
                            </Paragraph>
                            <Paragraph margin="0 0 .4rem 0">
                              Other Reason: {item.otherreason}
                            </Paragraph>
                            <Paragraph margin="0 0 .4rem 0">
                              Memo: {item.otherreason}
                            </Paragraph>
                            {item.file != "" && (
                              <Paragraph margin="0 0 .4rem 0">
                                File:{" "}
                                <a
                                  style={{ color: "#125396" }}
                                  href={item.file}
                                >
                                  asd{item.file}
                                </a>
                              </Paragraph>
                            )}

                            <Paragraph margin="0 0 .4rem 0">
                              Full Return:{" "}
                              {item.fullreturn == 0 ? <>NO</> : <>YES</>}
                            </Paragraph>
                            <Paragraph margin="0 0 .4rem 0">
                              Stock Return:{" "}
                              {item.stockreturn == 0 ? <>NO</> : <>YES</>}
                            </Paragraph>
                            <Paragraph margin="0 0 .4rem 0">
                              Customer Return:{" "}
                              {item.customerreturn == 0 ? <>NO</> : <>YES</>}
                            </Paragraph>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            border: "1px solid #eaeaea",
                          }}
                        >
                          <Table>
                            <TableHead className={classes.theHead}>
                              <TableRow>
                                <TableCell>Item Name</TableCell>
                                <TableCell>Quantity</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {item.item.map((x) => (
                                <TableRow>
                                  <TableCell>{x.itemname}</TableCell>
                                  <TableCell>{x.quantity}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </AccordionDetails>
                      </Accordion>
                      <br />
                    </Grid>
                  ))}
                </Grid>
              </div>
              <br />
            </DialogContent>
          </Dialog>
        </Container>
      )}
    </React.Fragment>
  );
}
