import axios from "axios";
// const host = "https://megah.cysoft.co/admin/";
// const host = "https://netsuite.megahtex.com/admin/";
// const host = "https://staging.megahtex.com/admin/";
const host = "https://megahtex.com/admin/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["token"] = token;
    }
  }

  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: body,
  };
  return postOptions;
};

export const customer_login = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "customer_login",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    // window.localStorage.setItem("token", json.data.token);
    // window.localStorage.setItem("business_type", json.data.user_type_id);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      var cart = [];
      let cart_res = window.localStorage.getItem("cart");
      if (cart_res) {
        cart = cart_res;
      }
      if (cart) {
        postparam["cart"] = cart;
      }
      window.location.href = "/";
    } else {
      // alert(json.message);
    }
    return json;
  }
};

export const register = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "register",
    format_request(postparam)
  );
  var json = await response.json();
  if (json.status) {
    // console.log(json.data);
    window.localStorage.setItem("token", json.data.token);
    return json;
  } else {
    if (json.message == "AUTH FAIL") {
      window.localStorage.clear();
      alert("Authentication broken");
      window.location.href = "/";
    } else {
      // alert(json.message);
    }
    return json;
  }
};

export const get_category = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_category",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_colour = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_colour",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_color = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_color",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_color_name = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_color_name",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_material = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_material",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_apparel = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_apparel",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_brand = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_brand",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_size = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_size",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_gender = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_gender",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const contact_us = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "contact_us",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const subscribe = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "subscribe",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_product_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_product_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const add_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "add_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_address",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_customer_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_customer_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const edit_profile = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "edit_profile",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_orders_history = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_history",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_orders_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_detail",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const change_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "change_password",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const delete_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "delete_address ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_address_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_address_detail ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_parent_item = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_parent_item ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_item_detail = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_item_detail ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const create_sales_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "create_sales_order ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const insert_customer_deposit = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "insert_customer_deposit ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const customer_payment = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "customer_payment ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_sales_history = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_sales_history ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_inventory_snapshot = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_inventory_snapshot ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const validate_cart = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "validate_cart ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const checkout = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "checkout ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const topup = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "topup ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const process_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "process_order ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const report_sales_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "report_sales_order ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_upload_file = (postparam) => {
  return axios.post(access_endpoint_link + "get_upload_file", postparam);
};

export const get_completed_orders_history = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_completed_orders_history ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const upload_consignment = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "upload_consignment ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_inventory_by_customer = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_inventory_by_customer ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const return_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "return_order  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_report_reason = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_report_reason  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const forgot_password = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "forgot_password  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const custom_made = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "custom_made  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_topup_amount = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_topup_amount  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_website_banner = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_website_banner  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_shipping_method = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_shipping_method  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const upload_bankslip = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "upload_bankslip  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const invoice_payment = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "invoice_payment  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const bulk_order = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "bulk_order  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_decoration = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_decoration  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_decorative = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_decorative  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_customer_cart = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_customer_cart",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_country = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_country  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_upload_consignment_note = (postparam) => {
  return axios.post(
    access_endpoint_link + "get_upload_consignment_note",
    postparam
  );
};

export const upload_item_images = (postparam) => {
  return axios.post(access_endpoint_link + "upload_item_images", postparam);
};

export const get_shipping_carrier_by_id = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_shipping_carrier_by_id   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const upload_attachment = (postparam) => {
  return axios.post(access_endpoint_link + "upload_attachment ", postparam);
};

export const get_default_billing_address = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_default_billing_address   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_state = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_state   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const validate_state = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "validate_state   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_credit_note_by_token = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_credit_note_by_token   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_statement = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_statement   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_customer_return_by_token = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_customer_return_by_token   ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_invoice = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_invoice",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_orders_history_count = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_orders_history_count",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const sync_invoice_list = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "sync_invoice_list",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_rebate = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_rebate",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_best_seller = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_best_seller  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const check_invoice_payment = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "check_invoice_payment  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};

export const get_parent_item_bestbuy = async (postparam) => {
  var response = await fetch(
    access_endpoint_link + "get_parent_item_bestbuy  ",
    format_request(postparam)
  );
  var json = await response.json();
  return json;
};