import React from "react";
import FormLabel from "../Typography/FormLabel";
import { FormGroup, TextInput } from "./styles";

const FormInputNoLabel2 = ({
  children,
  textLabelColor,
  bold,
  label,
  margin,
  bg,
  textcolor,
  mb,
  border,
  textalign,
  br,
  ...props
}) => {
  return (
    <TextInput
      br={br}
      bg={bg}
      textcolor={textcolor}
      border={border}
      mb={mb}
      textalign={textalign}
      {...props}
    >
      {children}
    </TextInput>
  );
};

export default FormInputNoLabel2;
