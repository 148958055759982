import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import headercontactus from "../../assets/images/contact.jpg";

const config = {

  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theTag: {
    background: "#D51B1B",
    position: "absolute",
    left: 0,
    top: "8px",
    padding: ".2rem 1rem",
  },

  theGallery: {
    position: "relative",
    width: "100%",
    marginBottom: ".6rem",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },
  theBanner: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "600px",
      objectFit: "cover"
    },
  },
  theOverlay: {
    position: "absolute",
    width: "80%",
    // height: "100%",
    transform: "translateY(10%)",
    bottom: "10%",
    left: "3%",
    padding: "2rem 1rem",
  
    ...md("md", {
      width: "35%",
    }),
  },

  backgroundBlur: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },

  theBGG: {
  position: "relative",
    height: "450px",
    backgroundImage: "url(" + headercontactus + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "flex",
    // display: "none",
    ...md("md", {
      height: "80vh",
      backgroundImage: "url(" + headercontactus + ")",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      display: "flex",
    }),
  },
};

export default makeStyles(config);
