import React from "react";
import Slider from "react-slick";
import useStyles, { IncrementAction, IncrementAction2 } from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import silestone001 from "../../assets/images/silestone001.png";

import {
  Container,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Button from "../../components/Button/Button";
import {
  Add,
  ArrowBackIos,
  ArrowDropDown,
  ArrowForwardIos,
  Remove,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { get_product_detail } from "../../API/API";
import useCart from "../../hooks/useCart";
import "./styles.css";

export default function CustomMadeDetail() {
  const classes = useStyles();
  // let { product_id } = useParams();

  const [count, setCount] = React.useState(1);

  const handleChangeQty = (new_qty, index) => {
    if (new_qty < 1) {
      return;
    }
    setCount(new_qty);
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // API

  const [chooseSize, setChooseSize] = React.useState(0);

  const handleChooseSize = (size_id) => {
    setChooseSize(size_id);
    // console.log(size_id);
  };

  const [chooseColour, setChooseColour] = React.useState(0);

  const handleChooseColour = (colour_id) => {
    setChooseColour(colour_id);
    // console.log(colour_id);
  };

  const [productData, setProductData] = React.useState([]);

  const getProductData = () => {
    // get_product_detail({ product_id: product_id }).then((json) => {
    //   setProductData(json.data);
    // });
  };

  React.useEffect(() => {
    getProductData();
  }, []);

  // ADD TO CART

  const { addProduct, clearCart } = useCart();

  const handleAddToCart = () => {
    // let prodList = {
    //   name: productData.name,
    //   single_price: productData.business_price,
    //   price: productData.business_price,
    //   quantity: count,
    //   image: productData.image,
    //   product_id: productData.product_id,
    //   category: productData.category,
    //   texture: productData.texture,
    //   brand: productData.brand,
    // };
    // console.log(prodList);
    // addProduct(prodList);
  };

  return (
    <React.Fragment>
      <Navbar />

      <Container>
        <Section pd="2rem 0">
          <a style={{ display: "flex", alignItems: "center" }} >
            <Paragraph size="14px">Ready To Wear</Paragraph>
            <ArrowForwardIos style={{ fontSize: "12px", margin: "0 .4rem" }} />
            <Paragraph size="14px">Brand Name</Paragraph>
            <ArrowForwardIos style={{ fontSize: "12px", margin: "0 .4rem" }} />
            <Paragraph size="14px">Product Name</Paragraph>
          </a>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theProdIMG}>
                <img src={silestone001} />
              </div>
              <br />
              <div className={classes.theGalleryList}>
                <div className={classes.theGalleryx}>
                  <img src={silestone001} />
                </div>
                <div className={classes.theGalleryx}>
                  <img src={silestone001} />
                </div>
                <div className={classes.theGalleryx}>
                  <img src={silestone001} />
                </div>
              </div>
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Paragraph
                color="#878787"
                bold="500"
                size="14px"
                margin="0 0 .2rem 0"
              >
                Brand Name
              </Paragraph>
              <Paragraph
                color="#878787"
                bold="500"
                size="24px"
                margin="0 0 2rem 0"
              >
                Product Name
              </Paragraph>
              <Paragraph
                color="#878787"
                bold="500"
                size="24px"
                margin="0 0 1rem 0"
              >
                RM 0000.00
              </Paragraph>

              <Paragraph size="14px" margin="1rem 0">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                interdum eu libero non hendrerit. Nullam dapibus augue nec ante
                laoreet fringilla. Integer scelerisque dolor at tincidunt
                fringilla.
              </Paragraph>

              <br />

              <div style={{ padding: ".4rem 1rem", background: "#d7d7d7" }}>
                <Paragraph bold="600">Sizes</Paragraph>
              </div>
              <div style={{ padding: "1rem", border: "1px solid #d7d7d7" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <label
                      className="theRadioSize"
                      onClick={() => handleChooseSize(1)}
                    >
                      <input type="radio" name="radio" />
                      <span className="checkmarkSize">XS</span>
                    </label>
                    <label
                      className="theRadioSize"
                      onClick={() => handleChooseSize(2)}
                    >
                      <input type="radio" name="radio" />
                      <span className="checkmarkSize">S</span>
                    </label>
                  </div>
                </div>
              </div>

              <br />

              <div style={{ padding: ".4rem 1rem", background: "#d7d7d7" }}>
                <Paragraph bold="600">Color</Paragraph>
              </div>
              <div style={{ padding: "1rem", border: "1px solid #d7d7d7" }}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <label className="theRadioColor">
                      <input type="radio" name="radio" />
                      <span
                        className="checkmarkColor"
                        style={{ background: "#333335" }}
                      ></span>
                    </label>
                    <label className="theRadioColor">
                      <input type="radio" name="radio" />
                      <span
                        className="checkmarkColor"
                        style={{ background: "#7B109B" }}
                      ></span>
                    </label>
                    {/* {colour.map((item) => (
                      <label
                        className="theRadioColor"
                        onClick={() => handleChooseColour(item.colour_id)}
                      >
                        <input type="radio" name="radio" value={chooseColour} />
                        <span
                          className="checkmarkColor"
                          style={{ background: item.name }}
                        ></span>
                      </label>
                    ))} */}
                  </div>
                </div>
              </div>

              <br />

              <div style={{ padding: ".4rem 1rem", background: "#d7d7d7" }}>
                <Paragraph bold="600">Quantity</Paragraph>
              </div>
              <div style={{ padding: "1rem", border: "1px solid #d7d7d7" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className={classes.theFlexQuantity}>
                    <div className={classes.theIncrement}>
                      <IncrementAction2
                        margin="0 1rem 0 0"
                        onClick={() => handleChangeQty(count - 1)}
                        // onClick={() => de_quantity(index)}
                      >
                        <Remove className={classes.theButtonStyle2} />
                      </IncrementAction2>

                      {count == 0 ? (
                        <div className={classes.theBox}>
                          <p className={classes.theCount}>0</p>
                        </div>
                      ) : (
                        <div className={classes.theBox}>
                          <p className={classes.theCount}>{count}</p>
                        </div>
                      )}

                      <IncrementAction
                        margin="0 0 0 1rem"
                        onClick={() => setCount(count + 1)}
                        // onClick={() => add_quantity(index)}
                      >
                        <Add className={classes.theButtonStyle} />
                      </IncrementAction>
                    </div>
                  </div>
                  <br />
                  <Paragraph color="#878787">
                    Available Quantity: 000 pieces
                  </Paragraph>
                </div>
              </div>

              <br />
              <br />
              <Button
                label="ADD TO CART"
                color="#FFF"
                bg="#565656"
                br="8px"
                pd=".8rem 1.5rem"
                onClick={() => handleAddToCart()}
              />
              <br />
              <br />
            </Grid>
          </Grid>
          <br />
          <br />
        </Section>
      </Container>
      <Footer/>
    </React.Fragment>
  );
}
