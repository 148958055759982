import styled from "styled-components/macro";
import { makeStyles } from "@mui/styles";
import { md } from "../../styles/responsive";
import { TextField, Input, FormControl } from "@mui/material";
// import bannercontact from "../../assets/images/bannerContact.png";

export const CarouselContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CarouselIMG = styled.img`
  width: 100%;
`;

export const FormInputSelect = styled(FormControl)`
  display: block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  //   border: 1px solid #c5c5c5;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  .MuiInput-underline:before {
    border-bottom: none !important;
  }
  .MuiInput-underline:after {
    border-bottom: none !important;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-root {
    font-weight: 500 !important;
  }
  .MuiInput-underline {
    &:before {
      position: relative !important;
    }
  }
`;

export const IncrementAction = styled.div`
  background: #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

export const IncrementAction2 = styled.div`
  border: 1px solid #353535;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  margin: ${(props) => props.margin};
`;

const config = {
  theBanner: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theFontTitle: {
    fontSize: "24px",
    ...md("md", {
      fontSize: "34px",
    }),
    ...md("xmd", {
      fontSize: "44px",
    }),
    ...md("lg", {
      fontSize: "54px",
    }),
  },

  theFont: {
    fontSize: "14px",
    ...md("xmd", {
      fontSize: "23px",
      textAlign: "justify",
    }),

    ...md("lg", {
      fontSize: "28px",
      textAlign: "justify",
    }),
  },

  theBox: {
    position: "absolute",
    width: "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: "#fff",
    padding: "3rem",

    ...md("md", {
      top: "140%",
      left: "50%",
      //   width: "100%",
    }),

    ...md("xmd", {
      width: "70%",
    }),
  },

  //

  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theBoxx: {
    position: "relative",
    margin: "1rem",
  },
  theText: {
    padding: "3rem 2rem",
  },
  theBanner: {
    position: "relative",
    "& img": {
      width: "100%",
      height: "600px",
      objectFit: "cover",
    },
  },
  theOverlay: {
    position: "absolute",
    width: "80%",
    // height: "100%",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    padding: "2rem 1rem",

    ...md("md", {
      width: "68%",
    }),
  },

  backgroundBlur: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    height: "150px",
    backgroundColor: "rgba(255, 255, 255, .3)", // Adjusted the alpha value
    borderRadius: "50%",
    boxShadow: "0 0 15px 100px rgba(255, 255, 255, .3)", // Adjusted box shadow
    opacity: 0.8, // Reduced opacity
    "&::before": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      background:
        "radial-gradient(ellipse at center, rgba(255, 255, 255, .1) 0%, rgba(255, 255, 255, .5) 140%)", // Adjusted radial gradient
      zIndex: -1,
    },
  },

  theGlow: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1",
    "& img": {
      width: "100%",
      height: "auto",
    },
  },
};

export default makeStyles(config);
