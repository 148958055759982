import React from "react";
// import Slider from "react-slick";
import useStyles, {
  FormInputSelect,
  IncrementAction,
  IncrementAction2,
} from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import readytowear from "../../assets/images/readytowear.jpg";

import { Box } from "@mui/material";

import "./styles.css";

import { get_website_banner } from "../../API/API";
import useCart from "../../hooks/useCart";

import glow from "../../assets/images/glow.png";

export default function ReadyToWear() {
  const classes = useStyles();

  // const [sort, setSort] = React.useState(0);

  // const handleChangeSort = (event) => {
  //   setSort(event.target.value);
  // };

  // const [expanded, setExpanded] = React.useState(1);

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  // // API

  // const [category, setCategory] = React.useState([]);

  // const [size, setSize] = React.useState([]);

  // const [brand, setBrand] = React.useState([]);

  // const [gender, setGender] = React.useState([]);

  // const getCategory = () => {
  //   get_category().then((json) => {
  //     setCategory(json.data);
  //   });
  // };
  // const getSize = () => {
  //   get_size().then((json) => {
  //     setSize(json.data);
  //   });
  // };
  // const getBrand = () => {
  //   get_brand().then((json) => {
  //     setBrand(json.data);
  //   });
  // };
  // const getGender = () => {
  //   get_gender().then((json) => {
  //     setGender(json.data);
  //   });
  // };

  // React.useEffect(() => {
  //   // getCategory();
  //   getSize();
  //   getBrand();
  //   getGender();
  // }, []);

  // // GET PRODUCT API

  // const [product, setProduct] = React.useState([]);

  // const [chooseCategory, setChooseCategory] = React.useState(0);

  // const handleChooseCategory = (category_id) => {
  //   setChooseCategory(category_id);
  //   console.log(category_id);
  // };

  // const [chooseBrand, setChooseBrand] = React.useState(0);

  // const handleChooseBrand = (brand_id) => {
  //   setChooseBrand(brand_id);
  //   console.log(brand_id);
  // };

  // const [chooseGender, setchooseGender] = React.useState(0);

  // const handleChooseGender = (gender_id) => {
  //   setchooseGender(gender_id);
  //   console.log(gender_id);
  // };

  // const [chooseSize, setChooseSize] = React.useState(0);

  // const handleChooseSize = (size_id) => {
  //   setChooseSize(size_id);
  //   console.log(size_id);
  // };

  // const handleChangeResetFilter = () => {
  //   setChooseBrand(0);
  //   setchooseGender(0);
  //   setChooseSize(0);
  // };

  // const getProduct = () => {
  //   get_product({
  //     brand_id: chooseBrand,
  //     gender_id: chooseGender,
  //     size_id: chooseSize,
  //     sort_by: sort,
  //   }).then((json) => {
  //     setProduct(json.data);
  //     setPageNum(json.pages);
  //   });
  // };

  // React.useEffect(() => {
  //   getProduct();
  // }, [chooseBrand, chooseGender, chooseSize, sort]);

  // const [pageList, setPageList] = React.useState(1);

  // const [pageNum, setPageNum] = React.useState("");

  // const handleChangePage = (ispage) => {
  //   setPageList(ispage);
  //   get_product({
  //     brand_id: chooseBrand,
  //     gender_id: chooseGender,
  //     size_id: chooseSize,
  //     sort_by: sort,
  //     page: ispage,
  //   }).then((json) => {
  //     setProduct(json.data);
  //   });
  // };

  // SEARCH

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  const [banner, setBanner] = React.useState([]);

  const getBanner = () => {
    get_website_banner().then((json) => {
      setBanner(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      {/* <div className={classes._theBanner}>
        <img src={readytowear} />
        <div className={classes.theOverlay}>
          <div className={classes.theBoxx}>
            <div className={classes.backgroundBlur}></div>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                zIndex: 999,
              }}
            >
              <Paragraph
                color="#58595b"
                size="28px"
                bold="600"
                margin="0 0 1rem 0"
                center
                style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
              >
                READY TO WEAR
              </Paragraph>
              <Paragraph
                color="#58595b"
                size="18px"
                bold="600"
                center
                style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
              >
                Ready-made clothes are available in our store, just order and
                wait at home.
              </Paragraph>
              <br />
              <FormInputNoLabel
                placeholder="Search Here"
                // border="1px solid #acacac"
                bg="#FFF"
                onChange={handleSearch}
                value={searchKey}
              />
            </Box>
          </div>
        </div>
      </div> */}

      {banner
        .filter((item) => item.page === "Ready to Wear")
        .sort((a, b) => a.weight - b.weight)
        .map((item) => (
          <div className={classes._theBanner} key={item.banner_id}>
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt={`Banner ${item.banner_id}`}
            />
            <Box className={classes.theGlow}>
              <img src={glow} />
              <div className={classes.theOverlay}>
                <div className={classes.theBoxx}>
                  {/* <div className={classes.backgroundBlur}></div> */}
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "100%",
                      zIndex: 999,
                    }}
                  >
                    <Paragraph
                      color="#58595b"
                      size="28px"
                      bold="600"
                      margin="0 0 1rem 0"
                      center
                      style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
                    >
                      {item.title}
                    </Paragraph>
                    <Paragraph
                      color="#58595b"
                      size="18px"
                      bold="600"
                      center
                      style={{ textShadow: "rgba(0, 0, 0, .3) 4px 3px 4px" }}
                    >
                      {item.subtitle}
                    </Paragraph>
                    <br />
                    <FormInputNoLabel
                      placeholder="Search Here"
                      // border="1px solid #acacac"
                      bg="#FFF"
                      onChange={handleSearch}
                      value={searchKey}
                    />
                  </Box>
                </div>
              </div>
            </Box>
          </div>
        ))}

      <Footer />
    </React.Fragment>
  );
}
