import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
import Paragraph from "../../components/Typography/Paragraph";

import { Container, Grid, Box } from "@mui/material";

import dis001 from "../../assets/images/i001.svg";
import dis002 from "../../assets/images/i002.svg";
import dis003 from "../../assets/images/i003.svg";
import dis004 from "../../assets/images/i004.svg";
import dis005 from "../../assets/images/i005.svg";
import dis006 from "../../assets/images/i006.svg";
import dis007 from "../../assets/images/i007.svg";
import dis008 from "../../assets/images/i008.svg";
import dis009 from "../../assets/images/i009.svg";
import { get_website_banner } from "../../API/API";

export default function About() {
  const classes = useStyles();

  const CLIENTS = [
    "client001.png",
    "client002.png",
    "client003.png",
    "client004.png",
    "client005.png",
    "client006.png",
    "client007.png",
    "client008.png",
    "client009.png",
    "client010.png",
    "client011.png",
    "client012.png",
    "client013.png",
    "client014.png",
    "client015.png",
    "client016.png",
    "client017.png",
    "client018.png",
    "client019.png",
    "client020.png",
    "client021.png",
    "client022.png",
    "client023.png",
    "client024.png",
    "client025.png",
    "client026.png",
    "client027.png",
    "client028.png",
    "client029.png",
    "client030.png",
  ];

  const [banner, setBanner] = React.useState([]);

  const getBanner = () => {
    get_website_banner().then((json) => {
      setBanner(json.data);
    });
  };

  React.useEffect(() => {
    getBanner();
  }, []);

  return (
    <React.Fragment>
      <Navbar />
      {/* <Box className={classes.theBGG}></Box> */}

      {/* <div className={classes.theBanner}>
        <img src={building} />
      </div> */}

      {banner
        .filter((item) => item.page === "About Us")
        .sort((a, b) => a.weight - b.weight)
        .map((item) => (
          <div className={classes.theBanner} key={item.banner_id}>
            <img
              style={{ width: "100%" }}
              src={item.image}
              alt={`Banner ${item.banner_id}`}
            />
          </div>
        ))}

      <Container>
        <Section pd="2rem 0">
          <Paragraph color="#2a2866" bold="700" size="24px" center>
            ABOUT US
          </Paragraph>
          <Paragraph color="#000" margin=".8rem 0 2rem 0" size="16px">
            Megah Textile, which was founded in 2010, is presently Malaysia's
            leadng multi-brand imprint provider. Our company was founded with
            the goal of being a provider of apparel and accessories to screen
            printers, embroiderers, sports wholesalers, and other businesses.
          </Paragraph>
          <Paragraph color="#2a2866" bold="700" size="24px" center>
            OUR PRODUCTS & BRANDS
          </Paragraph>
          <Paragraph color="#000" margin=".8rem 0 1rem 0" size="16px">
            We have successfully become a distributor for well-known
            international fabric companies such as Gildan, Anvil, Tultex,
            Crossrunner, North Harbor, Sarra, and many more in order to ensure
            that the clothes supplied are of excellent quality. With our
            internal production capabilities, we also can make custom apparel,
            from garment pattern creation to finished goods in addition to
            supplying garments to other distributors.
          </Paragraph>
          <Paragraph color="#000" margin=".8rem 0 1rem 0" size="16px">
            With a workforce of more than 100 people and based in Johor and with
            branches in KL and Singapore, Megah Textile is able to produce
            clothing stocks for distributors thoughout Malaysia. The brands that
            we bring well-known names that are part of Shopee's top fdashion
            brands category.
          </Paragraph>
        </Section>
      </Container>
      <div style={{ background: "#2a2866" }}>
        <Container>
          <Section pd="1rem 0">
            <Paragraph color="#fff" bold="600" size="22px" center>
              AUTHORISED DISTRIBUTOR FOR
            </Paragraph>

            {/* <Grid container spacing={3}>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis001} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis002} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis003} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis004} />
                </div>
              </Grid>{" "}
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis005} />
                </div>
              </Grid>{" "}
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis006} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis007} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis008} />
                </div>
              </Grid>
              <Grid item xs={6} sm={4} md={2}>
                <div className={classes.theIMG}>
                  <img src={dis009} />
                </div>
              </Grid>
            </Grid> */}
          </Section>
        </Container>
      </div>
      <Container>
        <Section pd="4rem 0">
          {/* <div className={classes.theDis}>
            <div className={classes.theDisIMG}>
              <img src={dis001} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis002} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis003} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis004} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis005} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis007} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis008} />
            </div>
            <div className={classes.theDisIMG}>
              <img src={dis009} />
            </div>
          </div> */}
          {/* <Grid
            container
            spacing={3}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis001} />
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis002} />
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis003} />
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis004} />
              </div>
            </Grid>

            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis005} />
              </div>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            spacing={3}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis006} />
              </div>
            </Grid>

            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMGG}>
                <img src={dis007} />
              </div>
            </Grid>

            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMG}>
                <img src={dis008} />
              </div>
            </Grid>
            <Grid item xs={6} sm={4} md={2}>
              <div className={classes.theIMGG}>
                <img src={dis009} />
              </div>
            </Grid>
          </Grid> */}
          <Box>
            <img style={{width: "100%"}} src="../../../../assets/images/distributors.png"/>
          </Box>
        </Section>
      </Container>
      <div
        style={{ background: "#2a2866", height: "60px", width: "100%" }}
      ></div>
      <Container>
        <Section pd="2rem 0">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div
                className={classes.theIMG2}
                // style={{
                //   fontSize: 0,
                // }}
              >
                <img src="/assets/images/ourmission.jpg" />
                {/* <div className={classes.theOverlayText}>
                  <Paragraph style={{ fontStyle: "italic" }} color="#fff">
                    To deliver quality promotional wear products and passionate
                    service to our customer
                  </Paragraph>
                </div> */}

                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    background: "rgba(0,0,0,.2)",
                    padding: "1rem 1rem ",
                    color: "white",
                    width: "-webkit-fill-available",
                  }}
                >
                  <Paragraph color="#FFF" bold="600" size="34px" style={{}}>
                    MISSION{" "}
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      provide quality custom apparel to everyone
                    </span>
                  </Paragraph>
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className={classes.theIMG2}>
                <img src="/assets/images/ourvision.jpg" />
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    background: "rgba(0,0,0,.2)",
                    padding: "1rem 1rem ",
                    color: "white",
                    width: "-webkit-fill-available",
                  }}
                >
                  <Paragraph color="#FFF" bold="600" size="34px" style={{}}>
                    VISION{" "}
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                      }}
                    >
                      to be NO.1 custom apparel provider in the country
                    </span>
                  </Paragraph>
                </Box>
                {/* <div className={classes.theOverlayText2}>
                  <Paragraph style={{ fontStyle: "italic" }} color="#fff">
                    Aim to be No.1 leader in Malaysia & Singapore promotional
                    wear industry
                  </Paragraph>
                </div> */}
              </div>
            </Grid>
          </Grid>
        </Section>
      </Container>
      <div style={{ background: "#FFF" }}>
        <Container>
          <Section pd="2rem 0">
            <Paragraph
              color="#2a2866"
              margin="0 0 1rem 0"
              bold="700"
              size="24px"
              center
            >
              OUR CLIENTS
            </Paragraph>

            <div className={classes.theClient}>
              {CLIENTS.map((r) => (
                <div className={classes.theClientIMG}>
                  <img src={"/assets/images/" + r} />
                </div>
              ))}
            </div>

            {/* <Grid container spacing={3}>
              {CLIENTS.map((r) => (
                <Grid item xs={6} sm={4} md={2}>
                  <div className={classes.theIMG}>
                    <img src={"/assets/images/" + r} />
                  </div>
                </Grid>
              ))}
            </Grid> */}
          </Section>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
}
