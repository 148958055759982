import React from "react";
import {
  Container,
  Grid,
  Dialog,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import Section from "../../components/Section/Section";
import Paragraph from "../../components/Typography/Paragraph";
import useStyles from "./styles";
import "./styles.css";
import {
  Person,
  Lock,
  Bookmark,
  Inbox,
  Close,
  Edit,
  Delete,
} from "@mui/icons-material";
import FormInput from "../../components/FormInput/FormInput";
import Button from "../../components/Button/Button";
import {
  add_address,
  delete_address,
  edit_address,
  edit_profile,
  get_address,
  get_address_detail,
  get_orders_history,
  get_customer_profile,
  change_password,
  topup,
  process_order,
} from "../../API/API";
import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import { useNavigate } from "react-router-dom";
import Loader from "react-js-loader";

export default function Account() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  const [tab, setTab] = React.useState(0);

  const handleChangeTab = (txt) => {
    setTab(txt);
  };

  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleOpenEditModal = (customer_address_id) => {
    setOpenEditModal(true, customer_address_id);
    setAddressBookID(customer_address_id);
  };

  const [openTopUpModal, setOpenTopUpModal] = React.useState(false);

  const handleOpenModalTopUp = () => {
    setOpenTopUpModal(true);
  };

  const [checked, setChecked] = React.useState(true);

  const [defaultAddress, setDefaultAddress] = React.useState("T");

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);

    console.log(event.target.checked);

    if (event.target.checked == true) {
      setDefaultAddress("T");
    }

    if (event.target.checked == false) {
      setDefaultAddress("F");
    }
  };

  const [searchKey, setSearchKey] = React.useState("");

  const handleSearch = (event) => {
    setSearchKey(event.target.value);
  };

  // API

  const [token, setToken] = React.useState([]);

  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    if (!token || "") {
      navigate("/login");
    }
  }, []);

  const [profileData, setProfileData] = React.useState({
    naem: "",
    phone: "",
  });

  const [addressData, setAddressData] = React.useState({
    // name: "",
    addr1: "",
    addr2: "",
    state: "",
    country: "",
    // phone: "",
    city: "",
    zip: "",
  });

  const [passwordData, setPasswordData] = React.useState({
    old_password: "",
    password: "",
    password2: "",
  });

  const [creditData, setCreditData] = React.useState({
    amount: "",
  });

  const [addressList, setAddressList] = React.useState([]);

  const [addressBookID, setAddressBookID] = React.useState(0);

  const [addressBookDetail, setAddressBookDetail] = React.useState({
    // name: "",
    addr1: "",
    addr2: "",
    state: "",
    country: "",
    // phone: "",
    city: "",
    zip: "",
  });

  const [oderList, setOderList] = React.useState([]);

  const handleChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
    setAddressData({
      ...addressData,
      [e.target.name]: e.target.value,
    });
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
    setAddressBookDetail({
      ...addressBookDetail,
      [e.target.name]: e.target.value,
    });
    setCreditData({
      ...creditData,
      [e.target.name]: e.target.value,
    });
  };

  const getProfile = () => {
    get_customer_profile({ token: token }).then((json) => {
      if (json.status) {
        setProfileData(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  const getOderListtory = () => {
    get_orders_history({ token: token }).then((json) => {
      if (json.status) {
        setOderList(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  const getAddressBook = () => {
    get_address({ token: token }).then((json) => {
      if (json.status) {
        setAddressList(json.data);
      } else {
        // alert(json.message);
      }
    });
  };

  // const getAddressBookDetail = () => {
  //   get_address_detail({ customer_address_id: addressBookID }).then((json) => {
  //     setAddressBookDetail(json.data);
  //   });
  // };

  React.useEffect(() => {
    getProfile();
    getOderListtory();
    getAddressBook();
    // getAddressBookDetail();
  }, [addressBookID]);

  // EDIT PROFILE

  const handleEditProfile = () => {
    if (profileData.name.length == "" || profileData.phone.length == "") {
      alert("All Field Required");
    } else {
      edit_profile({
        ...profileData,
        token: token,
      }).then((json) => {
        if (json.status) {
          // alert(json.message);
          alert("Editted Successful");
        } else {
          alert(json.message);
        }
      });
    }
  };

  // CHANGE PASSWORD

  const handleSavePassword = () => {
    if (
      passwordData.old_password.length == "" ||
      passwordData.password.length == "" ||
      passwordData.password2.length == ""
    ) {
      alert("All Field Required");
    } else {
      change_password({
        ...passwordData,
        token: token,
      }).then((json) => {
        if (json.status) {
          // alert(json.message);
          alert("Password Changed Successful");
          window.location.reload();
        } else {
          alert(json.message);
        }
      });
    }
  };

  // ADD NEW ADDRESS BOOK

  const handleAddAddress = () => {
    if (
      // addressData.name.length == "" ||
      addressData.addr1.length == "" ||
      addressData.addr2.length == "" ||
      addressData.city.length == "" ||
      addressData.zip.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert("All Field Required");
    }

    add_address({
      ...addressData,
      token: token,
      is_default: defaultAddress,
    }).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  // ADDRESS BOOK DETAIL

  // EDIT ADDRESS BOOK

  const handleEditAddress = () => {
    if (
      // addressBookDetail.name.length == "" ||
      addressData.addr1.length == "" ||
      addressData.addr2.length == "" ||
      addressData.city.length == "" ||
      addressData.zip.length == "" ||
      addressData.state.length == "" ||
      addressData.country.length == ""
    ) {
      alert("All Field Required");
    }

    edit_address({
      ...addressBookDetail,
      token: token,
      customer_address_id: addressBookID,
      is_default: defaultAddress,
    }).then((json) => {
      if (json.status) {
        alert(json.message);
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  // DELETE ADDRESS BOOK

  const handleDeleteAddress = (customer_address_id) => {
    delete_address({ customer_address_id: customer_address_id }).then(
      (json) => {
        if (json.status) {
          alert(json.message);
          window.location.reload();
        } else {
          alert(json.message);
        }
      }
    );
  };

  // TOP UP CREDIT

  const handleTopUp = () => {
    if (creditData.amount.length == "") {
      alert("All Field Required");
      return;
    }
    topup({ ...creditData, token: token }).then((json) => {
      if (json.status) {
        // alert("Top Up Successful");
        // window.location.reload();
        // navigate("https://megah.cysoft.co/admin/API/razer_pay/8/")
        window.location.href = json.data;
        navigate(json.data);
      } else {
        alert(json.message);
      }
    });
  };

  const handlePayNow = (sales_order_id) => {
    process_order({ sales_order_id: sales_order_id }).then((json) => {
      if (json.status) {
        if (json.razer == "") {
          return;
        } else {
          window.location.href = json.data;
          navigate(json.data);
        }
      } else {
        alert(json.message);
      }
    });
  };

  return (
    <React.Fragment>
      <Navbar />
      {loading == true ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "600px",
          }}
        >
          <Loader type="snipper-default" bgColor={"#125396"} size={50} />
        </div>
      ) : (
        <>
          <div style={{ background: "#E0F0F7", padding: "2rem 0 0 0" }}>
            <Container>
              <Paragraph
                color="#000"
                size="22px"
                bold="600"
                margin="0 0 1rem 0"
              >
                My Account
              </Paragraph>
              <div className={classes.theOverScroll}>
                <a
                  // className={tab == 0 ? classes.theLActive : classes.theL}
                  // onClick={() => handleChangeTab(0)}
                  className={classes.theLActive}
                  href="/account"
                >
                  <Paragraph
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    My Profile
                  </Paragraph>
                </a>
                <a className={classes.theL} href="/balance">
                  <Paragraph
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    My Balance
                  </Paragraph>
                </a>
                <a
                  className={classes.theL}
                  //   onClick={() => handleChangeTab(2)}
                  href="/orderhistory"
                >
                  <Paragraph
                    color="#000"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    My Order
                  </Paragraph>
                </a>
                <a
                  // className={tab == 3 ? classes.theLActive : classes.theL}
                  // onClick={() => handleChangeTab(3)}
                  className={classes.theL}
                  href="/address"
                >
                  <Paragraph
                    color="#000"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    Address Book
                  </Paragraph>
                </a>
                <a
                  className={classes.theL}
                  href="/statementaccount"
                >
                  <Paragraph
                    color="#000"
                    style={{ padding: ".4rem 2rem", width: "max-content" }}
                  >
                    Statement of Account
                  </Paragraph>
                </a>
              </div>
            </Container>
          </div>
          <Container>
            <Section pd="2rem 0">
              <div>
                <>
                  <div className={classes.theBox}>
                    <Paragraph size="20px" bold="600" color="#878787">
                      Personal Details
                    </Paragraph>
                    <FormInput
                      label="Name"
                      placeholder="Name"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7 "
                      onChange={handleChange}
                      name="companyname"
                      br="0"
                      value={profileData.name}
                      required
                    />
                    <FormInput
                      label="Contact Number"
                      placeholder="Contact Number"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7"
                      // type="number"
                      name="phone"
                      onChange={handleChange}
                      value={profileData.phone}
                      required
                    />
                    <FormInput
                      label="Email Address"
                      placeholder="Email Address"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7"
                      bg="#eaeaea"
                      br="0"
                      // color="#fff"
                      // type="number"
                      name="email"
                      // onChange={handleChange}
                      value={profileData.email}
                    />
                    <br />
                    <div className={classes.theSizeBtn}>
                      <Button
                        label="SAVE CHANGES"
                        color="#fff"
                        bg="#125396"
                        bold="600"
                        hover
                        // br="6px"
                        onClick={() => handleEditProfile()}
                      />
                    </div>
                  </div>
                  <br />
                  <div className={classes.theBox}>
                    <Paragraph size="20px" bold="600" color="#878787">
                      Security
                    </Paragraph>
                    <FormInput
                      label="Current Password"
                      placeholder="Current Password"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7 "
                      type="password"
                      name="old_password"
                      onChange={handleChange}
                      required
                    />
                    <FormInput
                      label="New Password"
                      placeholder="New Password"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7 "
                      type="password"
                      name="password"
                      onChange={handleChange}
                      required
                    />
                    <FormInput
                      label="Re-enter New Password"
                      placeholder="Re-enter New Password"
                      textLabelColor="#878787"
                      bold="500"
                      border="1px solid #d7d7d7 "
                      type="password"
                      name="password2"
                      onChange={handleChange}
                      required
                    />
                    <br />
                    <div className={classes.theSizeBtn}>
                      <Button
                        label="SAVE CHANGES"
                        color="#fff"
                        bg="#125396"
                        bold="600"
                        hover
                        // br="6px"
                        onClick={() => handleSavePassword()}
                      />
                    </div>
                  </div>
                  <br />
                  <br />
                </>
              </div>
            </Section>
          </Container>
        </>
      )}

      {/* <Footer /> */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#fff">
              Add New Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Address Line 1"
                  placeholder="Address Line 1"
                  border="1px solid #d7d7d7"
                  name="addr1"
                  onChange={handleChange}
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="addr2"
                  onChange={handleChange}
                />
                <FormInput
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                />
                <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                />
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                />
                <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  name="zip"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              onClick={() => handleAddAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* EDIT ADDRESS */}
      <Dialog
        open={openEditModal}
        onClose={() => setOpenEditModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenEditModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#353535">
              Edit Address
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                {/* <FormInput
                  label="Full Name"
                  placeholder="Full Name"
                  border="1px solid #d7d7d7"
                  name="name"
                  onChange={handleChange}
                  value={addressBookDetail.name}
                /> */}
                <FormInput
                  label="Address Line 1"
                  placeholder="Address 1"
                  border="1px solid #d7d7d7"
                  name="address"
                  onChange={handleChange}
                  value={addressBookDetail.addr1}
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  border="1px solid #d7d7d7"
                  name="address"
                  onChange={handleChange}
                  value={addressBookDetail.addr2}
                />
                <FormInput
                  label="State"
                  placeholder="State"
                  border="1px solid #d7d7d7"
                  name="state"
                  onChange={handleChange}
                  value={addressBookDetail.state}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label="Country"
                  placeholder="Country"
                  border="1px solid #d7d7d7"
                  name="country"
                  onChange={handleChange}
                  value={addressBookDetail.country}
                />
                {/* <FormInput
                  label="Phone"
                  placeholder="Phone"
                  border="1px solid #d7d7d7"
                  name="phone"
                  onChange={handleChange}
                  type="number"
                  value={addressBookDetail.phone}
                /> */}
                <FormInput
                  label="Town / City"
                  placeholder="Town / City"
                  border="1px solid #d7d7d7"
                  name="city"
                  onChange={handleChange}
                  value={addressBookDetail.city}
                />{" "}
                <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                  border="1px solid #d7d7d7"
                  name="postcode"
                  type="number"
                  onChange={handleChange}
                  value={addressBookDetail.zip}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChangeCheck}
                      value={checked}
                    />
                  }
                  label="Set As Default Address"
                />
              </Grid>
            </Grid>

            <br />
            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              onClick={() => handleEditAddress()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
      {/* TOP UP */}
      <Dialog
        open={openTopUpModal}
        onClose={() => setOpenTopUpModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <a onClick={() => setOpenTopUpModal(false)}>
                <Close style={{ color: "#c5c5c5" }} />
              </a>
            </div>

            <br />
            <Paragraph center size="24px" color="#353535">
              Top Up My Credit
            </Paragraph>

            <FormInput
              label="Top Up Amount"
              placeholder="Top Up Amount"
              border="1px solid #d7d7d7"
              name="amount"
              type="number"
              onChange={handleChange}
            />

            <Button
              label="SUBMIT"
              color="#fff"
              bg="#125396"
              hover
              br="6px"
              onClick={() => handleTopUp()}
            />
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
